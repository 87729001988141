<template>
    <div class="row" v-if="isValid('CanViewCurrency')">
        <div class="col-lg-12">
            <div class="row">
                <div class="col-sm-12">
                    <div class="page-title-box">
                        <div class="row">
                            <div class="col">
                                <h4 class="page-title">File Manager</h4>
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="javascript:void(0);">{{ $t('TheCurrency.Home')
                                            }}</a></li>
                                    <li class="breadcrumb-item active">File Manager</li>
                                </ol>
                            </div>
                            <div class="col-auto align-self-center">
                                <button class="btn btn-soft-primary btn-sm " 
                                                    v-on:click="DownloadZipFile">
                                                    <i class="fas fa-download"></i>
                                                    Download Zip 
                                                </button>
                                <a v-on:click="GotoPage('/StartScreen')" href="javascript:void(0);"
                                    class="btn btn-sm btn-outline-danger">
                                    {{ $t('TheCurrency.Close') }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card">

                <div class="card-body">

                    <div class="row">
                        <div class="col-sm-12">
                            <div class="page-title-box">
                                <div class="row">
                                    <div class="col">
                                        <h4 class="page-title">Files</h4>

                                    </div><!--end col-->
                                    <div class="col-auto align-self-center">
                                        <button v-if="attachment.length > 0" class="btn btn-soft-primary btn-sm " 
                                                    v-on:click="DownloadCaseZipFile">
                                                    <i class="fas fa-download"></i>
                                                    Download Case Zip 
                                                </button>


                                    </div><!--end col-->
                                </div><!--end row-->
                            </div><!--end page-title-box-->
                        </div><!--end col-->
                    </div><!--end row-->
                    <!-- end page title end breadcrumb -->
                    <div class="row">
                        <div class="col-lg-3">
                            <div class="card">
                                <div class="card-header">
                                    <div class="row align-items-center">
                                        <div class="col">
                                            <h4 class="card-title">Customer</h4>
                                        </div><!--end col-->
                                        <div class="col-auto">
                                            <div class="dropdown">
                                                <a href="#" class="btn btn-sm btn-outline-light dropdown-toggle"
                                                    data-bs-toggle="dropdown" aria-haspopup="true"
                                                    aria-expanded="false">
                                                    <!-- <i class="las la-menu align-self-center text-muted icon-xs"></i>  -->
                                                    <i class="mdi mdi-dots-horizontal text-muted"></i>
                                                </a>

                                            </div>
                                        </div><!--end col-->
                                    </div> <!--end row-->
                                </div><!--end card-header-->
                                <div class="card-body">
                                    <div class="files-nav">
                                        <div class="nav flex-column nav-pills" id="files-tab"
                                            aria-orientation="vertical">
                                            <a class="nav-link " v-bind:class="item.isActive ? 'active' : ''"
                                                v-for=" item in folderList" v-bind:key="item.customerName"
                                                id="files-projects-tab" data-bs-toggle="pill" href="#files-projects"
                                                aria-selected="true">

                                                <i data-feather="folder"
                                                    class="align-self-center icon-dual-file icon-sm me-3"></i>
                                                <div class="d-inline-block align-self-center"
                                                    v-on:click="GetCurrentFolder(item.customerName)">
                                                    <h5 class="m-0">{{ item.customerName }}</h5>
                                                </div>
                                            </a>


                                        </div>
                                    </div>
                                </div><!--end card-body-->
                            </div><!--end card-->


                        </div><!--end col-->

                        <div class="col-lg-9">
                            <div class="row" v-if="isAttachment == false">
                                <div class="col-sm-4" v-for="item in attachmentListFolder " v-bind:key="item.id">

                                    <div class="card" v-on:click="attachmentListOptions(item)">
                                        <div class="card-body">
                                            <div class="row">

                                                <div class="col">
                                                    <div class="media">
                                                        <!-- <img src="assets/images/users/user-1.jpg" alt="user"
                                                            class="rounded-circle thumb-lg align-self-center"> -->
                                                        <div class="media-body ms-3 align-self-center">
                                                            <h5 class="mt-0 mb-1">{{ item.caseName }}</h5>
                                                            <p class="mb-0 text-muted">{{ item.customerName }}</p>
                                                        </div>
                                                    </div><!--end media-->
                                                </div><!--end col-->
                                                <!-- <div class="col-auto align-self-center">
                                                    <ul class="list-inline mb-0">
                                                        <li class="list-inline-item">
                                                            <a href="#" class="me-1 contact-icon"><i
                                                                    class="fas fa-phone"></i></a>
                                                            <a href="#" class="contact-icon"><i
                                                                    class="far fa-envelope"></i></a>
                                                        </li>
                                                    </ul>
                                                </div> -->
                                            </div><!--end row-->
                                        </div><!--end card-body-->
                                    </div>



                                </div>
                            </div>
                            <div class="row" v-else>
                                <div class="file-box-content">
                                    
                                    <div class="file-box" v-for=" attchments in attachment"
                                        v-bind:key="attchments.path">
                                        <a v-on:click="ViewAttachment(attchments.path)" class="download-icon-link me-1">
                                            <i class="dripicons-preview  file-download-icon"></i>
                                        </a>
                                        <a v-on:click="DownloadAttachment(attchments.path)" class="download-icon-link">
                                            <i class="dripicons-download file-download-icon"></i>
                                        </a>
                                        <div class="text-center">
                                            <i :class="getFileIconClass(attchments.fileName)"></i>
                                            <h6 class="text-truncate">{{ attchments.fileName }}</h6>
                                            <small class="text-muted">{{ attchments.date }}</small>
                                        </div>
                                    </div>

                                </div>
                            </div>


                        </div><!--end col-->
                    </div><!--end row-->
                    <div class="row">

                        <div class="card">

                            <bulk-attachment :attachmentList="newCustomer.attachmentList" :show="show" v-if="show"
                                @close="attachmentSave" />
                        </div>

                    </div>
                </div>
            </div>
            <attachment-view :documentpath="documentpath" :show="showView" v-if="showView" @close="CloseModel" />


        </div>

    </div>
    <div v-else>
        <acessdenied></acessdenied>
    </div>
</template>
<script>
import clickMixin from '@/Mixins/clickMixin'

export default {
    mixins: [clickMixin],
    name: 'Currency',
    data: function () {
        return {
            attachmentListFolder: [],
            attachmentList: [],
            folderList: [],
            attachment: [],
            isAttachment: false,
            showView: false,
            documentpath: '',


        }
    },


    methods: {
        DownloadZipFile(){
            var root = this;
            var token = '';
            if (root.$session.exists()) {
                token = localStorage.getItem('token');
            }
            let id = '00000000-0000-0000-0000-000000000000';
            root.$https.get('/Contact/DownloadZipFile?isFileManager=true&id=' + id, { headers: { "Authorization": `Bearer ${token}` }, responseType: 'blob' })
                .then(function (response) {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'files.zip');
                    document.body.appendChild(link);
                    link.click();
                });
        },
        DownloadCaseZipFile(){
            var root = this;
            var token = '';
            if (root.$session.exists()) {
                token = localStorage.getItem('token');
            }
            let id = this.attachment[0].documentId;
            root.$https.get('/Contact/DownloadZipFile?isFileManager=false&id=' + id, { headers: { "Authorization": `Bearer ${token}` }, responseType: 'blob' })
                .then(function (response) {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'files.zip');
                    document.body.appendChild(link);
                    link.click();
                });
        },
        CloseModel: function () {
            this.showView = false;
        },
        getFileIconClass(fileName) {
            const extension = fileName.slice((fileName.lastIndexOf(".") - 1 >>> 0) + 2); // Extract file extension
            if (extension === 'png') {
                return 'lar la-file-alt text-primary';
            } else if (extension === 'pdf') {
                return 'lar la-file-code text-danger';
            }
            else if (extension === 'zip') {
                return 'lar la-file-archive text-warning';
            }
            else {
                // Default icon class for other file types
                return 'lar la-file text-secondary';
            }
        },
        DownloadAttachment(path) {
            

            var root = this;
            var token = '';
            if (root.$session.exists()) {
                token = localStorage.getItem('token');
            }
            var ext = path.split('.')[1];
            root.$https.get('/Contact/DownloadFile?filePath=' + path, { headers: { "Authorization": `Bearer ${token}` }, responseType: 'blob' })
                .then(function (response) {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'file.' + ext);
                    document.body.appendChild(link);
                    link.click();
                });
        },
        ViewAttachment: function (path) {
            

            if (path != '' && path != undefined && path != null) {
                this.documentpath = path;
                this.showView = true;
            }
        },
        attachmentListOptions: function (attachment) {
            
            this.attachment = attachment.attachmentList;
            this.isAttachment = true;
        },
        GotoPage: function (link) {
            this.$router.push({ path: link });
        },
        GetCurrentFolder: function (folderName) {
            this.isAttachment = false;
            if (folderName === 'All') {
                this.attachmentListFolder = this.attachmentList;
            }
            else {
                this.attachmentListFolder = this.attachmentList.filter(attachment => {
                    return attachment.customerName === folderName;
                });
            }



        },



        alertFunc: function () {
            var root = this;
            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }
            root.$https.get('/Case/CaseAttaachmentQuery', { headers: { "Authorization": `Bearer ${token}` } })
                .then(function (response) {
                    if (response.data != null) {
                        



                        const seenNames = new Set(['All']); // Start with 'All' as it's always added first

                        root.folderList = response.data.map(x => {
                            return {
                                customerName: x.customerName,
                                isActive: false
                            };
                        }).filter(item => {
                            // Check if we have seen the customerName before
                            const isDuplicate = seenNames.has(item.customerName);
                            // Add the customerName to the Set
                            seenNames.add(item.customerName);
                            // Keep the item only if it's not a duplicate
                            return !isDuplicate;
                        });

                        // Insert 'All' option at the beginning of the array
                        root.folderList.unshift({
                            customerName: 'All',
                            isActive: true,
                        });
                        root.attachmentList = response.data;

                        if (root.folderList.length > 0) {
                            root.folderList
                            root.GetCurrentFolder('All');

                        }
                        root.isAttachment = false;






                    } else {
                        console.log("error: something wrong from db.");
                    }
                },
                    function (error) {
                        this.loading = false;
                        console.log(error);
                    });


        }

    },
    created: function () {
        this.$emit('input', this.$route.name);
    },
    mounted: function () {
        
        this.alertFunc();
    }
}
</script>