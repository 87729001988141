<template>
    <div class=" justify-content-center">
        <div class="card">
            <div class="card-header text-center">
                <h1>Stripe Payment Successfully done</h1>
            </div>
        </div>
    </div>
</template>

<script>
    export default({
        created:function(){
            var root = this;
            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }
            let url = '/Case/UpdateLoaStatus?id=' + root.$route.query.id + '&caseStatus=3&isPaymentVoucher=true';
            
            console.log(url)
            root.$https.get(url, { headers: { "Authorization": `Bearer ${token}` } })
                .then(function (response) {
                    root.$router.push({
                        path:'/CaseUser',
                        query:{
                            fromDashboard:true,
                            formName:'CaseCustomer'
                        }
                    })
                    console.log(response)
                },
                    function (error) {
                        this.loading = false;
                        console.log(error);
                    });
        }
    })
</script>