<template>
    <div class="row">
        <div class="row">
            <div class="col-sm-12">
                <div class="page-title-box">
                    <div class="row">
                        <div class="col">
                            <h4 class="page-title">Cases</h4>
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="javascript:void(0);">Dastone</a></li>
                                <li class="breadcrumb-item active">Dashboard</li>
                            </ol>
                        </div><!--end col-->
                        <div class="col-auto align-self-center">
                            <a href="#" class="btn btn-sm btn-outline-primary" id="Dash_Date">
                                <span class="day-name" id="Day_Name">Today:</span>&nbsp;
                                <span class="" id="Select_date">Jan 11</span>
                                <i data-feather="calendar" class="align-self-center icon-xs ms-1"></i>
                            </a>

                        </div><!--end col-->
                    </div><!--end row-->
                </div><!--end page-title-box-->
            </div><!--end col-->
        </div><!--end row-->
        <div class="row">
            <div class="col-12 col-lg-6 col-xl">
                <div class="card">

                    <div class="card-body">
                        <div class="row align-items-center">
                            <div class="col text-center">
                                <span class="h4">{{ totalCases.total }}</span>
                                <h6 class="text-uppercase text-muted mt-2 m-0">Total Cases</h6>
                            </div><!--end col-->
                        </div> <!-- end row -->
                    </div><!--end card-body-->
                </div> <!--end card-body-->
            </div><!--end col-->

            <div class="col-12 col-lg-6 col-xl" v-if="role == 'User'">
                <div class="card">
                    <div class="card-body">
                        <div class="row align-items-center">
                            <div class="col text-center">
                                <span class="h4">{{ totalCases.totalInvoices }}</span>
                                <h6 class="text-uppercase text-muted mt-2 m-0">Total Invoices</h6>
                            </div><!--end col-->
                        </div> <!-- end row -->
                    </div><!--end card-body-->
                </div> <!--end card-body-->
            </div><!--end col-->
            <div class="col-12 col-lg-6 col-xl" v-else>
                <div class="card">
                    <div class="card-body">
                        <div class="row align-items-center">
                            <div class="col text-center">
                                <span class="h4">{{ totalCases.runningCse }}</span>
                                <h6 class="text-uppercase text-muted mt-2 m-0">Running Case</h6>
                            </div><!--end col-->
                        </div> <!-- end row -->
                    </div><!--end card-body-->
                </div> <!--end card-body-->
            </div><!--end col-->
            <div class="col-12 col-lg-6 col-xl" v-if="role == 'User'">
                <div class="card">

                    <div class="card-body">
                        <div class="row align-items-center">
                            <div class="col text-center">
                                <span class="h4">{{ totalCases.due }}</span>
                                <h6 class="text-uppercase text-muted mt-2 m-0">Due Amount</h6>
                            </div><!--end col-->
                        </div> <!-- end row -->
                    </div><!--end card-body-->
                </div> <!--end card-body-->
            </div><!--end col-->
            <div class="col-12 col-lg-6 col-xl" v-else>
                <div class="card">

                    <div class="card-body">
                        <div class="row align-items-center">
                            <div class="col text-center">
                                <span class="h4">{{ totalCases.rejectedCase }}</span>
                                <h6 class="text-uppercase text-muted mt-2 m-0">Rejected Case</h6>
                            </div><!--end col-->
                        </div> <!-- end row -->
                    </div><!--end card-body-->
                </div> <!--end card-body-->
            </div><!--end col-->
            <div class="col-12 col-lg-6 col-xl" v-if="role == 'User'">
                <div class="card">
                    <div class="card-body">
                        <div class="row align-items-center">
                            <div class="col text-center">
                                <span class="h4">{{ totalCases.received }}</span>
                                <h6 class="text-uppercase text-muted mt-2 m-0">Received Amount</h6>
                            </div><!--end col-->
                        </div> <!-- end row -->
                    </div><!--end card-body-->
                </div> <!--end card-->
            </div><!--end col-->
            <div class="col-12 col-lg-6 col-xl" v-else>
                <div class="card">
                    <div class="card-body">
                        <div class="row align-items-center">
                            <div class="col text-center">
                                <span class="h4">{{ totalCases.pendingCase }}</span>
                                <h6 class="text-uppercase text-muted mt-2 m-0">Pending Cases</h6>
                            </div><!--end col-->
                        </div> <!-- end row -->
                    </div><!--end card-body-->
                </div> <!--end card-->
            </div><!--end col-->
        </div><!--end row-->

        <div class="row">
            <div class="col-lg-6" v-if="role != 'User'">
                <div class="card">
                    <div class="card-header">
                        <div class="row align-items-center">
                            <div class="col">
                                <h4 class="card-title">New Case Request</h4>
                            </div><!--end col-->
                        </div> <!--end row-->
                    </div><!--end card-header-->
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table mb-0">
                                <thead class="table-light">
                                    <tr>
                                        <th class="border-top-0">#</th>
                                        <th class="border-top-0">Case Name</th>
                                        <th class="border-top-0">Client Name</th>
                                        <th class="border-top-0"> Status</th>
                                        <th class="border-top-0 text-center"> Action</th>
                                    </tr><!--end tr-->
                                </thead>
                                <tbody>
                                    <template v-for=" (item, index) in caseList">
                                        <tr v-if="item.caseStatus == 2" v-bind:key="item.id">
                                            <td>{{ index + 1 }}</td>
                                            <td>{{ item.caseName }}</td>
                                            <td>{{ item.customerName }}</td>
                                            <td><span class="badge badge-soft-success">{{ item.status }}</span></td>

                                            <td class="text-center">
                                                <button type="button" class="btn btn-light dropdown-toggle"
                                                    data-bs-toggle="dropdown" aria-expanded="false">{{ $t('Sale.Action')
                                                    }}
                                                    <i class="mdi mdi-chevron-down"></i></button>
                                                <div class="dropdown-menu">
                                                    <a v-if="item.caseStatus == 2" class="dropdown-item"
                                                        href="javascript:void(0)" v-on:click="AcceptLOA(item)">Review
                                                    </a>
                                                    <a v-else-if="item.caseStatus == 4" class="dropdown-item"
                                                        href="javascript:void(0)"
                                                        v-on:click="CreateInvoice(item)">Create Invoice
                                                    </a>
                                                    <!-- <a v-else class="dropdown-item" href="javascript:void(0)"
                                                            >View Invoice
                                                            </a> -->

                                                </div>
                                            </td>
                                        </tr>
                                    </template>

                                </tbody>
                            </table> <!--end table-->
                        </div><!--end /div-->
                    </div><!--end card-body-->
                </div><!--end card-->
            </div> <!--end col-->
            <div class="col-lg-6">
                <div class="card">
                    <div class="card-header">
                        <div class="row align-items-center">
                            <div class="col">
                                <h4 class="card-title">Running Case</h4>
                            </div><!--end col-->
                        </div> <!--end row-->
                    </div><!--end card-header-->
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table mb-0">
                                <thead class="table-light">
                                    <tr>
                                        <th class="border-top-0">#</th>
                                        <th class="border-top-0">Case Name</th>
                                        <th class="border-top-0">Client Name</th>
                                        <th class="border-top-0"> Status</th>
                                        <th class="border-top-0 text-center"> Action</th>
                                    </tr><!--end tr-->
                                </thead>
                                <tbody>
                                    <template v-for=" (item, index) in runningCase">
                                        <tr v-if="item.caseStatus !== 2 && item.caseStatus !== 6" v-bind:key="item.id">
                                            <td>{{ index + 1 }}</td>
                                            <td>{{ item.caseName }}</td>
                                            <td>{{ item.customerName }}</td>
                                            <td><span class="badge badge-soft-success">{{ item.status }}</span></td>

                                            <td class="text-center">
                                                <button type="button" v-on:click="PageDetail(item)" class="btn btn-light "
                                                    data-bs-toggle="dropdown" aria-expanded="false">Open The Case
                                                    </button>
                                                
                                            </td>
                                        </tr>
                                    </template>

                                </tbody>
                            </table> <!--end table-->
                        </div><!--end /div-->
                    </div><!--end card-body-->
                </div><!--end card-->
            </div> <!--end col-->
            <div class="col-lg-6" v-if="role != 'User'">
                <div class="card">
                    <div class="card-header">
                        <div class="row align-items-center">
                            <div class="col">
                                <h4 class="card-title">Rejected Case </h4>
                            </div><!--end col-->
                        </div> <!--end row-->
                    </div><!--end card-header-->
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table mb-0">
                                <thead class="table-light">
                                    <tr>
                                        <th class="border-top-0">#</th>
                                        <th class="border-top-0">Case Name</th>
                                        <th class="border-top-0">Client Name</th>
                                        <th class="border-top-0"> Status</th>
                                        <th class="border-top-0 text-center"> Action</th>
                                    </tr><!--end tr-->
                                </thead>
                                <tbody>
                                    <template v-for=" (item, index) in caseList">
                                        <tr v-if="item.caseStatus == 6" v-bind:key="item.id">
                                            <td>{{ index + 1 }}</td>
                                            <td>{{ item.caseName }}</td>
                                            <td>{{ item.customerName }}</td>
                                            <td><span class="badge badge-soft-success">{{ item.status }}</span></td>

                                            <td class="text-center">
                                                
                                            </td>
                                        </tr>
                                    </template>

                                </tbody>
                            </table> <!--end table-->
                        </div><!--end /div-->
                    </div><!--end card-body-->
                </div><!--end card-->
            </div> <!--end col-->
        </div><!--end row-->




        <DesignForAuthority :key="designForAuthority" :show="showLoa" v-if="showLoa" @close="onClose"
            :caseRecord="caseRecord">
        </DesignForAuthority>
    </div>
</template>
<script>

import clickMixin from '@/Mixins/clickMixin'
import moment from "moment";

export default {

    mixins: [clickMixin],

    data: function () {

        return {
            totalCases: {
               
               total: 0,
               runningCse: 0,
               pendingCase: 0,
               rejectedCase: 0,
               due: 0,
               received: 0,
               totalInvoices: 0,
           },
            newCurrency: {
                id: '00000000-0000-0000-0000-000000000000',
                name: '',
                nameArabic: '',
                sign: '',
                type: '',
                image: '',
                isActive: true,
                isDisable: false,
            },

            caseRecord: '',
            showLoa: false,
            designForAuthority: 0,
            printRender: 0,
            caseList: [],
            runningCase: [],
            show: false,
            isShown: false,
            isCaseUser: false,
            loading: true,

            search: '',
            role:''


        }
    },
    watch: {

    },
    methods: {
        onClose: function () {
            this.showLoa = false;
            this.alertFunc();
            //this.alertFunc()
        },
        AcceptLOA: function (item) {
            this.caseRecord = item;
            this.showLoa = true;
            this.designForAuthority++;
        },
        PageDetail: function (record) {
            this.$router.push({
                path: '/CaseView',
                query: {
                    data: record,
                }
            })
        },
        getDate: function (date) {
            return moment(date).format('l');
        },
        CreateInvoice: function (caseObj) {
            this.$router.push({
                path: '/AddSaleService?formName=' + 'ServiceProformaInvoice',
                query: {
                    customerId: caseObj.contactId,
                    productId: caseObj.productId,
                    caseId: caseObj.id,
                    isCaseInvoice: 'true'
                }
            });
        },


        SaveCustomer: function () {


            this.loading = true;
            var root = this;
            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }


            var customerName = {
                id: '00000000-0000-0000-0000-000000000000',
                code: '',
                multipleAddress: false,
                isAddressOnAll: false,
                priceLabelId: '',
                prefix: '',
                englishName: '',
                arabicName: '',
                companyNameEnglish: '',
                companyNameArabic: '',
                customerDisplayName: '',
                telephone: '',
                email: '',
                registrationDate: '',
                category: '',
                customerType: '',
                customerGroup: '',
                contactNo1: '',
                accountId: '',

                commercialRegistrationNo: '',
                vatNo: '',
                customerGroupId: '',
                currencyId: '',
                regionId: '',
                taxRateId: '',
                customerCode: '',
                website: '',

                billingAttention: '',
                billingCountry: '',
                billingZipCode: '',
                billingPhone: '',
                billingArea: '',
                billingAddress: '',
                billingCity: '',
                billingFax: '',

                shippingAttention: '',
                shippingCountry: '',
                shippingZipCode: '',
                shippingPhone: '',
                shippingArea: '',
                shippingAddress: '',
                shippingCity: '',
                shippingFax: '',

                contactPersonList: [{
                    prefix: '',
                    firstName: '',
                    lastName: '',
                    email: '',
                    phone: '',
                    mobile: ''
                }],
                deliveryAddressList: [],
                productList: [],
                remarks: '',
                isCustomer: true,
                isVendor: false,
                isActive: true,
                isCashCustomer: true,

                paymentTerms: '',
                deliveryTerm: '',
                creditLimit: '',
                creditPeriod: '',

                attachmentList: [],
                userId: '',
                isCaseCustomer: true
            };
            customerName.userId = localStorage.getItem('UserId')

            root.$https
                .post('/Contact/SaveContact', customerName, {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                })
                .then(response => {
                    console.log(response);

                })
                .catch(error => {
                    console.log(error)
                    root.$swal.fire({
                        icon: 'error',
                        title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Error!' : 'خطأ',
                        text: error.response.data,
                        showConfirmButton: false,
                        timer: 5000,
                        timerProgressBar: true,
                    });

                    root.loading = false
                })
                .finally(() => root.loading = false)
        },

        sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },

        EditprintSetting: function () {
            var root = this;
            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }



            root.$https.get('/Sale/printSettingDetail', { headers: { "Authorization": `Bearer ${token}` } })
                .then(function (response) {
                    if (response.data != null && response.data != '') {
                        localStorage.setItem('CashAccountId', response.data.cashAccountId);
                        localStorage.setItem('BankAccountId', response.data.bankAccountId);
                        localStorage.setItem('BankName', response.data.bankName);
                        localStorage.setItem('AccountName', response.data.accountName);
                        localStorage.setItem('AccoutNo', response.data.accoutNo);
                        localStorage.setItem('BranchName', response.data.branchName);
                        localStorage.setItem('BranchName', response.data.branchName);
                        localStorage.setItem('IsHeaderFooter', response.data.isHeaderFooter);



                    } else {
                        console.log("error: something wrong from db.");
                    }
                },
                    function (error) {
                        this.loading = false;
                        console.log(error);
                    });

        },
        alertFunc: function () {
            var root = this;
            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }

            var empId = localStorage.getItem('EmployeeId') =='null'?'00000000-0000-0000-0000-000000000000':localStorage.getItem('EmployeeId')

            root.$https.get('/Case/GetCaseQuery?isDashboard=true&employeeId='+ empId, { headers: { "Authorization": `Bearer ${token}` } })
                .then(function (response) {
                    if (response.data != null) {

                        root.caseList = response.data;

                        root.runningCase = response.data.filter(x => x.status != 'Pending');

                        if(response.data.length>0)
                        {
                            root.totalCases = {
                            total: response.data.length,
                            runningCse: response.data.filter(x => x.status != 'Pending' && x.status != 'Rejected').length,
                            pendingCase: response.data.filter(x => x.status == 'Pending').length,
                            rejectedCase: response.data.filter(x => x.status == 'Rejected').length,
                            due: response.data[0].dueAmount,
                            received: response.data[0].receivedAmount,
                            totalInvoices: response.data[0].totalInvoices
                        };

                        }
                        


                        console.log("error: something wrong from db.");

                    } else {
                        console.log("error: something wrong from db.");
                    }
                },
                    function (error) {
                        this.loading = false;
                        console.log(error);
                    });


        }
    },
    created: function () {

        this.$emit('input', this.$route.name);
    },
    mounted: function () {
        this.role = localStorage.getItem('RoleName');
        let isCaseSystem = localStorage.getItem('IsCaseSystem') == 'true' ? true : false;

        if ((localStorage.getItem('RoleName') == 'Admin' || localStorage.getItem('RoleName') == 'User') && isCaseSystem == true) {

            this.alertFunc();
        }


        this.isCaseUser = localStorage.getItem('IsCaseUser') == 'true' ? true : false;

        this.currency = localStorage.getItem('currency');

        this.fromDate = moment().startOf('month').format("DD MMM YYYY");

        if (this.$session.exists()) {
            this.userID = localStorage.getItem('UserID');
            this.employeeId = localStorage.getItem('EmployeeId');
            this.fromDate = moment().startOf('month').format("DD MMM YYYY");

        }
        if (this.isCaseUser) {
            this.SaveCustomer();
        }
        debugger;

        this.EditprintSetting();

    },
}
</script>