<template>
    <div class="row" v-if="isValid('CanViewBrand')">       

        <div class="col-lg-12">
            <div class="row">
                <div class="col-sm-12">
                    <div class="page-title-box">
                        <div class="row">
                            <div class="col">
                                <h4 class="page-title">Payment Gate Way</h4>
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="javascript:void(0);">{{ $t('brand.Home') }}</a></li>
                                    <li class="breadcrumb-item active">Payment Gate Way</li>
                                </ol>
                            </div>
                            <div class="col-auto align-self-center">
                                <a v-if="isValid('CanAddBrand')" v-on:click="openmodel"
                                   href="javascript:void(0);" class="btn btn-sm btn-outline-primary mx-1">
                                    <i class="align-self-center icon-xs ti-plus"></i>
                                    {{ $t('SubCategory.AddNew') }}
                                </a>
                                <a v-on:click="GotoPage('/StartScreen')" href="javascript:void(0);"
                                   class="btn btn-sm btn-outline-danger">
                                    {{ $t('SubCategory.Close') }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

           

            <AddPaymenGateWay :brand="newBrand"
                        :show="show"
                        v-if="show"
                        @close="IsSave"
                        :type="type" />
        </div>

    </div>
    <div v-else>
        <acessdenied></acessdenied>
    </div>
    
</template>


<script>
    import clickMixin from '@/Mixins/clickMixin'
    export default {
        mixins: [clickMixin],
        data: function () {
            return {
                searchQuery: '',
                show: false,
                brandlist: [],
                newBrand: {
                    id: '',
                    name: '',
                    nameArabic: '',
                    description: '',
                    code: '',
                    isActive: true
                },
                type: '',
                search: '',
                currentPage: 1,
                pageCount: '',
                rowCount: '',
                arabic: '',
                english: '',
            }
        },
        watch: {
            // search: function (val) {
            //     this.GetBrandData(val, 1);
            // }
        },
        methods: {

            search22: function () {
            this.GetBrandData(this.search, this.currentPage, this.active);
        },

        clearData: function () {
            this.search = "";
            this.GetBrandData(this.search, this.currentPage, this.active);

        },
            IsSave: function () {
                
                this.show = false;

                this.GetBrandData(this.search, this.currentPage);
            },
            getPage: function () {
                this.GetBrandData(this.search, this.currentPage);
            },
            GotoPage: function (link) {
            this.$router.push({ path: link });
            },
            openmodel: function () {
                this.newBrand = {
                    merchantID: 100001,
                action: 'SALE',
                type: 1,
                currencyCode: 826,
                countryCode: 826,
                amount: 1001,
                cardNumber: '4012001037141112',
                cardExpiryMonth: 12,
                cardExpiryYear: 15,
                cardCVV: '083',
                customerName: 'Test Customer',
                customerEmail: 'test@testcustomer.com',
                customerAddress: '16 Test Street',
                customerPostCode: 'TE15 5ST',
                orderRef: 'Test purchase',
                remoteAddress: this.userRemoteAddress, // Assuming you will get user's remote address via Vue instance or method
                threeDSRedirectURL: this.pageUrl + '&acs=1', // Assuming you have a computed property or data for pageUrl
                threeDSOptions: {
                    paymentAccountAge: '20190601',
                    paymentAccountAgeIndicator: '05',
                },
                }

                this.show = !this.show;
                this.type = "Add";
            },
         
            EditBrand: function () {
                let key = 'Circle4Take40Idea'; 
                 let url = 'https://gateway.example.com/direct/'; 

               console.log(key);
                
                this.$https.get(url)
                    .then(function (response) {
                         //eslint-disable-line
                        if (response.data) {
                          alert("Ok");
                        } else {
                            console.log("error: something wrong from db.");
                        }
                    },
                        function (error) {
                            this.loading = false;
                            console.log(error);
                        });

            }
        },
        created: function () {
            this.$emit('input', this.$route.name);
        },
        mounted: function () {
            this.english = 'true';
            this.arabic = localStorage.getItem('Arabic');
            this.EditBrand();

        }
    }
</script>