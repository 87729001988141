<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="row">
                <div class="col">
                    <h4 class="page-title">Create Ticket Invoices</h4>
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="javascript:void(0);">Home</a></li>
                        <li class="breadcrumb-item active">Ticket Invoices</li>
                    </ol>
                </div>
                <div class="col-auto align-self-center">
                    <a v-on:click="GotoPage('/Ticket')" href="javascript:void(0);"
                        class="btn btn-sm btn-outline-danger">
                        Close
                    </a>
                </div>

            </div>
            <hr class="hr-dashed hr-menu mt-0" />

            <div class="row">

                <div class="form-group has-label col-lg-3 ">
                    <label class="text  font-weight-bolder">Invoice No: <span class="text-danger"> *</span> </label>
                    <input class="form-control" v-model="$v.ticket.invoiceNo.$model" />
                    <span v-if="$v.ticket.invoiceNo.$error" class="error">
                        <span v-if="!$v.ticket.invoiceNo.required"> Invoice No is required</span>
                    </span>
                </div>

                <div class="form-group has-label col-lg-3 ">
                    <label class="text  font-weight-bolder">Invoice Date: </label>

                    <datepicker v-model="ticket.invoiceDate" :key="render"/>

                </div>
                <div class="form-group has-label col-lg-3 ">
                    <label class="text  font-weight-bolder">Departure Date: <span class="text-danger"> *</span> </label>

                    <datepicker v-model="$v.ticket.departureDate.$model" :key="render"/>
                    <span v-if="$v.ticket.departureDate.$error" class="error">
                        <span v-if="!$v.ticket.departureDate.required"> Departure date is required</span>
                    </span>
                </div>
                <div class="form-group has-label col-lg-3 ">
                    <label class="text  font-weight-bolder" :key="render">Arrival Date: </label>

                    <datepicker v-model="ticket.arrivalDate" />

                </div>

            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-header pb-0 pt-0">
                            <h4>Add Passengers</h4>
                        </div>
                        <div class="card-body">
                            <div class="row">

                                <div class="form-group has-label col-lg-4 ">
                                    <label class="text  font-weight-bolder">Name: </label>
                                    <input class="form-control" v-model="passanger.name" />

                                </div>
                                <div class="form-group has-label col-lg-3 ">
                                    <label class="text  font-weight-bolder">Vendor: </label>
                                    <vendor-dropdown v-model="passanger.vendor" :values="passanger.vendor"
                                        :key="rander"></vendor-dropdown>

                                </div>
                                <div class="form-group has-label col-lg-3 ">
                                    <label class="text  font-weight-bolder">Unit Price: </label>
                                    <input class="form-control" type="number" v-model="passanger.unitPrice" />

                                </div>
                                <div class="col-lg-2 align-self-center">
                                    <a v-on:click="processPassenger()" v-show="isShow" href="javascript:void(0);"
                                        class="btn btn-primary mx-1">
                                        Apply
                                    </a>

                                </div>
                            </div>
                            <div class="table-responsive mt-3" v-if="!isShow">
                                <table class="table mb-0" style="table-layout:fixed;">
                                    <thead class="thead-light">
                                        <tr>
                                            <th class="text-center">
                                                Name
                                            </th>
                                            <th class="text-center">
                                                Phone
                                            </th>
                                            <th class="text-center">
                                                Email
                                            </th>
                                            <th class="text-center">
                                                Default
                                            </th>

                                            <th class="text-center">

                                            </th>


                                        </tr>
                                    </thead>
                                    <tbody>
                                        <template v-for="(row, index) in addPassengerRow">
                                            <tr :key="index">

                                                <td class="border-top-0 text-center">
                                                    <input type="text" v-model="row.name"
                                                        class="form-control text-center" />
                                                </td>
                                                <td class="border-top-0 text-center">
                                                    <input type="text" v-model="row.phone"
                                                        class="form-control text-center" />
                                                </td>
                                                <td class="border-top-0 text-center">
                                                    <input type="text" v-model="row.email"
                                                        class="form-control text-center" />
                                                </td>
                                                <td class="border-top-0 text-center">
                                                    <input type="checkbox" v-model="row.default"
                                                        class="checkbox form-check-inline mx-2">

                                                </td>
                                                <td class="border-top-0 text-center">
                                                    <a href="javascript:void(0);" @click="removePassenger(index)"><i
                                                            class="las la-trash-alt text-secondary font-16"></i></a>
                                                </td>

                                            </tr>
                                        </template>
                                    </tbody>
                                </table>

                                <div>
                                    <a v-on:click="addNewPassenger()" href="javascript:void(0);"
                                        class="btn btn-primary mx-1">
                                        Add Another Passenger
                                    </a>
                                    <a v-on:click="confirmPassenger()" href="javascript:void(0);"
                                        class="btn btn-primary mx-1">
                                        Confirm Passenger
                                    </a>
                                </div>

                            </div>

                        </div>

                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="card">

                        <div class="card-body">
                            <h4>Tickets</h4>
                            <div class="card" v-for="(ticketObj,index) in ticket.ticketItems" v-bind:key="ticketObj.index">

                                <div class="card-header p-0">
                                    <a href="javascript:void(0);" @click="removeTicket(index)" style="float: right;"><i
                                                            class="las la-trash-alt text-danger font-16" style="width: 30px;height: 30px;"></i></a>
                                </div>
                                <div class="card-body">
                                    <div class="ticket-details">
                                        <div class="col-2">

                                            <label class="text  font-weight-bolder">Passenger: </label>
                                            <multiselect v-model="ticketObj.passenger" :options="passengerOptions"
                                                :multiple="false" placeholder="Passenger" track-by="name"
                                                :clear-on-select="false" :show-labels="false" label="name">

                                            </multiselect>


                                        </div>
                                        <div class="col-2">

                                            <label class="text  font-weight-bolder">Vendor: </label>
                                            <vendor-dropdown v-model="ticketObj.vendorId"
                                                :values="ticketObj.vendorId"></vendor-dropdown>

                                        </div>
                                        <div class="col-2">

                                            <label class="text  font-weight-bolder">Ticket No: </label>
                                            <input class="form-control" v-model="ticketObj.ticketNo" />

                                        </div>
                                        <div class="col-2">

                                            <label class="text  font-weight-bolder">Net Price: </label>
                                            <input class="form-control" type="number" v-model="ticketObj.netPrice" />

                                        </div>
                                        <div class="col-2">

                                            <label class="text  font-weight-bolder">Unit Price: </label>
                                            <input class="form-control" type="number" v-model="ticketObj.unitPrice"
                                                v-on:input="updateLineTotal(ticketObj)" />

                                        </div>
                                        <div class="col-2">

                                            <label class="text  font-weight-bolder">Quantity: </label>
                                            <input class="form-control" type="number" v-model="ticketObj.quantity"
                                                v-on:input="updateLineTotal(ticketObj)" />

                                        </div>
                                        <div class="col-2">

                                            <label class="text  font-weight-bolder">Total Price: </label>
                                            <input class="form-control" type="number" v-model="ticketObj.totalPrice" />

                                        </div>
                                    </div>
                                    <div class="accordion" id="accordionExample">
                                    <div class="accordion-item">
                                        <h5 class="accordion-header m-0" id="headingOne">
                                            <button class="accordion-button fw-semibold" type="button"
                                                    data-bs-toggle="collapse" data-bs-target="#collapseOne"
                                                    aria-expanded="false" aria-controls="collapseOne">
                                                Attach Tickets
                                            </button>
                                        </h5>
                                        <div id="collapseOne" class="accordion-collapse collapse "
                                             aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                <div class="row">
                                                    
                                                    <div class="col-lg-12 form-group">
                                                        <label class="col-form-label">
                                                            <span class="tooltip-container text-dashed-underline ">
                                                               Ticket:
                                                            </span>
                                                        </label>
                                                        <textarea class="form-control" v-model="ticketObj.attachTicketRemarks"></textarea>
                                                    </div>
                                                    
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    
                                </div>
                                </div>

                            </div>
                            <a v-on:click="addTicket()" href="javascript:void(0);" class="btn btn-primary mx-1">
                                Add Ticket
                            </a>
                        </div>

                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">

                                <div class="form-group has-label col-sm-12 ">
                                    <label class="text  font-weight-bolder"> Remark: </label>
                                    <VueEditor v-model="ticket.remarks" />

                                </div>
                                <div class="form-group has-label col-sm-12 ">
                                    <toggle-button v-model="ticket.isSeparateRemarks" :key="render" class="ml-2 mt-2" color="#3178F6" />
                                    <span style="font-weight: normal; font-size: medium; padding: 10px; ">Make remark in
                                        separate row</span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="form-group has-label col-sm-12 ">
                                    <toggle-button v-model="ticket.isAddTicketAll" :key="render" class="ml-2 mt-2" color="#3178F6" />
                                    <span style="font-weight: bold; font-size: large; padding: 10px; ">Add Ticket For
                                        All</span>
                                </div>
                                <div class="form-group has-label col-sm-12 " v-if="ticket.isAddTicketAll">
                                    <label class="text  font-weight-bolder"> Ticket for All: </label>
                                    <VueEditor v-model="ticket.allTicketRemarks" />

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="form-group has-label col-sm-12 ">
                                    <toggle-button v-model="ticket.isConfidentialNote" :key="render" class="ml-2 mt-2" color="#3178F6" />
                                    <span style="font-weight: bold; font-size: large;padding: 10px; ">Add Confidential
                                        Notes</span>
                                </div>
                                <div class="form-group has-label col-sm-12 " v-if="ticket.isConfidentialNote">
                                    <label class="text  font-weight-bolder"> Confidential notes: </label>
                                    <VueEditor v-model="ticket.confidentialNote" />

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-6">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="form-group has-label col-sm-12 ">
                                    <label class="text  font-weight-bolder"> Bill Type: <span class="text-danger">
                                            *</span></label>

                                    <multiselect v-model="ticket.billType" :options="['Customer', 'Passenger', 'Reference']"
                                        :show-labels="false" placeholder="Bill Type" :preselect-first="true">
                                    </multiselect>

                                </div>
                                <div class="form-group has-label col-sm-12 " v-if="ticket.billType == 'Passenger'">
                                    <label class="text  font-weight-bolder"> Passenger : </label>


                                    <multiselect v-model="passengerObj" @input="onCahngePassenger(passengerObj.id)"
                                        :options="passengerOptions" :multiple="false" placeholder="Select Passenger"
                                        track-by="name" :clear-on-select="false" :show-labels="false" label="name">

                                    </multiselect>
                                    <!-- <passengerdropdown></passengerdropdown> -->

                                </div>
                                <div class="form-group has-label col-sm-12 " v-if="ticket.billType == 'Customer'">
                                    <label class="text  font-weight-bolder"> Customer : </label>
                                    <customerdropdown v-model="ticket.billTo"></customerdropdown>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="form-group has-label col-lg-12 " v-if="formState == 'add'">
                                    <label class="text  font-weight-bolder">Payable: <span class="text-danger"> *</span>
                                    </label>
                                    <input class="form-control" type="number" disabled v-model="$v.ticket.payable.$model" />
                                    
                                </div>
                                <div class="form-group has-label col-lg-6 " v-if="formState == 'edit'">
                                    <label class="text  font-weight-bolder">Payable: <span class="text-danger"> *</span>
                                    </label>
                                    <input class="form-control" type="number" disabled v-model="$v.ticket.payable.$model" />
                                </div>
                                <div class="form-group has-label col-lg-6 " v-if="formState == 'edit'">
                                    <label class="text  font-weight-bolder">Previously Pay: <span class="text-danger"> *</span>
                                    </label>
                                    <input class="form-control" type="number" disabled v-model="ticket.previouslyPay" />
                                    
                                </div>
                                <div class="form-group has-label col-lg-6 ">
                                    <label class="text  font-weight-bolder">Discount:
                                    </label>
                                    <input @input="updateSummary()" class="form-control" type="number"
                                        v-model="ticket.discount" />

                                </div>
                                <div class="form-group has-label col-lg-6 ">
                                    <label class="text  font-weight-bolder">Net Payable: <span class="text-danger"> *</span>
                                    </label>
                                    <input class="form-control" type="number" disabled
                                        v-model="$v.ticket.netPayable.$model" />
                                    <span v-if="$v.ticket.netPayable.$error" class="error">
                                        <span v-if="!$v.ticket.netPayable.required"> Net Payable is required</span>
                                    </span>
                                </div>
                                <div class="form-group has-label col-lg-6 ">
                                    <label class="text  font-weight-bolder">Currently Paying:
                                    </label>
                                    <input @input="updateSummary()" class="form-control" type="number"
                                        v-model="ticket.currentlyPaying" />

                                </div>
                                <div class="form-group has-label col-lg-6 ">
                                    <label class="text  font-weight-bolder">Due:
                                    </label>
                                    <input class="form-control" type="number" disabled v-model="ticket.due" />

                                </div>
                                <div class="form-group has-label col-lg-12 ">
                                    <label class="text  font-weight-bolder">Bank Account:
                                    </label>

                                    <accountdropdown v-model="ticket.bankCashAccountId" :disabled="false" />
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <button type="button" class="btn btn-primary " v-on:click="saveTicket()"
                        v-bind:disabled="$v.ticket.$invalid">Save</button>
                        <button v-on:click="GotoPage('/Ticket')" href="javascript:void(0);"
                        class="btn btn-outline-danger">
                        Cancel
                    </button>
                </div>
            </div>

            <loading :active.sync="loading" :can-cancel="false" :is-full-page="true">
            </loading>
        </div>

    </div>
</template>

<script>
import clickMixin from '@/Mixins/clickMixin'
import Loading from 'vue-loading-overlay';
import moment from "moment";
import { VueEditor } from "vue2-editor";

import "vue-loading-overlay/dist/vue-loading.css";
import {
    required
} from "vuelidate/lib/validators";
import Multiselect from 'vue-multiselect'
//import VueBarcode from 'vue-barcode';
export default {
    props: ['formName'],

    mixins: [clickMixin],
    components: {
        Multiselect,
        VueEditor,

        Loading
    },
    data: function () {
        return {
            formState:'add',
            passanger: {
                id: '',
                vendor: '',
                unitPrice: 0,
                totalPrice: 0,

            },
            rander: 0,
            render: 0,
            loading: false,
            passengerOptions: [],
            isShow: true,
            isConfirm: false,
            addPassengerRow: [],
            passengerObj: '',
            vendorObj: '',
            ticket: {
                id: '00000000-0000-0000-0000-000000000000',
                invoiceNo: '',
                invoiceDate: '',
                departureDate: '',
                arrivalDate: '',
                remarks: '',
                isSeparateRemarks: false,
                isAddTicketAll: false,
                allTicketRemarks: '',
                isConfidentialNote: false,
                confidentialNote: '',
                billType: '',
                billTo: '',
                ticketItems: [],
                payable: 0,
                previouslyPay: 0,
                fromBalance: '',
                cashPaid: 0,
                discount: 0,
                netPayable: 0,
                currentlyPaying: 0,
                due: 0,
                bankCashAccountId: '',
                ticketType: 'Pending',
            }
        }
    },
    validations: {
        ticket: {
            invoiceNo: {
                required
            },
            departureDate: {
                required
            },
            billType: {
                required
            },
            // billTo: {
            //     required
            // },
            payable: {
                required
            },
            netPayable: {
                required
            },

        }
    },
    methods:
    {

        onCahngePassenger: function (id) {
            this.ticket.billTo = id;
        },
        createUUID: function () {

            var dt = new Date().getTime();
            var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                var r = (dt + Math.random() * 16) % 16 | 0;
                dt = Math.floor(dt / 16);
                return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
            });
            return uuid;
        },
        updateLineTotal: function (ticketItem) {
            ticketItem.totalPrice = ticketItem.unitPrice * ticketItem.quantity;
            this.updateSummary();
        },
        updateSummary: function () {
            this.ticket.payable = this.ticket.ticketItems.reduce((tPrice, tick) => tPrice + parseFloat(tick.totalPrice == '' ? 0 : tick.totalPrice), 0)
            this.ticket.netPayable = parseFloat(this.ticket.payable) - parseFloat(this.ticket.discount);
            this.ticket.due = parseFloat(this.ticket.netPayable) - parseFloat(this.ticket.currentlyPaying) + parseFloat(this.ticket.previouslyPay);
            this.ticket.previouslyPay = parseFloat(this.ticket.currentlyPaying) + parseFloat(this.ticket.previouslyPay)
        },
        getPassengerData: function () {
            var root = this;
            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }
            this.$https.get('/Sale/GetPassengerList?isDropdown=true', { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {

                if (response.data != null) {
                    response.data.results.forEach(function (result) {
                        root.passengerOptions.push({
                            id: result.id,
                            name: result.name,
                            description: result.description,
                        })
                    })
                }
            })
        },
        saveTicket: function () {
            var root = this;
            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }
            this.$https.post('/Sale/SaveTicket', this.ticket, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {

                if (response.data != null) {

                    root.$swal({
                        title: 'Saved Successfully',
                        text: 'Saved',
                        type: 'success',
                        confirmButtonClass: "btn btn-success",
                        buttonStyling: false,
                        icon: 'success',
                        timer: 1500,
                        timerProgressBar: true,

                    });
                    root.$router.go();
                }
                else {
                    root.$swal({
                        title: 'Error!',
                        text: 'There is something wrong. Please contact to support.',
                        type: 'error',
                        confirmButtonClass: "btn btn-danger",
                        icon: 'error',
                        timer: 1500,
                        timerProgressBar: true,
                    });
                }
            })
                .catch(error => {
                    console.log(error)
                    root.$swal.fire({
                        icon: 'error',
                        title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Error!' : 'خطأ',
                        text: error.response.data,
                        showConfirmButton: false,
                        timer: 5000,
                        timerProgressBar: true,
                    });

                    root.loading = false
                })
        },
        getAutoInvoiceNumber: function () {
            var root = this;
            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }
            this.$https.get('/Sale/GetTicketAutoCode', { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {

                if (response.data != null) {
                    root.ticket.invoiceNo = response.data;
                }
            })
        },
        confirmPassenger: function () {

            for (let i = 0; i < this.addPassengerRow.length; i++) {
                let passengerRec = this.addPassengerRow[i];
                let newPassengerObj = {
                    id: passengerRec.id,
                    name: passengerRec.name,
                    phone: passengerRec.phone,
                    email: passengerRec.email
                };
                if (passengerRec.default) {
                    this.ticket.billType = 'Passenger';
                    this.passengerObj = newPassengerObj
                }
                // Push new records into ticketItem based on passenger data
                this.ticket.ticketItems.push({
                    index: i + 1,
                    passengerId: passengerRec.id,
                    name: passengerRec.name,
                    phone: passengerRec.phone,
                    isDefault: passengerRec.default,
                    vendorId: passengerRec.vendor,
                    ticketNo: '',
                    totalPrice: passengerRec.unitPrice * 1,
                    netPrice: 0,
                    unitPrice: passengerRec.unitPrice,
                    quantity: 1,
                    ticketId: '',
                    passenger: newPassengerObj,
                });
                this.passengerOptions.push({
                    id: passengerRec.id,
                    name: passengerRec.name,
                    description: '',
                })
            }
            this.isConfirm = true;
            this.addPassengerRow = [];
            this.isShow = true;
            this.passanger = {
                id: '',
                vendor: '',
                unitPrice: 0,
                totalPrice: 0,

            };
            this.rander++;
            this.updateSummary();

        },
        addNewPassenger: function () {
            var root = this;
            this.addPassengerRow.push({
                id: root.createUUID(),
                name: '',
                email: '',
                phone: '',
                default: false
            });

        },
        addTicket: function () {
            this.ticket.ticketItems.push({
                index: this.ticket.ticketItems.length + 1,
                passengerId: '',
                name: '',
                phone: '',
                isDefault: false,
                vendorId: '',
                ticketNo: '',
                netPrice: 0,
                unitPrice: 0,
                totalPrice: 0,
                quantity: 1,
                ticketId: '',
                passenger: ''
            });

        },
        processPassenger: function () {
            // Split the comma-separated text into an array
            const textsArray = this.passanger.name.split(',');
           
            var root = this;
            this.addPassengerRow = [];
            
            // Update the rows array with the generated text fields
            textsArray.map((text, index) => {
                root.addPassengerRow.push({
                    id: root.createUUID(),
                    name: text.trim() || `Text ${index + 1}`,
                    email: '',
                    vendor: this.passanger.vendor,
                    unitPrice: this.passanger.unitPrice,
                    phone: '',
                    default: false
                });

            })
            this.isShow = false;
            //this.addPassengerRow = textsArray.map((text, index) => text.trim() || `Text ${index + 1}`);
        },
        removePassenger: function (index) {
            this.addPassengerRow.splice(index, 1);
        },
        removeTicket: function (index) {
            this.ticket.ticketItems.splice(index, 1);
        },
        GotoPage: function (link) {
            this.$router.push({
                path: link
            });
        },
        findPassengerById:function(id){
            this.passengerObj = this.passengerOptions.find(x=>x.id == id);
        },
        getTicketRecordById:function(){
            var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
            
                root.$https.get('Sale/GetTicket?' + '&Id=' + this.$route.query.id, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        
                        root.ticket.id = response.data.id;
                        root.ticket.invoiceNo = response.data.invoiceNo;
                        root.ticket.invoiceDate =  moment(response.data.invoiceDate).format("LLL");
                        root.ticket.departureDate =  moment(response.data.departureDate).format("LLL");
                        root.ticket.arrivalDate =  moment(response.data.arrivalDate).format("LLL");
                        root.ticket.remarks = response.data.remarks;
                        root.ticket.isSeparateRemarks = response.data.isSeparateRemarks;
                        root.ticket.confidentialNote = response.data.confidentialNote;
                        root.ticket.isConfidentialNote = response.data.isConfidentialNote;
                        root.ticket.allTicketRemarks = response.data.allTicketRemarks;
                        root.ticket.isAddTicketAll = response.data.isAddTicketAll;
                        root.ticket.billTo = response.data.billTo;
                        root.ticket.billType = response.data.billType;
                        root.ticket.payable = response.data.payable;
                        root.ticket.cashPaid = response.data.cashPaid;
                        root.ticket.discount = response.data.discount;
                        root.ticket.netPayable = response.data.netPayable;
                        root.ticket.currentlyPaying = 0;
                        root.ticket.due = response.data.due;
                        root.ticket.bankCashAccountId = response.data.bankCashAccountId;
                        root.ticket.ticketItems = response.data.ticketItems;
                        root.ticket.ticketType = response.data.ticketType;
                        root.ticket.previouslyPay = response.data.previouslyPay == null?0:response.data.previouslyPay;
                        root.findPassengerById(response.data.billTo);
                        root.render++;
                        root.formState = 'edit'
                    }
                    root.loading = false;
                });
        }



    },
    created: function () {
        
        
        this.getPassengerData();
        if(this.$route.query.id != null){
            this.getTicketRecordById();
        }
        else{
            this.getAutoInvoiceNumber();
            this.ticket.invoiceDate = moment().format("LLL");
        }

    },
    mounted: function () {


    },
};
</script>
<style scoped>
.ticket-details {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;
}

.ticket-details>div {
    flex: 0 0 calc(100% / 7);
    /* Divide into 7 equal columns */
    padding: 0 5px;
    /* Adjust spacing between columns */
    box-sizing: border-box;
    margin-bottom: 10px;
}

/* Adjust responsiveness for smaller screens */
@media (max-width: 768px) {
    .ticket-details>div {
        flex: 0 0 calc(100% / 2);
        /* For smaller screens, display 2 columns per row */
    }
}
</style>