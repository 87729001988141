<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="row">
                <div class="col-sm-12">
                    <div class="page-title-box">
                        <div class="row">
                            <div class="col">
                                <h4 class="page-title">Day Wise Report</h4>
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="javascript:void(0);">{{
                                        $t('InvoicePrintReport.Home') }}</a></li>
                                    <li class="breadcrumb-item active">Day Wise Report</li>
                                </ol>
                            </div>
                            <div class="col-auto align-self-center">
                                <a v-on:click="printInvoice()" href="javascript:void(0);"
                                    class="btn btn-sm btn-outline-primary mx-1">
                                    <i class="fas fa-print font-14"></i>
                                    {{ $t('Print') }}
                                </a>
                                <a v-on:click="GotoPage('/StartScreen')" href="javascript:void(0);"
                                    class="btn btn-sm btn-outline-danger">
                                    {{ $t('Categories.Close') }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" v-bind:key="render" v-bind:class="($i18n.locale == 'en' || isLeftToRight()) ? '' : 'pr-3'">
                <div class="col-md-3 col-lg-3">
                    <div class="form-group ml-3 ">
                        <label>{{ $t('StockReport.FromDate') }}</label>
                        <datepicker v-model="fromDate" :key="render" />
                    </div>
                </div>
                <div class="col-md-3 col-lg-3">
                    <div class="form-group ">
                        <label>{{ $t('StockReport.ToDate') }}</label>
                        <datepicker v-model="toDate" :key="render" />
                    </div>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3" id="hide">

                    <button v-on:click="GetInvoiceRecord(true)" type="button" class="btn btn-outline-primary mt-3">
                        {{ $t('Sale.ApplyFilter') }}
                    </button>
                    <button v-on:click="GetInvoiceRecord(false)" type="button"
                        class="btn btn-outline-primary mx-2 mt-3">
                        {{ $t('Sale.ClearFilter') }}
                    </button>

                </div>


            </div>
            <div class="card">




                <div class="card-body" id="content">
                   <div class="row" id='purchaseInvoice'  style="background-color:white">
                     <!--HEADER-->
                     <div class="row">
                        <div class="col-sm-6">
                            Date : {{ getDate(list.date) }}
                        </div>
                        <div class="col-sm-6">
                            <h3 class="text-left">One Day Report</h3>
                        </div>
                    </div>

                    <div style="background-color:white;margin-top:1mm;">
                        <div class="col-12">
                            <div class=" pt-4 " style="background-color:white">
                                <table class="table ">

                                    <tr class="heading"
                                        style="font-size:18px;border-bottom:1px solid;padding-bottom:15px;border: 1px solid black !important">
                                        <th
                                            style="width:2%;padding-top:1px !important; padding-bottom:1px !important;border: 1px solid black !important">
                                            #</th>
                                        <th class="text-center"
                                            style="padding-top:1px !important; padding-bottom:1px !important;color:black !important;border: 1px solid black !important;">
                                            Inv. No</th>
                                        <th class="text-left"
                                            style="padding-top:1px !important; padding-bottom:1px !important;color:black !important;border: 1px solid black !important ;">
                                            Product</th>
                                        <th class="text-center"
                                            style="padding-top:1px !important; padding-bottom:1px !important;color:black !important;border: 1px solid black !important ;">
                                            Total Amount</th>
                                    </tr>
                                    <tr style="font-size:15px;border: 1px solid black !important">
                                        <td colspan="5" class="text-center"
                                            style="border: 1px solid black !important; border-bottom:0 !important;color:black !important;">
                                            Purchase</td>
                                    </tr>
                                    <tr style="font-size:17px;" v-for="(item, index) in list.purchaseList"
                                        v-bind:key="item.id">
                                        <td class="text-center"
                                            style="padding-top:8px !important; padding-bottom:1px !important;border: 1px solid black !important; border-bottom:0 !important;color:black !important;">
                                            {{ index + 1 }}</td>
                                        <td class="text-center"
                                            style="border: 1px solid black !important;color:black !important;">
                                            {{ item.invoiceNo }}</td>
                                        <td class="text-left"
                                            style="border: 1px solid black !important;color:black !important;"><span
                                                v-html="item.productName"></span></td>
                                        <td class="text-center" collspan="2"
                                            style="border: 1px solid black !important;color:black !important;">
                                            {{ (parseFloat(item.totalAmount)).toFixed(3).slice(0,
                                        -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                            "$1,") }}</td>
                                        <td class="text-center" collspan="2"
                                            style="border: 1px solid black !important;color:black !important;">
                                        </td>
                                    </tr>

                                    <tr style="font-size:15px;">
                                        <td colspan="3" class="text-center"
                                            style="border: 1px solid black !important; border-bottom:0 !important;color:black !important;">
                                            {{ $t('Total') }}</td>
                                        <td class="text-center"
                                            style="border: 1px solid black !important;color:black !important;"> {{
                                        (parseFloat(list.totalPurchase)).toFixed(3).slice(0,
                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                "$1,") }}</td>
                                        <td class="text-center"
                                            style="border: 1px solid black !important;color:black !important;"> </td>
                                    </tr>

                                    <tr style="font-size:15px;">
                                        <td colspan="4" class="text-center"
                                            style="border: 1px solid black !important; border-bottom:0 !important;color:black !important;">
                                            Sales</td>
                                        <td class="text-center"
                                            style="border: 1px solid black !important;font-weight: bold; border-bottom:0 !important;color:black !important;">
                                            Profit</td>
                                    </tr>
                                    <tr style="font-size:17px;" v-for="(item, index) in list.saleList"
                                        v-bind:key="item.id">
                                        <td class="text-center"
                                            style="border: 1px solid black !important; border-bottom:0 !important;color:black !important;">
                                            {{ index + 1 }}</td>
                                        <td class="text-center"
                                            style="border: 1px solid black !important;color:black !important;">
                                            {{ item.invoiceNo }}</td>
                                        <td class="text-center"
                                            style="border: 1px solid black !important;color:black !important;"><span
                                                v-html="item.productName"></span></td>
                                        <td class="text-center"
                                            style="border: 1px solid black !important;color:black !important;">
                                            {{ (parseFloat(item.totalAmount)).toFixed(3).slice(0,
                                        -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                            "$1,") }}</td>
                                        <td class="text-center"
                                            style="border: 1px solid black !important;color:black !important;">
                                            {{ (parseFloat(item.profit)).toFixed(3).slice(0,
                                        -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                            "$1,") }}</td>
                                    </tr>

                                    <tr style="font-size:15px;border: 1px solid black !important">
                                        <td colspan="3" class="text-center"
                                            style="border:1px !important; border-bottom:0 !important;color:black !important;">
                                            {{ $t('Total') }}</td>
                                        <td class="text-center" style="border: 1px !important;color:black !important;">
                                            {{
                                        (parseFloat(list.totalSales)).toFixed(3).slice(0,
                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                "$1,") }}</td>
                                        <td class="text-center" style="border: 1px !important;color:black !important;">
                                            {{ (parseFloat(Math.abs(totalAmount))).toFixed(3).slice(0,
                                        -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                            "$1,") }}</td>
                                    </tr>


                                    <tr style="font-size:15px;border: 1px solid black !important">
                                        <td colspan="5" class="text-center"
                                            style="border:1px solid !important;color:black !important;">Expenditure</td>
                                    </tr>
                                    <tr style="font-size:17px;" v-for="(item, index) in list.expenseList"
                                        v-bind:key="item.id">
                                        <td class="text-center"
                                            style="border: 1px solid black !important; border-bottom:0 !important;color:black !important;">
                                            {{ index + 1 }}</td>
                                        <td class="text-center"
                                            style="border: 1px solid black !important;color:black !important;">
                                            {{ item.invoiceNo }}</td>
                                        <td class="text-center"
                                            style="border: 1px solid black !important;color:black !important;"><span
                                                v-html="item.productName"></span></td>
                                        <td class="text-center" colspan="5"
                                            style="border: 1px solid black !important;color:black !important;">
                                            {{ (parseFloat(item.totalAmount)).toFixed(3).slice(0,
                                        -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                            "$1,") }}</td>
                                    </tr>

                                    <tr style="font-size:15px;border: 1px solid black !important">
                                        <td colspan="3" class="text-center"
                                            style="border: 1px solid black !important; border-bottom:0 !important;color:black !important;">
                                            {{ $t('Total') }}</td>
                                        <td class="text-center"
                                            style="border: 1px solid black !important;color:black !important;"> {{
                                        (parseFloat(list.totalExpense)).toFixed(3).slice(0,
                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                "$1,") }}</td>
                                        <td class="text-center"
                                            style="border: 1px solid black !important;color:black !important;"> </td>
                                    </tr>
                                    <tr
                                        style="font-size:15px;background-color:azure;border: 1px solid black !important">
                                        <td colspan="3" class="text-center" style="border: 1px solid black !important">
                                            Total Profit(Sales-(Purchase+Expense))
                                        </td>




                                        <td class="text-center">
                                            {{ list.totalProft >= 0 ? 'Cr' : 'Dr' }}
                                            {{ (parseFloat(Math.abs(list.totalProft))).toFixed(3).slice(0,
                                        -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                            "$1,") }}
                                        </td>

                                    </tr>


                                </table>



                                <div class="row">

                                    <table class="table ">
                                        <tr style="font-size:17px;font-weight:bold;">
                                            <td colspan="4" class="text-center"
                                                style="color:black !important;padding-top: 2px !important;padding-bottom: 2px !important">
                                                Today's Due Report
                                            </td>
                                        </tr>
                                        <tr style="font-size:17px" v-for="item in list.dueList" v-bind:key="item.id">
                                            <!-- <td class="text-left"> <span style="font-size: 15px;font-weight: bold;">{{ item.customerName }}</span></td>
                            <td class="text-left">  -   Due Amount</td>
                            <td class="text-left"><span style="font-size: 15px;font-weight: bold;">{{ currency }} &nbsp;{{item.amount}} </span></td>
                            <td class="text-left"><span>Against Invoice No.&nbsp;</span><span style="font-size: 15px;font-weight: bold;">&nbsp;{{ item.invoiceNo }}</span></td> -->
                                            <td colspan="4" class="text-left"
                                                style="color:black !important;padding-top: 0px !important;padding-bottom: 0px !important">
                                                <span style="font-size: 15px;font-weight: bold;">{{ item.customerName
                                                    }}</span> &nbsp; - Due Amount <span
                                                    style="font-size: 15px;font-weight: bold;">&nbsp; {{ currency }}
                                                    &nbsp;{{ item.amount }} &nbsp;</span><span>Against Invoice
                                                    No.&nbsp;</span><span
                                                    style="font-size: 15px;font-weight: bold;">&nbsp;{{ item.invoiceNo
                                                    }}</span>
                                            </td>
                                        </tr>
                                        <tr style="font-size:17px;font-weight:bold;">
                                            <td colspan="4" class="text-center"
                                                style="color:black !important;padding-top: 0px !important;padding-bottom: 0px !important">
                                                Total:

                                                {{ currency }}
                                                {{
                                        (parseFloat(list.totalDueList)).toFixed(3).slice(0,
                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                "$1,") }}
                                            </td>
                                        </tr>

                                        <tr style="font-size:17px;font-weight:bold;">
                                            <td colspan="4" class="text-center"
                                                style="color:black !important;padding-top: 2px !important;padding-bottom: 2px !important">
                                                Payment Recevied Record </td>
                                        </tr>
                                        <tr style="font-size:17px;" v-for="item in list.paymentList"
                                            v-bind:key="item.id">
                                            <td colspan="4" class="text-left"
                                                style="color:black !important;padding-top: 0px !important;padding-bottom: 0px !important">
                                                <span style="font-size: 15px;font-weight: bold;">{{ item.customerName
                                                    }}</span> &nbsp; - Paid Amount <span
                                                    style="font-size: 15px;font-weight: bold;">&nbsp; {{ currency }}
                                                    &nbsp;{{ item.amount }} &nbsp;</span><span>in Account
                                                    No:/</span><span style="font-size: 15px;font-weight: bold;">{{
                                        item.accountName
                                    }}&nbsp;</span><span v-if="item.invoiceNo != null">Against Invoice
                                                    No.&nbsp;<span style="font-size: 15px;font-weight: bold;">&nbsp;{{
                                        item.invoiceNo }}</span></span>
                                            </td>
                                        </tr>
                                        <tr style="font-size:17px;font-weight:bold;">
                                            <td colspan="4" class="text-center"
                                                style="color:black !important;padding-top: 0px !important;padding-bottom: 0px !important">
                                                Total:
                                                {{ currency }}
                                                {{
                                        (parseFloat(list.totalPaymentList)).toFixed(3).slice(0,
                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                "$1,") }}
                                            </td>
                                        </tr>
                                        <tr style="font-size:17px;font-weight:bold;">
                                            <td colspan="4" class="text-center"
                                                style="color:black !important;padding-top: 2px !important;padding-bottom: 2px !important">
                                                Payment Pay to Supplier </td>
                                        </tr>
                                        <tr style="font-size:17px;" v-for="item in list.purchasePaymentList"
                                            v-bind:key="item.id">
                                            <td colspan="4" class="text-left"
                                                style="color:black !important;padding-top: 0px !important;padding-bottom: 0px !important">
                                                <span style="font-size: 15px;font-weight: bold;">{{ item.customerName
                                                    }}</span>&nbsp;-Paid Amount <span
                                                    style="font-size: 15px;font-weight: bold;">&nbsp; {{ currency }}
                                                    &nbsp;{{ item.amount }} &nbsp;</span><span>from Account
                                                    No:/&nbsp;</span><span style="font-size: 15px;font-weight: bold;">{{
                                        item.accountName }}&nbsp;</span><span
                                                    v-if="item.invoiceNo != null">Against Invoice No.&nbsp; &nbsp;<span
                                                        style="font-size: 15px;font-weight: bold;">&nbsp;{{
                                        item.invoiceNo }}</span></span>
                                            </td>
                                        </tr>
                                        <tr style="font-size:17px;font-weight:bold;">
                                            <td colspan="4" class="text-center"
                                                style="color:black !important;padding-top: 0px !important;padding-bottom: 0px !important">
                                                Total:
                                                {{ currency }}
                                                {{
                                        (parseFloat(list.totalPurchasePaymentList)).toFixed(3).slice(0,
                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                "$1,") }}
                                            </td>
                                        </tr>
                                        <tr style="font-size:17px;font-weight:bold;">
                                            <td colspan="4" class="text-center"
                                                style="color:black !important;padding-top: 2px !important;padding-bottom: 2px !important">
                                                Other Transction Record
                                            </td>
                                        </tr>
                                        <tr class="heading"
                                            style="font-size:18px;border-bottom:1px solid;padding-bottom:15px;border: 1px solid black !important">
                                            <th
                                                style="width:2%;padding-top:1px !important; padding-bottom:1px !important;border: 1px solid black !important">
                                                #</th>
                                            <th class="text-center"
                                                style="padding-top:1px !important; padding-bottom:1px !important;color:black !important;border: 1px solid black !important;">
                                                Account Name</th>
                                            <th class="text-center"
                                                style="padding-top:1px !important; padding-bottom:1px !important;color:black !important;border: 1px solid black !important ;">
                                                With Draw</th>
                                            <th class="text-center"
                                                style="padding-top:1px !important; padding-bottom:1px !important;color:black !important;border: 1px solid black !important ;">
                                                Deposit</th>
                                        </tr>

                                        <tr style="font-size:17px;" v-for="(item, index) in list.otherTransactionList"
                                            v-bind:key="item.id">
                                            <td class="text-center"
                                                style="color:black !important;padding-top: 0px !important;padding-bottom: 0px !important">
                                                {{ index + 1 }}</td>
                                            <td class="text-left"
                                                style="color:black !important;padding-top: 0px !important;padding-bottom: 0px !important">
                                                {{ item.accountName }}</td>
                                            <td class="text-center"
                                                style="color:black !important;padding-top: 0px !important;padding-bottom: 0px !important">
                                                {{ item.debit }}</td>
                                            <td class="text-center"
                                                style="color:black !important;padding-top: 0px !important;padding-bottom: 0px !important">
                                                {{ item.credit }}
                                            </td>

                                        </tr>
                                        <tr style="font-size:17px;font-weight:bold;">
                                            <td colspan="4" class="text-center"
                                                style="color:black !important;padding-top: 2px !important;padding-bottom: 2px !important">
                                                Transaction On Cash & Bank
                                            </td>
                                        </tr>
                                        <tr style="font-size:17px;">
                                            <td colspan="3" class="text-right"
                                                style="color:black !important;padding-top: 0px !important;padding-bottom: 0px !important">
                                                Payment On Cash:
                                            </td>
                                            <td class="text-center"
                                                style="color:black !important;padding-top: 0px !important;padding-bottom: 0px !important">

                                                {{ currency }}
                                                {{
                                        (parseFloat(Math.abs(list.totalCash))).toFixed(3).slice(0,
                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                "$1,") }}
                                            </td>

                                        </tr>
                                        <tr style="font-size:17px;">
                                            <td colspan="3" class="text-right"
                                                style="color:black !important;padding-top: 0px !important;padding-bottom: 0px !important">
                                                Payment On Bank:
                                            </td>
                                            <td class="text-center"
                                                style="color:black !important;padding-top: 0px !important;padding-bottom: 0px !important">
                                                {{ currency }}
                                                {{
                                        (parseFloat(Math.abs(list.totalBank))).toFixed(3).slice(0,
                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                "$1,") }}
                                            </td>

                                        </tr>
                                        <tr style="font-size:17px;font-weight:bold;">
                                            <td colspan="4" class="text-center"
                                                style="color:black !important;padding-top: 2px !important;padding-bottom: 2px !important">
                                                Today Closing Balance
                                            </td>
                                        </tr>
                                        <tr style="font-size:17px;">
                                            <td colspan="3" class="text-right"
                                                style="color:black !important;padding-top: 0px !important;padding-bottom: 0px !important">
                                                Total Sale Service
                                            </td>
                                            <td class="text-center"
                                                style="color:black !important;padding-top: 0px !important;padding-bottom: 0px !important">
                                                {{ currency }} {{
                                        (parseFloat(list.totalServiceSales)).toFixed(3).slice(0,
                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                "$1,") }}
                                            </td>
                                        </tr>
                                        <tr style="font-size:17px;">
                                            <td colspan="3" class="text-right"
                                                style="color:black !important;padding-top: 0px !important;padding-bottom: 0px !important">
                                                Total Cash Sale Service
                                            </td>
                                            <td class="text-center"
                                                style="color:black !important;padding-top: 0px !important;padding-bottom: 0px !important">
                                                {{ currency }} {{
                                        (parseFloat(list.totalServiceCashSales)).toFixed(3).slice(0,
                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                "$1,") }}
                                            </td>
                                        </tr>
                                        <tr style="font-size:17px;">
                                            <td colspan="3" class="text-right"
                                                style="color:black !important;padding-top: 0px !important;padding-bottom: 0px !important">
                                                Total Due Sale Service:
                                            </td>
                                            <td class="text-center"
                                                style="color:black !important;padding-top: 0px !important;padding-bottom: 0px !important">
                                                {{ currency }} {{
                                        (parseFloat(list.totalDueServiceList)).toFixed(3).slice(0,
                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                "$1,") }}

                                            </td>
                                        </tr>
                                        <tr style="font-size:17px;">
                                            <td colspan="3" class="text-right"
                                                style="color:black !important;padding-top: 0px !important;padding-bottom: 0px !important">
                                                Total Sale Service Due received:
                                            </td>
                                            <td class="text-center"
                                                style="color:black !important;padding-top: 0px !important;padding-bottom: 0px !important">
                                                {{ currency }}
                                                {{
                                        (parseFloat(list.totalPaymentServiceList)).toFixed(3).slice(0,
                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                "$1,") }}
                                            </td>
                                        </tr>
                                        <tr style="font-size:17px;">
                                            <td colspan="3" class="text-right"
                                                style="color:black !important;padding-top: 0px !important;padding-bottom: 0px !important">
                                                Total Sale
                                            </td>
                                            <td class="text-center"
                                                style="color:black !important;padding-top: 0px !important;padding-bottom: 0px !important">
                                                {{ currency }} {{
                                        (parseFloat(list.totalSales)).toFixed(3).slice(0,
                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                "$1,") }}
                                            </td>
                                        </tr>
                                        <tr style="font-size:17px;">
                                            <td colspan="3" class="text-right"
                                                style="color:black !important;padding-top: 0px !important;padding-bottom: 0px !important">
                                                Total Cash Sale
                                            </td>
                                            <td class="text-center"
                                                style="color:black !important;padding-top: 0px !important;padding-bottom: 0px !important">
                                                {{ currency }} {{
                                        (parseFloat(list.totalCashSales)).toFixed(3).slice(0,
                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                "$1,") }}
                                            </td>
                                        </tr>
                                        <tr style="font-size:17px;">
                                            <td colspan="3" class="text-right"
                                                style="color:black !important;padding-top: 0px !important;padding-bottom: 0px !important">
                                                Total Due:
                                            </td>
                                            <td class="text-center"
                                                style="color:black !important;padding-top: 0px !important;padding-bottom: 0px !important">
                                                {{ currency }} {{
                                        (parseFloat(list.totalDueList)).toFixed(3).slice(0,
                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                "$1,") }}

                                            </td>
                                        </tr>
                                        <tr style="font-size:17px;">
                                            <td colspan="3" class="text-right"
                                                style="color:black !important;padding-top: 0px !important;padding-bottom: 0px !important">
                                                Total Due received:
                                            </td>
                                            <td class="text-center"
                                                style="color:black !important;padding-top: 0px !important;padding-bottom: 0px !important">
                                                {{ currency }}
                                                {{
                                        (parseFloat(list.totalPaymentList)).toFixed(3).slice(0,
                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                "$1,") }}
                                            </td>
                                        </tr>
                                        <tr style="font-size:17px;">
                                            <td colspan="3" class="text-right"
                                                style="color:black !important;padding-top: 0px !important;padding-bottom: 0px !important">
                                                Total Purchase
                                            </td>
                                            <td class="text-center"
                                                style="color:black !important;padding-top: 0px !important;padding-bottom: 0px !important">
                                                {{ currency }} {{
                                        (parseFloat(list.totalPurchase)).toFixed(3).slice(0,
                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                "$1,") }}
                                            </td>
                                        </tr>
                                        <tr style="font-size:17px;">
                                            <td colspan="3" class="text-right"
                                                style="color:black !important;padding-top: 0px !important;padding-bottom: 0px !important">
                                                Total Cash - Total Puchase Payment
                                            </td>
                                            <td class="text-center"
                                                style="color:black !important;padding-top: 0px !important;padding-bottom: 0px !important">
                                                {{ currency }} {{
                                        (parseFloat(list.totalPurchase -
                                            list.totalCreditPurchase)).toFixed(3).slice(0,
                                                -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                    "$1,") }}
                                            </td>
                                        </tr>
                                        <tr style="font-size:17px;">
                                            <td colspan="3" class="text-right"
                                                style="color:black !important;padding-top: 0px !important;padding-bottom: 0px !important">
                                                Total Due paid (Supplier):
                                            </td>
                                            <td class="text-center"
                                                style="color:black !important;padding-top: 0px !important;padding-bottom: 0px !important">
                                                {{ currency }}
                                                {{
                                        (parseFloat(list.totalPurchasePaymentList)).toFixed(3).slice(0,
                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                "$1,") }}
                                            </td>
                                        </tr>
                                        <tr style="font-size:17px;">
                                            <td colspan="3" class="text-right"
                                                style="color:black !important;padding-top: 0px !important;padding-bottom: 0px !important">
                                                Total Expense
                                            </td>
                                            <td class="text-center"
                                                style="color:black !important;padding-top: 0px !important;padding-bottom: 0px !important">
                                                {{ currency }} {{
                                        (parseFloat(list.totalExpense)).toFixed(3).slice(0,
                                            -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                "$1,") }}
                                            </td>
                                        </tr>



                                        <tr style="font-size:17px;font-weight:bold;">
                                            <td colspan="3" class="text-right"
                                                style="color:black !important;padding-top: 0px !important;padding-bottom: 0px !important">
                                                Today’s Blance:
                                            </td>
                                            <td class="text-center"
                                                style="color:black !important;padding-top: 0px !important;padding-bottom: 0px !important">
                                                {{ currency }} {{
                                        (parseFloat(list.totalCashSales + list.totalServiceCashSales +
                                            list.totalPaymentList + list.totalPaymentServiceList)
                                            - (
                                                parseFloat(list.totalExpense + list.totalPurchasePaymentList +(list.totalPurchase-list.totalCreditPurchase))
                                                ) ).toFixed(3).slice(0,
                                                -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                "$1,") }}
                                            </td>
                                        </tr>


                                    </table>


                                </div>


                            </div>
                        </div>
                    </div>
                   </div>

                </div>


            </div>
        </div>
        <loading :active.sync="loading" :can-cancel="true" :is-full-page="false"></loading>

    </div>
</template>
<script>

import moment from "moment";
import clickMixin from '@/Mixins/clickMixin'
import Loading from 'vue-loading-overlay';
import "vue-loading-overlay/dist/vue-loading.css";

const options = {
    name: '',
    specs: [
        'fullscreen=no',
        'titlebar=yes',
        'scrollbars=yes'
    ],
    styles: [
        'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
        'https://unpkg.com/kidlat-css/css/kidlat.css',
    ],
    timeout: 700,
    autoClose: true,
    windowTitle: window.document.title,

}
export default {
    mixins: [clickMixin],

    props: ['printDetails', 'headerFooter', 'formName', 'fromDate', 'toDate', 'invoice', 'show'],
    components: {
        Loading,
    },
    data: function () {
        return {
            list: [],

            itemTotal: 0,
            listItemP1: [],
            listItemP2: [],
            listItemP3: [],

            isHeaderFooter: '',
            IsPaksitanClient: false,
            loading: false,
            invoicePrint: '',
            arabic: '',
            currency: '',
            english: '',
            render: 0,
            headerFooters: {
                footerEn: '',
                footerAr: '',
                company: ''
            }
        }
    },
    filters: {
        toWords: function (value) {
            var converter = require('number-to-words');
            if (!value) return ''
            return converter.toWords(value);
        }
    },
    computed: {

        totalAmount: function () {
            return this.list.saleList.reduce(function (a, c) {


                return a + Number((c.profit.toFixed(3).slice(0, -1) < 0 ? c.profit.toFixed(3).slice(0, -1) * -1 : c.profit.toFixed(3).slice(0, -1)) || 0)


            }, 0)
        },

    },

    methods: {

        GetInvoiceRecord: function (value) {
            if (value == false) {
                this.toDate = '';
                this.fromDate = '';
                this.render++;
                return;
            }

            this.loading=true;
            var root = this;
            var token = '';
            if (token == '') {
                token = localStorage.getItem('token');
            }
            let url;


            url = '/Report/OneDaySalePurchaseReport?fromDate=';




            this.$https.get(url + this.fromDate + '&toDate=' + this.toDate, { headers: { "Authorization": `Bearer ${token}` } })
                .then(function (response) {
                    if (response.data != null) {
                        root.loading=false;


                        root.list = response.data;
                    }
                }).catch(error => {

                    console.log(error)
                    root.$swal.fire(
                        {
                            icon: 'error',
                            title: 'No Record Exist!',
                            text: error.response.data,
                            showConfirmButton: false,
                            timer: 5000,
                            timerProgressBar: true,
                        });

                    root.loading = false
                });
        },
        close: function () {
            
            this.$emit('close');
        },
        getDate: function (date) {
            return moment(date).format('l');
        },
        printInvoice: function () {

            this.$htmlToPaper('purchaseInvoice', options, () => {




            });
        },



    },
    mounted: function () {

        this.english = localStorage.getItem('English');
        this.currency = localStorage.getItem('currency');
        this.arabic = localStorage.getItem('Arabic');
        this.isHeaderFooter = localStorage.getItem('IsHeaderFooter');
        this.invoicePrint = localStorage.getItem('InvoicePrint');
        this.toDate = moment().format("DD MMM YYYY");
        this.fromDate = moment().format('DD MMM YYYY');
        this.render++;

        this.GetInvoiceRecord();

        // var root = this;
        {

            // setTimeout(function () {
            //     root.printInvoice();
            // }, 125)

        }
    }
}
</script>
