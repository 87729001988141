import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { store } from './store'
import Vuelidate from 'vuelidate'
import i18n from './i18n'
import moment from 'moment'
import axios from 'axios'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import VueSession from 'vue-session'

import { BootstrapVue, BPagination } from 'bootstrap-vue'
Vue.use(BootstrapVue, BPagination);
import ElementUI from 'element-ui'
import { DatePicker } from 'element-ui';
import ar from 'element-ui/lib/locale/lang/ar'
import en from 'element-ui/lib/locale/lang/en'
import pt from 'element-ui/lib/locale/lang/pt'
import VueHtmlToPaper from 'vue-html-to-paper';
import Notifications from 'vue-notification'

const options = {
    name: '',
    specs: [
        'fullscreen=no',
        'titlebar=yes',
        'scrollbars=yes'
    ],
    styles: [
        '/assets/css/bootstrap.min.css',
        'https://unpkg.com/kidlat-css/css/kidlat.css'
    ],
    timeout: 1000,
    autoClose: true,
    windowTitle: window.document.title
}
Vue.use(VueHtmlToPaper, options);
Vue.use(VueHtmlToPaper);
Vue.use(Notifications);


import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale'
Vue.use(DatePicker);
Vue.use(VueSession)

Vue.use(ElementUI, { locale });
import 'vue2-timepicker/dist/VueTimepicker.css'
import 'vue-multiselect/dist/vue-multiselect.min.css';
import clickMixin from '@/Mixins/clickMixin';
import json from '../public/Config.json';
import VueApexCharts from 'vue-apexcharts';
Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts);
import { MonthPicker } from 'vue-month-picker'
import { MonthPickerInput } from 'vue-month-picker'

Vue.use(MonthPicker)
Vue.use(MonthPickerInput)
Vue.use(clickMixin);

Vue.prototype.$locale = locale;


if (i18n.locale === 'en') {
    locale.use(en)
}
else if (i18n.locale === 'pt') {
    locale.use(pt)
}
else {
    locale.use(ar);
}
import ToggleButton from 'vue-js-toggle-button';
Vue.use(ToggleButton);

//import the theme

// register VueFusionCharts component
Vue.use(VueSweetalert2);
Vue.use(Loading);
Vue.use(Vuelidate);
Vue.use(moment);
Vue.config.productionTip = false;
Vue.component('product-inventory-Report-print', () => import(/* webpackChunkName: "year-dropdown" */ './components/Reports/ProductInventoryReportPrint.vue'));
Vue.component('dailyExpenseA4', () => import(/* webpackChunkName: "year-dropdown" */ './components/Reports/DailyExpenseA4Report.vue'));
Vue.component('ProductFilterDownloadReport', () => import(/* webpackChunkName: "year-dropdown" */ './components/Reports/ProductFilterDownloadReport.vue'));
Vue.component('productPrintReport', () => import(/* webpackChunkName: "year-dropdown" */ './components/Reports/ProductPrintReport.vue'));
Vue.component('PaymentGateWay', () => import(/* webpackChunkName: "year-dropdown" */ './components/PaymentGateWay/AddPaymenGateWay.vue'));
Vue.component('HeaderComponent', () => import(/* webpackChunkName: "year-dropdown" */ './components/Reports/HeaderAndFooter/Header.vue'));
Vue.component('citydropdown', () => import(/* webpackChunkName: "year-dropdown" */ './components/General/CityDropdown.vue'));
Vue.component('paymentVoucherModel', () => import(/* webpackChunkName: "year-dropdown" */ './components/PaymentVouchers/PaymentVoucherModel.vue'));
Vue.component('addcity', () => import(/* webpackChunkName: "year-dropdown" */ './components/City/AddCity.vue'));
Vue.component('yeardropdown', () => import(/* webpackChunkName: "year-dropdown" */ './components/General/YearDropdown.vue'));
Vue.component('ColorPicker', () => import(/* webpackChunkName: "year-dropdown" */ './components/General/ColorPicker.vue'));
Vue.component('email-compose', () => import(/* webpackChunkName: "email-compose" */ './components/General/EmailCompose.vue'));
Vue.component('email-compose-received', () => import(/* webpackChunkName: "email-compose-received" */ './components/General/EmailComposeReceived.vue'));
Vue.component('category-multidropdown', () => import(/* webpackChunkName: "category-multi-dropdown" */ './components/General/CategoryMultiDropdown.vue'));
Vue.component('departmentmodel', () => import(/* webpackChunkName: "department-model" */ './components/Department/AddDepartment.vue'));
Vue.component('AutorityPrint', () => import(/* webpackChunkName: "department-model" */ './components/Department/AutorityPrint.vue'));
Vue.component('categorymodal', () => import(/* webpackChunkName: "category-modal" */ './components/Category/AddCategory.vue'));
Vue.component('subcategorymodal', () => import(/* webpackChunkName: "subcategory-modal" */ './components/SubCategory/AddSubCategory.vue'));
Vue.component('brandmodal', () => import(/* webpackChunkName: "brand-modal" */ './components/Brand/AddBrand.vue'));
Vue.component('originmodal', () => import(/* webpackChunkName: "origin-modal" */ './components/Origin/AddOrigin.vue'));
Vue.component('unitmodal', () => import(/* webpackChunkName: "unit-modal" */ './components/Unit/AddUnit.vue'));
Vue.component('sizemodal', () => import(/* webpackChunkName: "size-modal" */ './components/Size/AddSize.vue'));
Vue.component('colormodal', () => import(/* webpackChunkName: "color-modal" */ './components/Color/AddColor.vue'));
Vue.component('taxratemodal', () => import(/* webpackChunkName: "tax-rate-modal" */ './components/TaxRate/AddTaxRate.vue'));
Vue.component('subcategorydropdown', () => import(/* webpackChunkName: "subcategory-dropdown" */ './components/General/SubCategoryDropdown.vue'));
Vue.component('branddropdown', () => import(/* webpackChunkName: "brand-dropdown" */ './components/General/BrandDropdown.vue'));
Vue.component('origindropdown', () => import(/* webpackChunkName: "origin-dropdown" */ './components/General/OriginDropdown.vue'));
Vue.component('unitdropdown', () => import(/* webpackChunkName: "unit-dropdown" */ './components/General/UnitDropdown.vue'));
Vue.component('unitleveldropdown', () => import(/* webpackChunkName: "unit-level-dropdown" */ './components/General/UnitLevelDropdown.vue'));
Vue.component('sizedropdown', () => import(/* webpackChunkName: "size-dropdown" */ './components/General/SizeDropdown.vue'));
Vue.component('size-multiselect-dropdown', () => import(/* webpackChunkName: "size-multiselect-dropdown" */ './components/General/SizeMultiSelectDropdown.vue'));
Vue.component('colordropdown', () => import(/* webpackChunkName: "color-dropdown" */ './components/General/ColorDropdown.vue'));
Vue.component('color-multiselect-dropdown', () => import(/* webpackChunkName: "color-multiselect-dropdown" */ './components/General/Color-Multiselect-Dropdown.vue'));
Vue.component('warranty-type-dropdown', () => import(/* webpackChunkName: "warranty-type-dropdown" */ './components/General/WarrantyTypeDropdown.vue'));
Vue.component('salutation-dropdown', () => import(/* webpackChunkName: "salutation-dropdown" */ './components/General/SalutationDropdown.vue'));
Vue.component('display-name-dropdown', () => import(/* webpackChunkName: "display-name-dropdown" */ './components/General/DisplayNameDropdown.vue'));
Vue.component('sale-invoice-dropdown', () => import(/* webpackChunkName: "display-name-dropdown" */ './components/General/SaleInvoiceDropdown.vue'));
Vue.component('proforma-invoice-dropdown', () => import(/* webpackChunkName: "proforma-invoice-dropdown" */ './components/General/ProformaInvoiceDropdown.vue'));




//Leave
Vue.component('addleavetypes', () => import(/* webpackChunkName: "addleave-types" */ './components/Hr/LeaveManagement/LeaveTypes/AddLeaveTypes.vue'));
Vue.component('addleaveperiod', () => import(/* webpackChunkName: "addleave-period" */ './components/Hr/LeaveManagement/LeavePeriod/AddLeavePeriod.vue'));
Vue.component('addworkweek', () => import(/* webpackChunkName: "addwork-week" */ './components/Hr/LeaveManagement/WorkWeek/AddWorkWeek.vue'));
Vue.component('addholiday', () => import(/* webpackChunkName: "add-holiday" */ './components/Hr/LeaveManagement/Holiday/AddHoliday.vue'));
Vue.component('addleaverules', () => import(/* webpackChunkName: "addleave-rules" */ './components/Hr/LeaveManagement/LeaveRules/AddLeaveRules.vue'));
Vue.component('addleavegroups', () => import(/* webpackChunkName: "addleave-groups" */ './components/Hr/LeaveManagement/LeaveGroups/AddLeaveGroups.vue'));
Vue.component('addpaidtimeoff', () => import(/* webpackChunkName: "addpaid-time-off" */ './components/Hr/LeaveManagement/PaidTimeOff/AddPaidTimeOff.vue'));

Vue.component('leavegroupdropdown', () => import(/* webpackChunkName: "leave-group-dropdown" */ './components/General/LeaveGroupDropdown.vue'));
Vue.component('leavetypedropdown', () => import(/* webpackChunkName: "leave-type-dropdown" */ './components/General/LeaveTypeDropdown.vue'));
Vue.component('leaveperioddropdown', () => import(/* webpackChunkName: "leave-period-dropdown" */ './components/General/LeavePeriodDropdown.vue'));

Vue.component('taxratedropdown', () => import(/* webpackChunkName: "tax-rate-dropdown" */ './components/General/TaxRateDropdown.vue'));
Vue.component('brandmodel', () => import(/* webpackChunkName: "brand-model" */ './components/Brand/AddBrand.vue'));
Vue.component('originmodel', () => import(/* webpackChunkName: "origin-model" */ './components/Origin/AddOrigin.vue'));
Vue.component('unitmodel', () => import(/* webpackChunkName: "unit-model" */ './components/Unit/AddUnit.vue'));
Vue.component('addRoles', () => import(/* webpackChunkName: "add-roles" */ './components/UserRoles/AddRoles.vue'));
Vue.component('sizemodel', () => import(/* webpackChunkName: "size-model" */ './components/Size/AddSize.vue'));
Vue.component('colormodel', () => import(/* webpackChunkName: "color-model" */ './components/Color/AddColor.vue'));
Vue.component('addholidaysetup', () => import(/* webpackChunkName: "add-holiday-setup" */ './components/Hr/ShiftManagement/AddHolidaySetup.vue'));
Vue.component('taxratemodel', () => import(/* webpackChunkName: "tax-rate-model" */ './components/TaxRate/AddTaxRate.vue'));
Vue.component('currencymodel', () => import(/* webpackChunkName: "currency-model" */ './components/Currency/AddCurrency.vue'));
Vue.component('currency-dropdown', () => import(/* webpackChunkName: "currency-dropdown" */ './components/General/CurrencyDropdown.vue'));
Vue.component('PaymentVoucherItem', () => import(/* webpackChunkName: "payment-voucher-item" */ './components/PaymentVouchers/PaymentVoucherItem.vue'));
Vue.component('productfiltermodel', () => import(/* webpackChunkName: "product-filter-model" */ './components/Product/ProductFilterModel.vue'));
Vue.component('AddAddress', () => import(/* webpackChunkName: "add-address" */ './components/Customer/AddAddress.vue'));
Vue.component('invoicedetailsprint', () => import(/* webpackChunkName: "invoice-details-print" */ './components/SaleServiceInvoice/InvoiceDetailsPrint.vue'));



//Bank Model
Vue.component('bankmodel', () => import(/* webpackChunkName: "bank-model" */ './components/Bank/AddBank.vue'));
Vue.component('PaymentModel', () => import(/* webpackChunkName: "payment-model" */ './components/PurchaseBill/PaymentModel.vue'));

//
//User&UserRoles Dropdown_Modals
Vue.component('usersDropdown', () => import(/* webpackChunkName: "users-dropdown" */ './components/General/UsersDropdown.vue'));
Vue.component('acessdenied', () => import(/* webpackChunkName: "acess-denied" */ './components/General/AcessDenied.vue'));
Vue.component('userRolesDropdown', () => import(/* webpackChunkName: "user-roles-dropdown" */ './components/General/UserRolesDropdown.vue'));
Vue.component('quickemployeemodel', () => import(/* webpackChunkName: "quick-employee-model" */ './components/EmployeeRegistration/AddQuickEmployee.vue'));



Vue.component('modal', () => import(/* webpackChunkName: "modal" */ './components/modalcomponent.vue'));
Vue.component('datepicker', () => import(/* webpackChunkName: "datepicker" */ './components/DatePicker.vue'));
Vue.component('coa-modal', () => import(/* webpackChunkName: "coa-modal" */ './components/COA/chartOfAccountModal.vue'));
Vue.component('addupdate-coa', () => import(/* webpackChunkName: "add-update-coa" */ './components/COA/AddUpdateAccount.vue'));
Vue.component('paymentvoucherlinedetail', () => import(/* webpackChunkName: "payment-voucher-line-detail" */ './components/PaymentVouchers/AddPaymentVoucherDetails/AddPaymentVoucherDetail.vue'));


//Journal Voucher Items
Vue.component('jvitems', () => import(/* webpackChunkName: "jv-items" */ './components/JournalVouchers/AddJvLineItem/AddLineItem.vue'));
// Daily Expense Row
Vue.component('adddailyexpenserow', () => import(/* webpackChunkName: "add-daily-expense-row" */ './components/DailyExpense/AddDailyExpenseLineItem/AddLineItem.vue'));

// Mobile Order Items

// DropDown
Vue.component('perioddropdown', () => import(/* webpackChunkName: "period-dropdown" */ './components/General/PeriodDropdown.vue'));
Vue.component('monthlydropdown', () => import(/* webpackChunkName: "monthly-dropdown" */ './components/General/MonthlyDropdown.vue'));
Vue.component('productMasterdropdown', () => import(/* webpackChunkName: "product-master-dropdown" */ './components/General/ProductMasterDropdown.vue'));
Vue.component('accountdropdown', () => import(/* webpackChunkName: "account-dropdown" */ './components/General/AccountNumberDropdown.vue'));
Vue.component('currencyinput', () => import(/* webpackChunkName: "currency-input" */ './components/General/InputDropdown.vue'));
Vue.component('categorydropdown', () => import(/* webpackChunkName: "category-dropdown" */ './components/General/CategoryDropdown.vue'));
Vue.component('supplierdropdown', () => import(/* webpackChunkName: "supplier-dropdown" */ './components/General/SupplierDropdown.vue'));
Vue.component('customerdropdown', () => import(/* webpackChunkName: "customer-dropdown" */ './components/General/CustomerDropdown.vue'));
Vue.component('add-purchase', () => import(/* webpackChunkName: "add-purchase" */ './components/Purchase/AddPurchase.vue'));
Vue.component('purchase-item', () => import(/* webpackChunkName: "purchase-item" */ './components/Purchase/PurchaseItem.vue'));
Vue.component('poItem', () => import(/* webpackChunkName: "po-item" */ './components/PurchaseOrder/PurchaseItem.vue'));
Vue.component('product-dropdown', () => import(/* webpackChunkName: "product-dropdown" */ './components/General/ProductDropdown.vue'));
Vue.component('vendor-dropdown', () => import(/* webpackChunkName: "product-dropdown" */ './components/General/VendorDropdown.vue'));
Vue.component('contractor-view', () => import(/* webpackChunkName: "product-dropdown" */ './components/Color/ContractorView.vue'));
Vue.component('bulk-attachment', () => import(/* webpackChunkName: "product-dropdown" */ './components/Attachment/ImportAttachment.vue'));
Vue.component('addsignupmodel', () => import(/* webpackChunkName: "product-dropdown" */ './components/LoginCredentials/AddSignUpmodel.vue'));
Vue.component('attachment-view', () => import(/* webpackChunkName: "product-dropdown" */ './components/Attachment/AttachmentView.vue'));
Vue.component('stock-report-print', () => import(/* webpackChunkName: "product-dropdown" */ './components/Reports/StockReportPrint.vue'));
Vue.component('payment-refund-model', () => import(/* webpackChunkName: "product-dropdown" */ './components/PaymentVouchers/PaymentRefundModel.vue'));
Vue.component('DesignForAuthority', () => import(/* webpackChunkName: "product-dropdown" */ './components/Department/DesignForAuthority.vue'));


Vue.component('warehouse-dropdown', () => import(/* webpackChunkName: "warehouse-dropdown" */ './components/General/WarehouseDropdown.vue'));
Vue.component('employeeDropdown', () => import(/* webpackChunkName: "employee-dropdown" */ './components/General/EmployeeDropdown.vue'));
Vue.component('purchaseinvoicedropdown', () => import(/* webpackChunkName: "purchase-invoice-dropdown" */ './components/General/PurchaseInvoiceDropDown.vue'));
Vue.component('productMultiSelectDropdown', () => import(/* webpackChunkName: "product-multi-select-dropdown" */ './components/General/ProductMultiSelectDropdown.vue'));
Vue.component('company-dropdown', () => import(/* webpackChunkName: "company-dropdown" */ './components/General/CompanyDropdown.vue'));
Vue.component('my-currency-input', () => import(/* webpackChunkName: "my-currency-input" */ './components/General/DecimalDropdown.vue'));
Vue.component('decimal-to-fixed', () => import(/* webpackChunkName: "decimal-to-fixed" */ './components/General/DecimalToFixed.vue'));

//Sale Service
Vue.component('sale-service-item', () => import(/* webpackChunkName: "sale-service-item" */ './components/SaleServiceInvoice/SaleServiceItem.vue'));
Vue.component('sale-item', () => import(/* webpackChunkName: "sale-service-item" */ './components/SaleServiceInvoice/SaleItem.vue'));
Vue.component('credit-payment', () => import(/* webpackChunkName: "credit-payment" */ './components/SaleServiceInvoice/CreditPayment.vue'));





//Dashboard
Vue.component('dashboard', () => import(/* webpackChunkName: "dashboard" */ './components/Dashboard/Dashboard.vue'));
Vue.component('forgotPassword', () => import(/* webpackChunkName: "forgotPassword" */ './views/ForgotPassword.vue'));
Vue.component('CompanyInfoImage', () => import(/* webpackChunkName: "CompanyInfoImage" */ './components/CompanyInfoImage.vue'));
Vue.component('AddProductImage', () => import(/* webpackChunkName: "AddProductImage" */ './components/AddProductImage.vue'));
Vue.component('productimage', () => import(/* webpackChunkName: "productimage" */ './components/productImageList.vue'));



Vue.component('uploadImage', () => import(/* webpackChunkName: "uploadImage" */ './components/UploadImage.vue'));
// Purchase Order Dropdown


Vue.component('purchase-order-dropdown', () => import(/* webpackChunkName: "purchase-order-dropdown" */ './components/General/PurchaseOrderDropdown.vue'));
Vue.component('product-single-dropdown', () => import(/* webpackChunkName: "product-single-dropdown" */ './components/General/ProductSingleDropdown.vue'));

//Area Dropdowb
Vue.component('passengerdropdown', () => import(/* webpackChunkName: "areadropdown" */ './components/General/PassengerDropdown.vue'));
Vue.component('areadropdown', () => import(/* webpackChunkName: "areadropdown" */ './components/General/AreaDropdown.vue'));
Vue.component('bankdropdown', () => import(/* webpackChunkName: "bankdropdown" */ './components/General/BankDropdown.vue'));

Vue.component('saleorderdropdown', () => import(/* webpackChunkName: "saleorderdropdown" */ './components/General/SaleOrderDropdown.vue'));
Vue.component('quotationdropdown', () => import(/* webpackChunkName: "quotationdropdown" */ './components/General/QuotationDropdown.vue'));
Vue.component('roledropdown', () => import(/* webpackChunkName: "roledropdown" */ './components/General/RolesDropdown.vue'));



Vue.component('Backup', () => import(/* webpackChunkName: "Backup" */ './components/DatabaseBackup/Backup.vue'));
Vue.component('Restore', () => import(/* webpackChunkName: "Restore" */ './components/Restore/Restore.vue'));

//Hr
Vue.component('add-deduction', () => import(/* webpackChunkName: "add-deduction" */ './components/Hr/Payroll/Deduction/AddDeduction.vue'));
Vue.component('add-shift', () => import(/* webpackChunkName: "add-shift" */ './components/Hr/Shift/AddShift.vue'));
Vue.component('shift-dropdown', () => import(/* webpackChunkName: "shift-dropdown" */ './components/General/ShiftDropdown.vue'));
Vue.component('add-contribution', () => import(/* webpackChunkName: "add-contribution" */ './components/Hr/Payroll/Contribution/AddContribution.vue'));



//PayRoll
Vue.component('add-payroll-schedule', () => import(/* webpackChunkName: "add-payroll-schedule" */ './components/Hr/Payroll/PayrollSchedule/AddPayrollSchedule.vue'));
Vue.component('addAllowance', () => import(/* webpackChunkName: "addAllowance" */ './components/Hr/Payroll/Allowance/AddAllowance.vue'));
Vue.component('AddAllowanceType', () => import(/* webpackChunkName: "AddAllowanceType" */ './components/Hr/Payroll/AllowanceType/AddAllowanceType.vue'));
Vue.component('allowanceTypeDropdown', () => import(/* webpackChunkName: "allowanceTypeDropdown" */ './components/General/AllowanceTypeDropdown.vue'));
Vue.component('allowanceDropdown', () => import(/* webpackChunkName: "allowanceDropdown" */ './components/General/AllowanceDropdown.vue'));
Vue.component('deductionDropdown', () => import(/* webpackChunkName: "deductionDropdown" */ './components/General/DeductionDropdown.vue'));
Vue.component('addDeduction', () => import(/* webpackChunkName: "addDeduction" */ './components/Hr/Payroll/Deduction/AddDeduction.vue'));
Vue.component('contributionDropdown', () => import(/* webpackChunkName: "contributionDropdown" */ './components/General/ContributionDropdown.vue'));
Vue.component('salary-template-dropdown', () => import(/* webpackChunkName: "salary-template-dropdown" */ './components/General/SalaryTemplateDropdown.vue'));
Vue.component('payroll-schedule-dropdown', () => import(/* webpackChunkName: "payroll-schedule-dropdown" */ './components/General/PayrollScheduleDropdown.vue'));
Vue.component('productMastermodel', () => import(/* webpackChunkName: "productMastermodel" */ './components/ProductMaster/AddProductMaster.vue'));


Vue.component('departmentModel', () => import(/* webpackChunkName: "departmentModel" */ './components/Hr/Employee/Department/AddDepartment.vue'));
Vue.component('designationModel', () => import(/* webpackChunkName: "designationModel" */ './components/Hr/Employee/Designation/AddDesignation.vue'));
Vue.component('designationDropdown', () => import(/* webpackChunkName: "designationDropdown" */ './components/General/DesignationDropdown.vue'));
Vue.component('designation-multi-dropdown', () => import(/* webpackChunkName: "designation-multi-dropdown" */ './components/General/DesignationMultiDropdown.vue'));
Vue.component('department-multi-dropdown', () => import(/* webpackChunkName: "department-multi-dropdown" */ './components/General/DepartmentMultiDropdown.vue'));

Vue.component('departmentDropdown', () => import(/* webpackChunkName: "departmentDropdown" */ './components/General/DepartmentDropdown.vue'));
Vue.component('loanDetailModel', () => import(/* webpackChunkName: "loanDetailModel" */ './components/Hr/Payroll/LoanPayment/LoanDetailModel.vue'));

Vue.component('loanRecovery', () => import(/* webpackChunkName: "loanRecovery" */ './components/Hr/Payroll/LoanPayment/LoanRecoveryModel.vue'));
Vue.component('salaryTemplateModel', () => import(/* webpackChunkName: "salaryTemplateModel" */ './components/Hr/Payroll/RunPayroll/SalaryTemplateModel.vue'));

Vue.component('run-payroll-model', () => import(/* webpackChunkName: "run-payroll-model" */ './components/Hr/Payroll/RunPayroll/RunPayrollView.vue'));
Vue.component('invoice-report-print', () => import(/* webpackChunkName: "run-payroll-model" */ './components/Reports/SaleInvoiceReport.vue'));
Vue.component('purchaseinvoicereport', () => import(/* webpackChunkName: "run-payroll-model" */ './components/Reports/PurchaseInvoiceReport.vue'));
Vue.component('InvoiceReportPrint', () => import(/* webpackChunkName: "run-payroll-model" */ './components/Reports/InvoiceReportPrint.vue'));
Vue.component('SalePurchasePrint', () => import(/* webpackChunkName: "run-payroll-model" */ './components/Reports/SalePurchasePrintReport.vue'));
Vue.component('VatExpenseReportPrint', () => import(/* webpackChunkName: "run-payroll-model" */ './components/Reports/VatExpenseReportPrint.vue'));
Vue.component('customerLedgerPrintReport', () => import(/* webpackChunkName: "run-payroll-model" */ './components//Reports/CustomerLedgerReport/CustomerLedgerPrintReport.vue'));
Vue.component('CustomerLedgerPdf', () => import(/* webpackChunkName: "run-payroll-model" */ './components//Reports/CustomerLedgerReport/CustomerLedgerDownloadReport.vue'));
Vue.component('journaVoucherPrintReport', () => import(/* webpackChunkName: "run-payroll-model" */ './components/Reports/journaVoucherPrintReport.vue'));
Vue.component('paymentVoucherPrintReport', () => import(/* webpackChunkName: "run-payroll-model" */ './components/Reports/paymentVoucherPrintReport.vue'));
Vue.component('PaymentVoucherPrint', () => import(/* webpackChunkName: "run-payroll-model" */ './components/Reports/PaymentVoucherPrint.vue'));




Vue.prototype.$https = axios;
axios.defaults.baseURL = json.ServerIP;
Vue.prototype.$ServerIp = json.ServerIP;
Vue.prototype.$PermissionIp = json.PermissionIP;
Vue.prototype.$ClientIP = json.ClientIP;
Vue.prototype.$ReportServer = json.ReportServer;
Vue.prototype.$macAddess = 'D8-FC-93-2E-5B-94';
Vue.prototype.$SystemType = json.SystemType;

router.beforeEach((to, from, next) => { // <------------
    var getLocale = localStorage.getItem('locales');
    let language = getLocale;
    if (!language) {
        language = 'en'
    }
    i18n.locale = language
    next()
});


Vue.filter('englishLanguage',
    function (value) {

        var getEnglishLanguage = 'true';
        var getArabicLanguage = localStorage.getItem('Arabic');
        var getPortuguesLanguage = localStorage.getItem('Portugues');
        if (getEnglishLanguage == 'true' && getArabicLanguage == 'false' && getPortuguesLanguage == 'false') {
            return value;
        }
        else if (getEnglishLanguage == 'false' && getArabicLanguage == 'true' && getPortuguesLanguage == 'false') {
            return value;
        }
        else if (getEnglishLanguage == 'false' && getArabicLanguage == 'false' && getPortuguesLanguage == 'true') {
            return value;
        }
       
        else {
            var lan = localStorage.getItem('locales');

            if (lan == 'en') {
                return value + ' (English)';
            }
            else if (lan == 'pt') {
                return value + ' (Inglês)';
            }
            else {
                return value + ' (انجليزي)';
            }

        }

    });

Vue.filter('arabicLanguage',
    function (value) {
        var getEnglishLanguage = 'true';
        var getArabicLanguage = localStorage.getItem('Arabic');
        var getPortuguesLanguage = localStorage.getItem('Portugues');
        if (getEnglishLanguage == 'true' && getArabicLanguage == 'false' && getPortuguesLanguage == 'false') {
            return value;
        }
        else if (getEnglishLanguage == 'false' && getArabicLanguage == 'true' && getPortuguesLanguage == 'false') {
            return value;
        }
        else if (getEnglishLanguage == 'false' && getArabicLanguage == 'false' && getPortuguesLanguage == 'true') {
            return value;
        }
        else {
            var lan = localStorage.getItem('locales');
            if (lan == 'en' && getArabicLanguage == 'false' && getPortuguesLanguage == 'true') {
                return value + ' (Portugues)';
            }
            else if (lan == 'en' && getArabicLanguage == 'true' && getPortuguesLanguage == 'false') {
                return value + ' (Arabic)';
            }
            else if (lan == 'pt') {
                return value + ' (Portugues)';
            }
            else {
                return value + ' (عربي)';
            }

        }
    });

Vue.filter('formatAmount', function (value) {
    if (!value) return '';

    return parseFloat(value).toFixed(3).slice(0, -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
})

Vue.filter('roundAmount', function (value) {
    if (!value) return 0;

    return parseFloat(value).toFixed(3).slice(0, -1);
})

new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')
