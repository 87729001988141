<template>
    <div>
        <div class="col-md-12  mt-4">
            <div class="card">
                <div class="card-header DayHeading ">
                    <span style="font-size:30px;font-weight:bold">Add Contract </span>
                    <a v-on:click="PrintView" href="javascript:void(0);" class="btn btn-sm btn-outline-primary mx-1">
                        View
                    </a>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-6">
                            <label>Main Heading: </label>

                            <input placeholder="" v-model="contractor.mainHeading" class="form-control" type="text">
                        </div>
                        <div class="col-md-6">
                            <label>Prepared For: </label>

                            <multiselect v-model="contractor.preparedFor" :preselect-first="false"
                                :options="['Customer', 'Supplier', 'Vendor', 'Sub Agent']" :show-labels="false"
                                :placeholder="$t('AddCustomer.SelectType')">
                            </multiselect>
                        </div>
                        <div class="col-md-6">
                            <label>Customer: </label>

                            <multiselect :options="options" @input="GetRecord" :multiple="false" track-by="name"
                                ref="multiselect" :searchable="true" :clear-on-select="false" :show-labels="false"
                                label="name" :preselect-first="true"
                                v-bind:placeholder="$t('CustomerDropdown.SelectOption')">
                            </multiselect>
                        </div>
                        <div class="col-md-6">
                        </div>

                        <div class="col-md-10">
                            <label>Main Paragraph: </label>
                            <VueEditor ref="editor" @drop="onDropToEditor" v-model="contractor.mainParagrah" />
                        </div>
                        <div class="col-md-2">
                            <label>Record: </label>
                            <div class="card">
                                <div class="card-body">
                                    <template v-for="(record, index) in optionsList">
                                        <div v-if="record && record.trim() !== ''" class="badge badge-soft-primary mt-1"
                                            :key="index" draggable="true" @dragstart="onDragStart(record, $event)">
                                            {{ record }}
                                        </div>
                                    </template>
                                </div>

                            </div>

                        </div>
                       

                    </div>



                </div>
            </div>
        </div>
        <contractor-view :contractor="contractor" :show="show" v-if="show" @close="show = false"></contractor-view>

    </div>
</template>
<script>
import clickMixin from '@/Mixins/clickMixin'
import { required, maxLength } from 'vuelidate/lib/validators';
import Multiselect from 'vue-multiselect'
import { VueEditor } from "vue2-editor";

export default ({

    mixins: [clickMixin],
    components: {
        Multiselect,
        VueEditor,


    },
    data: function () {
        return {
            
            customer: '',
            optionsList: ['englishName','registrationDate','contactNo1',
            'telephone','email','isActive','vatNo','commercialRegistrationNo',
            'paymentTerms','billingAddress','billingAttention','billingCountry',
            'billingCity','billingArea','billingZipCode',
            'billingPhone','billingFax','shippingAttention','shippingCountry','shippingAddress',
            'shippingCity','shippingArea','shippingZipCode','shippingPhone','shippingFax'],
            options: [],
            contractor: {
                id: '00000000-0000-0000-0000-000000000000',
                mainHeading: '',
                mainParagrah: '',

            },
            signaturePadOptions: {
                width: 400,
                height: 200,
                // Other options for signature pad configuration
            },
            signature: null,
            path: '',
            renderedImage: 0,
            show: false,
            isDelete: false

        }
    },
    validations: {
        contractor:
        {
            nameInEnglish: {
                required,
                maxLength: maxLength(50)
            },


        }
    },
    methods: {


        SaveColor: function () {
                var root = this;
                this.loading = true;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                this.$https.post('/Product/SaveColor', this.color, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data.isSuccess == true) {

                            if (root.type != "Edit") {

                                root.$swal({
                                    title: root.$t('AddColors.SavedSuccessfully'),
                                    text: root.$t('AddColors.Saved'),
                                    type: 'success',
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 1500,
                                    timerProgressBar: true,
                                });

                                root.close();
                            }
                            else {

                                root.$swal({
                                    title: root.$t('AddColors.SavedSuccessfully'),
                                    text: root.$t('AddColors.UpdateSucessfully'),
                                    type: 'success',
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 1500,
                                    timerProgressBar: true,
                                });
                                root.close();
                            }
                        }
                        else {
                            root.$swal({
                                title: root.$t('AddColors.Error'),
                                text: root.$t('AddColors.YourColorNameAlreadyExist'),
                                type: 'error',
                                icon: 'error',
                                showConfirmButton: false,
                                timer: 1500,
                                timerProgressBar: true,
                            });
                        }
                    }).catch(error => {
                        console.log(error)
                        root.$swal.fire(
                            {
                                icon: 'error',
                                title: this.$t('AddColors.SomethingWrong'),
                                text: error.response.data,

                                showConfirmButton: false,
                                timer: 5000,
                                timerProgressBar: true,
                            });

                        root.loading = false
                    })
                    .finally(() => root.loading = false)
            },

        // Function triggered when a record is dropped into the text area
        onDrop(event) {
            
            alert("Drop")


            event.preventDefault();
            const data = event.dataTransfer.getData('text/plain');
            this.contractor.mainParagrah += `${data}\n`;
            event.dataTransfer.clearData(); // Clear the data after drop
        },

        // Function triggered when a record is dropped onto the VueEditor
        onDragStart(record, event) {
            event.dataTransfer.setData('text/plain', record);
            event.dataTransfer.setData('text/identifier', record.id); // Use a unique identifier if available

            // Clear the previous selection in the VueEditor
            const editor = this.$refs.editor;
            editor.quill.blur(); // Remove focus to clear the selection
        },

        // Function triggered when a record is dropped onto the VueEditor
        onDropToEditor(event) {
            
            event.preventDefault();
            const data = event.dataTransfer.getData('text/plain');
            const identifier = event.dataTransfer.getData('text/identifier'); // Retrieve the identifier
            const editor = this.$refs.editor;
            if (identifier) {
                // Insert the dropped content into the VueEditor based on the identifier
                // Adjust this logic based on how your data is structured
                editor.quill.insertText(editor.quill.getSelection().index, `${data}\n`);
            }
            event.dataTransfer.clearData(); // Clear the data after drop
        },
        GetRecord: function (obj) {
            

            this.customer = obj;
        },
        getImage: function (value) {
            this.contractor.logoPath = value;
            this.isDelete = true;
        },


        PrintView: function () {
            this.show = true;

        },

        getData: function () {


            var root = this;
            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }




            this.$https.get('/Contact/ContactList?IsDropDown=' + true + '&isCustomer=' + true + '&isActive=' + true + '&paymentTerms=' + '' + '&searchTerm=' + '' + '&isCashCustomer=' + false + '&multipleAddress=' + false, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            }).then(function (response) {
                if (response.data != null) {
                    response.data.results.forEach(function (cust) {
                        if (cust.contactNo1 == null)
                            cust.contactNo1 = '';
                        if (cust.customerCode == null)
                            cust.customerCode = '';




                        root.options.push({
                            name: cust.englishName,
                            commercialRegistrationNo: cust.commercialRegistrationNo,
                            vatNo: cust.vatNo,
                            contactNo1: cust.contactNo1,
                            email: cust.email,
                            address: cust.address,
                            registrationDate: cust.registrationDate,
                        });

                    });


                }
            }).then(function () {

                root.value = root.options.find(function (x) {
                    return x.id == root.values;
                })

            });
        },



    },
    mounted: function () {

        this.getData();
    }
})

</script>