<template>

    <div class="row">
        <div class="col-lg-12">
            <div class="row">
                <div class="col-lg-12">
                    <div class="page-title-box">
                        <div class="row">
                            <div class="col-6">
                                <h4 class="page-title">Case </h4>

                            </div>
                            <div class="col-6 text-end">
                                                        <button class="btn btn-outline-primary  m-2"
                                                            :disabled="caseDetail.caseStatus === 5 || caseDetail.caseStatus === 6"
                                                            v-on:click="SaveDiscussionBoard">
                                                            Save 
                                                        </button>
                                                        
                                                    </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-body">

                    <div class="row">

                        <div class="row">
                            <label class="col-form-label text-end col-lg-2">
                                Case Code:
                            </label>
                            <div class="inline-fields text-start col-lg-4 mt-2">
                                #{{ caseDetail.caseCode }}


                            </div>
                            <label class="col-form-label text-end col-lg-2">
                                Payment Status:
                            </label>
                            <div class="inline-fields text-Start col-lg-4 mt-2">




                            </div>
                        </div>
                        <div class="row">
                            <label class="col-form-label text-end col-lg-2">
                                Client Name:
                            </label>
                            <div class="inline-fields text-start col-lg-4 mt-2">
                                {{ caseDetail.customerName }}


                            </div>
                            <div class="inline-fields text-center col-lg-6 mt-2">
                                <div class="col-lg-12 text-end">
                                    <button class="btn btn-outline-primary  m-2" data-bs-toggle="offcanvas"
                                        data-bs-target="#offcanvasRight2" aria-controls="offcanvasRight">
                                        Multiple Lawyer
                                    </button>
                                    
                                </div>
                                <div>
                                    <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight2"
                                        aria-labelledby="offcanvasRightLabel" style="width:600px !important">
                                        <div class="offcanvas-header">
                                            <h5 id="offcanvasRightLabel" class="m-0">Multiple Lawyer</h5>
                                            <!-- <button v-bind:style="($i18n.locale == 'en' || isLeftToRight()) ? '' : 'margin-left:0px !important'" type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button> -->
                                            <!-- <button v-bind:style="($i18n.locale == 'en' || isLeftToRight()) ? 'margin-left:412px !important' : 'margin-left:0px !important'" type="button" class="btn btn-outline-primary" v-on:click="UpdateDetail">{{ $t('AddSale.Update') }}</button> -->
                                            <button
                                                v-bind:style="($i18n.locale == 'en' || isLeftToRight()) ? '' : 'margin-left:0px !important'"
                                                type="button" class="btn-close text-reset filter-green "
                                                data-bs-dismiss="offcanvas" aria-label="Close"></button>
                                        </div>
                                        <div class="offcanvas-body">
                                            <div class="row">
                                                <div class="row text-start">
                                                    
                                                    <div class="col-lg-6 form-group"
                                                        >
                                                        <label>
                                                            <span
                                                                class="tooltip-container text-dashed-underline "> Multiple Lawyer
                                                                :</span>
                                                        </label>
                                                        <employeeDropdown v-model="caseDetail.externalEmployeeId" v-on:input="AddContactEmpoyeeProduct"
                                                             :values="caseDetail.externalEmployeeId"
                                                            :key="randerWorkProcess" />
                                                    </div>
                                                   
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="table-responsive">
                                                    <table class="table mb-0">
                                                        <thead class="thead-light table-hover">
                                                            <tr>
                                                                <th>#</th>
                                                                <th>
                                                                    Employee Name
                                                                </th>
                                                                <th>
                                                                    Invoice No
                                                                </th>
                                                                <th>
                                                                    Action
                                                                </th>
                                                                
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="(emp, index) in caseDetail.contactEmployeeProductModels" v-bind:key="index">
                                                                <td >
                                                                    {{ index + 1 }}
                                                                </td>
                                                                
                                                                <td>
                                                                    {{ emp.name}}
                                                                </td>
                                                                <td>
                                                                {{ emp.invoiceNo }}
                                                                </td>
                                                            
                                                                <td class="text-end">
                                                                    <button type="button" class="btn btn-light " >Make invoice</button>
                                                                    <!-- <button type="button" class="btn btn-danger ms-2">Remove</button>
                                                                     -->

                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="offcanvas-footer " >
                                            <div class="row">
                                                <div class="row text-end">
                                                    
                                                    
                                                    <div class="col-lg-12 form-group"
                                                        >
                                                        <button type="button" style="width: 150px; margin: 8px 8px 16px 0;" class="btn btn-primary" v-on:click="SaveExternalLayer">Save</button>
                                                    </div>
                                                   
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                        <div class="row ">
                            <label class="col-form-label text-end col-lg-2">
                                Case Type:
                            </label>
                            <div class="inline-fields text-start col-lg-4 mt-2">
                                {{ caseDetail.caseName }}


                            </div>
                            <label class="col-form-label text-end col-lg-2">
                                Case Worker:
                            </label>
                            <div class="inline-fields text-Start col-lg-4">

                                <employeeDropdown v-model="caseDetail.employeeId" v-bind:values="caseDetail.employeeId"
                                    :key="randerWorkProcess"></employeeDropdown>


                            </div>
                        </div>

                        <div class="row ">
                            <div class="col-lg-12">
                                <ul class="nav nav-tabs" role="tablist">
                                    <li class="nav-item">
                                        <a class="nav-link " v-bind:class="{ active: active == 'DiscussionBoard' }"
                                            v-on:click="makeActive('DiscussionBoard')" data-bs-toggle="tab" href="#home"
                                            role="tab" aria-selected="true">Discussion Board</a>
                                    </li>

                                    <li class="nav-item">
                                        <a class="nav-link" data-bs-toggle="tab" href="#Remarks" role="tab"
                                            v-bind:class="{ active: active == 'PreviousDiscussion' }"
                                            v-on:click="makeActive('PreviousDiscussion')" aria-selected="false">Previous
                                            Discussion</a>
                                    </li>
                                </ul>
                                <div class="tab-content">
                                    <div class="tab-pane p-3 " v-bind:class="{ active: active == 'DiscussionBoard' }"
                                        id="home" role="tabpanel">

                                        <div class="row">
                                            <div class="col-lg-8 mt-2">

                                                <VueEditor v-model="caseDetail.newDiscussionBoards.dicussion" />
                                                <div class="row">

                                                    <div class="col-lg-12 text-end">
                                                        <button class="btn btn-outline-primary  m-2"
                                                            :disabled="caseDetail.caseStatus === 5 || caseDetail.caseStatus === 6"
                                                            v-on:click="SaveDiscussionBoard">
                                                            Save Discussion
                                                        </button>
                                                        <button class="btn btn-outline-primary   m-2"
                                                            :disabled="caseDetail.caseStatus === 5 || caseDetail.caseStatus === 6">
                                                            Print
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 mt-2">
                                                <div class="row form-group">
                                                    <label class="col-form-label col-lg-4">
                                                        <span id="ember695"
                                                            class="tooltip-container text-dashed-underline "> Date Time:
                                                            <span class="text-danger"> *</span></span>
                                                    </label>
                                                    <div class="inline-fields col-lg-8">
                                                        <datepicker v-bind:key="randerWorkProcess"
                                                            v-model="caseDetail.newDiscussionBoards.dateTime">
                                                        </datepicker>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>




                                    </div>
                                    <div class="tab-pane p-3" v-bind:class="{ active: active == 'PreviousDiscussion' }"
                                        id="Remarks" role="tabpanel">
                                        <div class="row">
                                            <div class="table-responsive">
                                                <table class="table mb-0">
                                                    <thead class="thead-light table-hover">
                                                        <tr>
                                                            <th width="5%">#</th>

                                                            <th width="15%" class="text-start">
                                                                Date
                                                            </th>
                                                            <th width="65%" class="text-start">
                                                                Previous Discussion
                                                            </th>
                                                            <th width="15%" class="text-start">

                                                            </th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(prevDiscussion, index) in caseDetail.discussionBoardModels"
                                                            v-bind:key="prevDiscussion.id">
                                                            <td width="5%">
                                                                {{ index + 1 }}
                                                            </td>
                                                            <td width="15%" class="text-start">
                                                                <strong>
                                                                    {{ getFormattedDate(prevDiscussion.dateTime) }}
                                                                </strong>
                                                            </td>

                                                            <td width="65%" class="text-start">

                                                                <div class="truncate-text"
                                                                    v-html="prevDiscussion.dicussion">

                                                                </div>
                                                            </td>
                                                            <td width="15%">
                                                                <button class="btn btn-sm btn-outline-primary"
                                                                    v-on:click="showPreviousDiscussion(prevDiscussion)">
                                                                    View Detail
                                                                </button>
                                                            </td>

                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="tab-content tab-space tab-subcategories">

                                </div>

                            </div>
                        </div>

                        <div class="row">
                            <div class="col-lg-8">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <button class="btn btn-outline-primary  m-2"
                                                    v-on:click="AddWorkerProcesse"
                                                    :disabled="caseDetail.caseStatus === 5 || caseDetail.caseStatus === 6"
                                                    style="width: 100%;">
                                                    Add Work Process
                                                </button>

                                            </div>
                                            <div class="col-lg-6">
                                                <button class="btn btn-outline-success  m-2"
                                                    v-on:click="saveWorkerProcessList()"
                                                    :disabled="caseDetail.caseStatus === 5 || caseDetail.caseStatus === 6"
                                                    style="width: 100%;">
                                                    Save Worker Process
                                                </button>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-4">
                                        <div class="card">
                                            <div class="card-header text-center">
                                                <h5 style="margin: 0;">
                                                    To Do List
                                                </h5>
                                            </div>
                                            <draggable class="col-sm-12 border p-2" v-model="todoList" data-status="2"
                                                :disabled="caseDetail.caseStatus === 5 || caseDetail.caseStatus === 6"
                                                @end="onDragEnd($event, 2)" style="margin: unset;">

                                                <template class="row align-items-baseline p-2" v-for="item in todoList">
                                                    <div :key="item.id"
                                                        class="alert alert-primary d-flex justify-content-between align-items-center"
                                                        v-if="item.workerProcessStatus == 1 && !item.isActive"
                                                        :data-id="item.id">
                                                        <span v-if="!item.isDelete">{{ item.name }}</span>
                                                        <input v-else v-model="item.name" class="form-control"
                                                            style="background-color: transparent !important;"
                                                            @keyup.enter="saveEdit(item)" @blur="saveEdit(item)" />
                                                        <div>
                                                            <button class="btn btn-sm btn-soft-success btn-circle me-2"
                                                                @click="editItem(item)"><i
                                                                    class="dripicons-pencil"></i></button>
                                                            <button class="btn  btn-danger btn-round btn-sm btn-icon"
                                                                style="font-size: 0.6rem;position:absolute !important;right: -8px !important;top: -7px !important;"
                                                                @click="deleteItem(item)"><i data-v-52364bdd=""
                                                                    class="fas fa-times"></i></button>
                                                        </div>
                                                    </div>
                                                </template>
                                            </draggable>



                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="card">
                                            <div class="card-header text-center">
                                                <h5 style="margin: 0;">
                                                    Processing
                                                </h5>
                                            </div>
                                            <draggable class="col-sm-12 border p-2" v-model="todoList" data-status="2"
                                                :disabled="caseDetail.caseStatus === 5 || caseDetail.caseStatus === 6"
                                                @end="onDragEnd($event, 3)" style="margin: unset;">

                                                <template class="row align-items-baseline p-2" v-for="item in todoList">
                                                    <div :key="item.id"
                                                        class="alert alert-light d-flex justify-content-between align-items-center"
                                                        v-if="item.workerProcessStatus == 2 && !item.isActive"
                                                        :data-id="item.id">
                                                        <span v-if="!item.isDelete">{{ item.name }}</span>
                                                        <input v-else v-model="item.name" class="form-control"
                                                            style="background-color: transparent !important;"
                                                            @keyup.enter="saveEdit(item)" @blur="saveEdit(item)" />
                                                        <div>
                                                            <button class="btn btn-sm btn-soft-success btn-circle me-2"
                                                                @click="editItem(item)"><i
                                                                    class="dripicons-pencil"></i></button>
                                                            <button class="btn  btn-danger btn-round btn-sm btn-icon"
                                                                style="font-size: 0.6rem;position:absolute !important;right: -8px !important;top: -7px !important;"
                                                                @click="deleteItem(item)"><i data-v-52364bdd=""
                                                                    class="fas fa-times"></i></button>
                                                        </div>
                                                    </div>
                                                </template>
                                            </draggable>

                                        </div>

                                    </div>
                                    <div class="col-4">
                                        <div class="card">
                                            <div class="card-header text-center">
                                                <h5 style="margin: 0;">
                                                    Completed
                                                </h5>
                                            </div>

                                            <draggable class="col-sm-12 border p-2" v-model="todoList" data-status="2"
                                                :disabled="caseDetail.caseStatus === 5 || caseDetail.caseStatus === 6"
                                                @end="onDragEnd($event, 1)" style="margin: unset;">

                                                <template class="row align-items-baseline p-2" v-for="item in todoList">
                                                    <div :key="item.id"
                                                        class="alert alert-success d-flex justify-content-between align-items-center"
                                                        v-if="item.workerProcessStatus == 3 && !item.isActive"
                                                        :data-id="item.id">
                                                        <span v-if="!item.isDelete">{{ item.name }}</span>
                                                        <input v-else v-model="item.name" class="form-control"
                                                            style="background-color: transparent !important;"
                                                            @keyup.enter="saveEdit(item)" @blur="saveEdit(item)" />
                                                        <div>
                                                            <button class="btn btn-sm btn-soft-success btn-circle me-2"
                                                                @click="editItem(item)"><i
                                                                    class="dripicons-pencil"></i></button>
                                                            <button class="btn  btn-danger btn-round btn-sm btn-icon"
                                                                style="font-size: 0.6rem;position:absolute !important;right: -8px !important;top: -7px !important;"
                                                                @click="deleteItem(item)"><i data-v-52364bdd=""
                                                                    class="fas fa-times"></i></button>
                                                        </div>
                                                    </div>
                                                </template>
                                            </draggable>

                                        </div>

                                    </div>
                                    <!-- <draggable class="row" v-model="resultQuery" style="  margin: unset;">
                                        <div style="display: flex; padding: 12px 7px !important; margin: unset; border-bottom: 1px solid #e9f5f9; " v-for="work in resultQuery" v-bind:key="work.id">
                                            
                                        </div>
                                    </draggable> -->

                                </div>
                            </div>
                            <div class="col-lg-4">
                                <button class="btn btn-outline-primary"
                                    :disabled="caseDetail.caseStatus === 5 || caseDetail.caseStatus === 6"
                                    v-on:click="showAttachment = true" style="width: 100%; margin: 9px 8px 8px 8px;">
                                    Add/Download/View Attachment
                                </button>
                                <button class="btn btn-outline-primary m-2" v-on:click="DownloadZipFile"
                                    style="width: 100%; ">
                                    Download Attachment
                                </button>

                                <button class="btn btn-outline-success  m-2" v-on:click="updateStatus(5)"
                                    :disabled="caseDetail.caseStatus === 5 || caseDetail.caseStatus === 6"
                                    style="width: 100%;">
                                    Completed
                                </button>
                                <button class="btn btn-outline-danger  m-2" v-on:click="updateStatus(6)"
                                    :disabled="caseDetail.caseStatus === 5 || caseDetail.caseStatus === 6"
                                    style="width: 100%;">
                                    Rejected
                                </button>
                            </div>
                        </div>



                    </div>



                </div>


            </div>
            <bulk-attachment :fileNameZip="fileNameZip" :attachmentList="caseDetail.attachmentList"
                :documentid="caseDetail.id" :show="showAttachment" v-if="showAttachment"
                @close="showAttachment = false" />

        </div>

        <modal :show="show" v-if="show">

            <div style="margin-bottom:0px" class="card">
                <div class="card-body">
                    <div class="col-lg-12">
                        <div class="tab-content" id="nav-tabContent">
                            <div class="modal-header">

                                <h5 class="modal-title" id="myModalLabel"> New Work Process</h5>

                            </div>
                            <div class="text-left">
                                <div class="card-body">
                                    <div class="row">

                                        <div class="form-group has-label col-sm-12 "
                                            v-bind:class="{ 'has-danger': $v.newWorkerProcesse.name.$error }">
                                            <label class="text  font-weight-bolder">
                                                Name: <span class="text-danger"> *</span>
                                            </label>
                                            <input class="form-control" v-model="$v.newWorkerProcesse.name.$model"
                                                type="text" />
                                            <span v-if="$v.newWorkerProcesse.name.$error" class="error">
                                                <span v-if="!$v.newWorkerProcesse.name.required"> Name is
                                                    required</span>
                                            </span>
                                        </div>

                                        <div class="form-group has-label col-sm-12 ">
                                            <label class="text  font-weight-bolder"> Description:
                                            </label>
                                            <textarea rows="3" class="form-control"
                                                v-model="newWorkerProcesse.discription" type="text" />

                                        </div>

                                        <div class="form-group col-md-4">
                                            <div class="checkbox form-check-inline mx-2">
                                                <input type="checkbox" id="inlineCheckbox1"
                                                    v-model="newWorkerProcesse.isActive">
                                                <label for="inlineCheckbox1"> Active </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="modal-footer justify-content-right">

                                <button type="button" class="btn btn-primary  " v-on:click="SaveWorkerProcess"
                                    v-bind:disabled="$v.newWorkerProcesse.$invalid"> {{ $t('CityDropdown.btnSave')
                                    }}</button>
                                <button type="button" class="btn btn-danger  mr-3 " v-on:click="close()">{{
                                    $t('CityDropdown.btnClear') }}</button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </modal>
        <modal :modalLarge="true" :show="previousDiscussionShow" v-if="previousDiscussionShow">

            <div class="modal-content">

                <div class="modal-header">

                    <h5 class="modal-title" id="myModalLabel"> Detail ({{
                        getFormattedDate(previousDiscussionDetail.dateTime)
                        }})</h5>
                    <button type="button" class="btn btn-primary" v-on:click="print()">&nbsp; Print</button>
                    <button type="button" class="btn-close" v-on:click="previousDiscussionShow = false"></button>
                </div>

                <div class="modal-body">
                    <main>
                        <div class="box box-border" ref="printable" id="printable">
                            <table style="width: 100%">
                                <thead v-if="isHeaderFooter == 'true'">
                                    <tr>
                                        <th>
                                            <HeaderComponent></HeaderComponent>



                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div class="row ">
                                                <p class="text-left">{{
                                                    getFormattedDate(previousDiscussionDetail.dateTime) }}
                                                </p>
                                                <span v-html="previousDiscussionDetail.dicussion">

                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    </main>
                    <iframe ref="printFrame" style="display: none;"></iframe>



                </div>
            </div>
        </modal>
    </div>

</template>
<script>
import moment from 'moment';
import { required } from "vuelidate/lib/validators"
import 'vue-loading-overlay/dist/vue-loading.css';
import clickMixin from '@/Mixins/clickMixin';
import draggable from 'vuedraggable';

import { VueEditor } from "vue2-editor";

export default ({
    mixins: [clickMixin],
    components: {
        draggable,
        VueEditor
    },

    data: function () {
        return {
            active: 'DiscussionBoard',
            fileNameZip: '',
            isHeaderFooter: '',
            showAttachment: false,
            randerWorkProcess: 0,
            previousDiscussionShow: false,
            isPrint: false,
            show: false,
            todoList: [],
            previousDiscussionDetail: {
                dateTime: '',
                dicussion: ''
            },
            newWorkerProcesse: {
                id: '00000000-0000-0000-0000-000000000000',
                name: '',
                discription: '',
                isActive: false,
                isDelete: false,
                workerProcessStatus: 'ToDoList',
                contactProductId: '00000000-0000-0000-0000-000000000000'
            },
            caseDetailId: '',
            caseDetail: {
                id: '00000000-0000-0000-0000-000000000000',
                date: '',
                documentId: '',
                customerName: '',
                caseName: '',
                isActive: false,
                isDelete: false,

                caseCode: '',
                caseStatus: '',
                productId: '',
                contactId: '',
                dueAmount: '',
                receivedAmount: '',
                attachmentList: [],
                isExternalLayer: false,
                employeeId: '',
                externalEmployeeId: '',
                contactEmployeeProductModels:[],
                discussionBoardModels: [],
                workerProcesseModels: [],
                newDiscussionBoards: {
                    id: '00000000-0000-0000-0000-000000000000',
                    dicussion: '',
                    dateTime: '',
                    contactProductId: ''
                }
            }
        }
    },

    validations: {
        newWorkerProcesse: {
            name: {
                required
            }
        }
    },

    methods: {
        SaveExternalLayer:function(){
            var root = this;
            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }


            root.$https.post('/Case/SaveExternalLayer', this.caseDetail.contactEmployeeProductModels, { headers: { "Authorization": `Bearer ${token}` } })
                .then(function (response) {
                    root.$swal({
                        title: 'Saved Successfully',
                        text: 'Saved',
                        type: 'success',
                        confirmButtonClass: "btn btn-success",
                        buttonStyling: false,
                        icon: 'success',
                        timer: 1500,
                        timerProgressBar: true,

                    })
                    console.log(response)
                },
                    function (error) {
                        this.loading = false;
                        console.log(error);
                    });
        },
        AddContactEmpoyeeProduct: function (id, data) {
            let rec = {
                id:'00000000-0000-0000-0000-000000000000',
                employeeId: id,
                contactProductId:this.caseDetail.id,
                name: data.name,
                invoiceNo:''
            }
            this.caseDetail.contactEmployeeProductModels.push(rec)

        },
        editItem(item) {
            // Set the item to editing mode
            item.isDelete = true;
            // Optionally, you can focus the input field automatically here
        },
        saveEdit(item) {
            // Exit editing mode and save changes
            item.isDelete = false;
            // Optionally, send the updated item to the server here
        },
        deleteItem(item) {
            // Find the item in the list and set its isDelete property to false
            const index = this.todoList.findIndex(i => i.id === item.id);
            if (index !== -1) {
                this.todoList[index].isActive = true;

                // Optionally, send a delete request to the server here
            }
        },
        updateStatus(caseStatus) {

            var root = this;
            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }


            root.$https.get('/Case/UpdateLoaStatus?id=' + this.caseDetail.id + '&caseStatus=' + caseStatus + '&isPaymentVoucher=false', { headers: { "Authorization": `Bearer ${token}` } })
                .then(function (response) {
                    root.$router.push({
                        path: '/Welcome',

                    })
                    console.log(response)
                },
                    function (error) {
                        this.loading = false;
                        console.log(error);
                    });
        },
        print() {
            this.isPrint = true;

            var cssPath = window.location.origin + '/assets/css/A5Print.css';
            let printContents = this.$refs.printable.innerHTML;

            let printFrame = this.$refs.printFrame;

            if (printFrame) {
                let doc = printFrame.contentDocument || printFrame.contentWindow.document;
                doc.open();
                doc.write('<html><head><title>Invoice</title>');
                doc.write('<link rel="stylesheet" href="' + cssPath + '" type="text/css" />');
                doc.write('</head><body>');
                doc.write(printContents);
                doc.write('</body></html>');
                doc.close();

                printFrame.contentWindow.onload = function () {
                    printFrame.contentWindow.print();
                    this.isPrint = false;
                };
            } else {
                this.isPrint = false;
                // Handle if iframe reference is not available
            }
        },
        showPreviousDiscussion: function (item) {
            this.previousDiscussionDetail.dateTime = item.dateTime;
            this.previousDiscussionDetail.dicussion = item.dicussion;
            this.previousDiscussionShow = true;

        },
        EditWorkProcess: function (item) {
            this.$v.$reset();
            this.newWorkerProcesse = {
                id: item.id,
                code: item.code,
                name: item.name,
                discription: item.discription,
                isActive: item.isActive,
                workerProcessStatus: item.workerProcessStatus,
                contactProductId: item.contactProductId
            }

            this.show = !this.show

        },
        AddWorkerProcesse: function () {
            this.$v.$reset();
            this.newWorkerProcesse = {
                id: '00000000-0000-0000-0000-000000000000',
                code: 0,
                name: '',
                discription: '',
                isActive: false,
                workerProcessStatus: 'ToDoList',
                contactProductId: this.caseDetail.id
            }

            this.show = !this.show;
        },
        DownloadZipFile() {
            var root = this;
            var token = '';
            if (root.$session.exists()) {
                token = localStorage.getItem('token');
            }
            root.$https.get('/Contact/DownloadZipFile?id=' + this.caseDetail.id, { headers: { "Authorization": `Bearer ${token}` }, responseType: 'blob' })
                .then(function (response) {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    let nameFile = root.caseDetail.customerName + ' ' + root.caseDetail.caseName;
                    link.setAttribute('download', nameFile + '.zip');
                    document.body.appendChild(link);
                    link.click();
                });
        },
        onDragEnd(event, stat) {
            const itemId = event.item.getAttribute('data-id');
            if (!itemId) return;

            const item = this.todoList.find(i => i.id.toString() === itemId);
            if (!item) return;

            const newStatus = stat;
            if (item.workerProcessStatus !== newStatus) {
                item.workerProcessStatus = newStatus;
            }
        },

        saveWorkerProcessList: function () {
            let root = this;

            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }

            this.$https.post('/Case/UpdateWorkerProcess', this.caseDetail.workerProcesseModels, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                if (response.data != null) {
                    root.$swal({
                        title: 'Saved Successfully',
                        text: 'Saved',
                        type: 'success',
                        confirmButtonClass: "btn btn-success",
                        buttonStyling: false,
                        icon: 'success',
                        timer: 1500,
                        timerProgressBar: true,

                    }).then(function (ok) {
                        if (ok != null) {
                            root.close()
                        }
                    });
                }

            });
        },


        getFormattedDate: function (x) {
            return moment(x).format('DD MMM YYYY');
        },
        SaveDiscussionBoard: function () {
            var root = this;
            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }
            this.$https.post('/Case/UpsertDiscussionBoard', this.caseDetail, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                if (response.data != null) {
                    root.getData();
                    root.$swal({
                        title: 'Saved Successfully',
                        text: 'Saved',
                        type: 'success',
                        confirmButtonClass: "btn btn-success",
                        buttonStyling: false,
                        icon: 'success',
                        timer: 1500,
                        timerProgressBar: true,

                    });
                }

            });
        },
        SaveWorkerProcess: function () {
            var root = this;
            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }
            this.$https.post('/Case/UpsertWorkProcess', this.newWorkerProcesse, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                if (response.data != null) {
                    root.close();
                    root.getData();
                    root.$swal({
                        title: 'Saved Successfully',
                        text: 'Saved',
                        type: 'success',
                        confirmButtonClass: "btn btn-success",
                        buttonStyling: false,
                        icon: 'success',
                        timer: 1500,
                        timerProgressBar: true,

                    });
                }

            });
        },
        getData: function () {
            var root = this;
            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }
            this.$https.get('/Case/CaseDetailQuery?id=' + this.caseDetailId, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {

                if (response.data != null) {
                    root.caseDetail.id = response.data.id;
                    root.caseDetail.date = response.data.date;
                    root.caseDetail.documentId = response.data.documentId;
                    root.caseDetail.customerName = response.data.customerName;
                    root.caseDetail.caseName = response.data.caseName;
                    root.caseDetail.isActive = response.data.isActive;
                    root.caseDetail.caseCode = response.data.caseCode;
                    root.caseDetail.caseStatus = response.data.caseStatus;
                    root.caseDetail.productId = response.data.productId;
                    root.caseDetail.contactId = response.data.contactId;
                    root.caseDetail.dueAmount = response.data.dueAmount;
                    root.caseDetail.receivedAmount = response.data.receivedAmount;
                    root.caseDetail.attachmentList = response.data.attachmentList;
                    root.caseDetail.isExternalLayer = response.data.isExternalLayer;
                    root.caseDetail.employeeId = response.data.employeeId;
                    root.caseDetail.discussionBoardModels = response.data.discussionBoardModels;
                    root.caseDetail.workerProcesseModels = response.data.workerProcesseModels;
                    root.caseDetail.caseCode = response.data.caseCode;
                    root.todoList = root.caseDetail.workerProcesseModels;
                    root.caseDetail.contactEmployeeProductModels = response.data.contactEmployeeProductModels;

                    root.fileNameZip = root.caseDetail.customerName + ' ' + root.caseDetail.caseName;
                    root.caseDetail.newDiscussionBoards = {
                        id: '00000000-0000-0000-0000-000000000000',
                        dicussion: '',
                        dateTime: '',
                        contactProductId: root.caseDetail.id
                    }
                    root.caseDetail.newDiscussionBoards.dateTime = moment().format('LLL')
                    root.randerWorkProcess++;
                    if (root.caseDetail.caseStatus === 5 || root.caseDetail.caseStatus === 6) {
                        root.active = 'PreviousDiscussion'
                    }
                }
            });
        },

        close: function () {
            this.show = false;
        },
        makeActive: function (tab) {

            this.active = tab;
        },

    },
    created: function () {
        this.caseDetailId = localStorage.getItem('caseDetailId');
        if(this.caseDetailId){
            this.getData();
        }
        else if (this.$route.query.data && this.$route.query.data.id) {
            this.caseDetailId = this.$route.query.data.id;
            localStorage.setItem('caseDetailId', this.caseDetailId);
            this.getData();
        }
        else {
            this.employee.salaryType = (this.$i18n.locale == 'en' || this.isLeftToRight()) ? 'Bank' : 'بنكي';
        }
    },

    mounted: function () {
        this.isHeaderFooter = localStorage.getItem('IsHeaderFooter');
        this.language = this.$i18n.locale;
        this.english = 'true';
        this.arabic = localStorage.getItem('Arabic');

    }
})

</script>


<style scoped>
.disabled {
    pointer-events: none
}

.truncate-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    max-height: 20px;
    /* Set the maximum height you want */
    max-width: 100%;
}
</style>