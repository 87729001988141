<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="row ">
                <div class="col-sm-12">
                    <div class="page-title-box">
                        <div class="row">
                            <div class="col">
                                <h5 class="page_title" v-if="isService">{{ $t('Sale.SaleServiceInvoice') }}</h5>
                                <h5 class="page_title" v-else>{{ $t('Sale.SaleInvoice') }}</h5>
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="javascript:void(0);"> {{ $t('Sale.Home') }}</a>
                                    </li>
                                    <li class="breadcrumb-item active" v-if="isService">
                                        {{ $t('Sale.SaleServiceInvoice') }}
                                    </li>
                                    <li class="breadcrumb-item active" v-else>
                                        {{ $t('Sale.SaleInvoice') }}
                                    </li>
                                </ol>
                            </div>

                            <div class="col-auto align-self-center">

                                <a v-on:click="GotoPage('/StartScreen')" href="javascript:void(0);"
                                    class="btn btn-sm btn-outline-danger">
                                    {{ $t('Sale.Close') }}
                                </a>
                            </div>
                        </div>


                    </div>

                </div>
            </div>
            <div class="card ">
                <div class="card-header ">
                    <div class="row">
                        <div class="col-lg-8" style="padding-top:20px">
                            <div class="input-group">
                                <button class="btn btn-soft-primary" type="button" id="button-addon1"
                                    @keyup.enter="search22()">
                                    <i class="fas fa-search"></i>
                                </button>
                                <input v-model="search" @keyup.enter="search22" type="text" class="form-control"
                                    placeholder="Search By Invoice No,Customer Name,Phone No or Amount"
                                    aria-label="Example text with button addon" aria-describedby="button-addon1">

                                <a class="btn btn-soft-primary" v-on:click="AdvanceFilterFor" id="button-addon2"
                                    value="Advance Filter">
                                    <i class="fa fa-filter"></i>
                                </a>
                            </div>
                        </div>
                        <div class=" col-lg-4 mt-1" v-if="!advanceFilters">
                            <button  @keyup.enter="search22()" type="button" class="btn btn-outline-primary mt-3">
                                {{ $t('Sale.ApplyFilter') }}
                            </button>
                            <button v-on:click="clearData()" type="button" class="btn btn-outline-primary mx-2 mt-3">
                                {{ $t('Sale.ClearFilter') }}
                            </button>
                        </div>
                    </div>
                    <div class="row " v-if="advanceFilters">
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4">
                            <label class="text  font-weight-bolder">{{ $t('Sale.Customer') }}</label>
                            <customerdropdown v-model="customerId" ref="CustomerDropdown" />
                        </div>
                        <div class="col-xs-12  col-lg-2">
                            <div class="form-group">
                                <label>{{ $t('Sale.Month') }}</label>
                                <month-picker-input style="z-index:9 !important;width: 80% !important;"
                                    v-bind:placeholder="$t('Sale.PickMonth')" v-bind:disabled="isDisableMonth"
                                    v-if="!isDisableMonth" v-on:input="GetMonth" :lang="isMonthPicker()" v-model="monthObj"
                                    :no-default="true" v-bind:key="randerforempty"></month-picker-input>
                                <input class="form-control" v-else disabled />
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="form-group">
                                <label>{{ $t('Sale.FromDate') }}</label>
                                <datepicker v-model="fromDate" v-bind:isDisable="isDisable" v-on:input="GetDate1"
                                    v-bind:key="randerforempty" />
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="form-group">
                                <label>{{ $t('Sale.ToDate') }}</label>
                                <datepicker v-model="toDate" v-bind:isDisable="isDisable" v-on:input="GetDate1"
                                    v-bind:key="randerforempty" />
                            </div>
                        </div>





                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3">

                            <button v-on:click="FilterRecord(true)" type="button" class="btn btn-outline-primary mt-3">
                                {{ $t('Sale.ApplyFilter') }}
                            </button>
                            <button v-on:click="FilterRecord(false)" type="button"
                                class="btn btn-outline-primary mx-2 mt-3">
                                {{ $t('Sale.ClearFilter') }}
                            </button>

                        </div>
                    </div>
                </div>

                <div class="card-body">

                    <ul class="nav nav-tabs" role="tablist">
                        <li class="nav-item" v-if="isValid('CanViewHoldInvoices')">
                            <a class="nav-link" v-bind:class="{ active: active == 'Hold' }" v-on:click="makeActive('Hold')"
                                data-bs-toggle="tab" href="#Hold" role="tab" aria-selected="true">
                                {{ $t('Sale.Hold') }}
                            </a>
                        </li>
                        <li class="nav-item" v-if="isValid('CanViewHoldInvoices')">
                            <a class="nav-link" v-bind:class="{ active: active == 'Credit' }" v-on:click="makeActive('Credit')"
                                data-bs-toggle="tab" href="#Credit" role="tab" aria-selected="true" v-if="isService">
                                {{ $t('Sale.Post') }}
                            </a>
                            <a class="nav-link" v-bind:class="{ active: active == 'Credit' }" v-on:click="makeActive('Credit')"
                                data-bs-toggle="tab" href="#Credit" role="tab" aria-selected="true" v-else>
                                {{ $t('Sale.Post') }}
                            </a>
                        </li>



                    </ul>

                    <!-- Tab panes -->
                    <div class="tab-content">
                        <div class="tab-pane pt-3" id="Hold" role="tabpanel" v-bind:class="{ active: active == 'Hold' }">

                            <div class="table-responsive">
                                <table class="table mb-0">
                                    <thead class="thead-light table-hover">
                                        <tr>
                                            <th>#</th>
                                            <th>
                                                {{ $t('Sale.InvoiceNo') }}
                                            </th>
                                            <th>
                                                {{ $t('Sale.Date') }}
                                            </th>
                                            <th>
                                                {{ $t('Sale.CustomerName') }}
                                            </th>
                                            <th>
                                                {{ $t('Sale.CreatedBy') }}
                                            </th>
                                            <th>
                                                {{ $t('InvoiceNote') }}
                                            </th>
                                            <!-- <th v-if="isValid('SaleHoldSetup')">
                                                {{ $t('Sale.SaleHoldType') }}
                                            </th> -->
                                            <th v-if="allowBranches">
                                                {{ $t('DailyExpense.BranchCode') }}
                                            </th>
                                            <th>
                                                {{ $t('Sale.NetAmount') }}
                                            </th>
                                            <th class="text-end">

                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(sale, index) in saleList" v-bind:key="index">
                                            <td v-if="currentPage === 1">
                                                {{ index + 1 }}
                                            </td>
                                            <td v-else>
                                                {{ ((currentPage * 10) - 10) + (index + 1) }}
                                            </td>
                                            <td
                                                v-if="isValid('CanEditHoldInvoices') && (isValid('CreditInvoices') || isValid('CashInvoices'))">
                                                <strong>
                                                    <a href="javascript:void(0)"
                                                        v-on:click="EditSale(sale.id, false)">{{ sale.registrationNumber }}</a>
                                                </strong> <br />
                                                <span class="badge bg-success">{{ sale.isCredit ? 'Credit' : 'Cash' }}</span>
                                            </td>
                                            <td v-else>
                                                {{ sale.registrationNumber }}
                                                <span class="badge bg-success">{{ sale.isCredit ? 'Credit' : 'Cash' }}</span>

                                            </td>
                                            <td>
                                                {{ getDate(sale.date) }}
                                            </td>
                                            <td>
                                                <a href="javascript:void(0)"
                                                    v-on:click="ViewCustomerInfo(sale.customerId, sale.cashCustomerId)"
                                                    data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight2"
                                                    aria-controls="offcanvasRight">{{ sale.customerName }}</a>
                                            </td>
                                            <td>
                                                {{ sale.userName }} <br />
                                                <span v-if="isDayStarts == 'true'">{{ sale.counterName }}</span>
                                            </td>
                                            <td v-if="sale.invoiceNote != null">
                                                <a href="javascript:void(0)"
                                                    v-on:click="SaleIdForCanvas(sale.deliveryChallanId, sale.saleOrderId, sale.qutationId, sale.proformaId, sale.registrationNumber, sale.date, sale.id)"
                                                    data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight3"
                                                    aria-controls="offcanvasRight">{{ sale.invoiceNote }}</a>
                                            </td>
                                            <td v-else>
                                                ---
                                            </td>
                                            <td v-if="sale.saleHoldTypes == 1 && isValid('SaleHoldSetup')">
                                                Hold
                                            </td>
                                            <td v-else-if="sale.saleHoldTypes == 2 && isValid('SaleHoldSetup')">
                                                Deleted
                                            </td>
                                            <td v-else-if="sale.saleHoldTypes == 3 && isValid('SaleHoldSetup')">
                                                Used
                                            </td>
                                            <td v-if="allowBranches">
                                                {{ sale.branchCode }}
                                            </td>
                                            <td>
                                                {{ currency }}
                                                {{ parseFloat(sale.netAmount).toFixed(3).slice(0, -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                    "$1,") }}
                                            </td>
                                            <td class="text-end">
                                                <button type="button" class="btn btn-light dropdown-toggle"
                                                    data-bs-toggle="dropdown" aria-expanded="false">{{ $t('Sale.Action') }}
                                                    <i class="mdi mdi-chevron-down"></i></button>
                                                <div class="dropdown-menu">
                                                    <a class="dropdown-item" href="javascript:void(0)"
                                                        v-on:click="EditSale(sale.id, false)"
                                                        v-if="isValid('CanEditHoldInvoices') && (isValid('CreditInvoices') || isValid('CashInvoices'))">{{
                                                            $t('Sale.EditInvoice') }}</a>
                                                    <a class="dropdown-item" href="javascript:void(0)"
                                                        v-on:click="DuplicateInvoice(sale.id)"
                                                        v-if="isValid('CanDuplicateInvoices') && (isValid('CreditInvoices') || isValid('CashInvoices'))">{{
                                                            $t('Sale.DuplicateInvoice') }}</a>
                                                    <a class="dropdown-item" href="javascript:void(0)"
                                                        v-on:click="PrintRdlc(sale.id, false)" title="Template View"
                                                        v-if="isValid('CanViewInvoiceDetail') && printTemplate == 'Template6'">{{
                                                            $t('Sale.TemplateView') }}</a>
                                                    <a class="dropdown-item" href="javascript:void(0)"
                                                        v-on:click="PrintRdlc(sale.id, false)"
                                                        v-if="isValid('CanViewInvoiceDetail') && printTemplate != 'Template6'">{{
                                                            $t('Sale.ViewInvoice') }}</a>
                                                    <a class="dropdown-item" href="javascript:void(0)"
                                                        v-on:click="PrintRdlc(sale.id, false)"
                                                        v-if="isValid('CanA4Print')">{{ $t('Sale.A4Print') }} </a>
                                                    <a class="dropdown-item" href="javascript:void(0)"
                                                        v-on:click="PrintRdlc(sale.id, true)"
                                                        v-if="isValid('CanA4Print')">{{ $t('Sale.PdfDownload') }}</a>
                                                        <a class="dropdown-item" href="javascript:void(0)"
                                                        v-on:click="RemoveSale(sale.id)" >Delete</a>
                                                   
                                                    <a class="dropdown-item" href="javascript:void(0)"
                                                        @click="DeleteSalePermanently(sale.id)"
                                                        v-if="isValid('CanDeleteHoldInvoices')">{{
                                                            $t('Sale.PermanentDelete') }} </a>
                                                    <a class="dropdown-item" href="javascript:void(0)"
                                                        v-on:click="sendEmail(sale.id)"
                                                        v-if="isValid('CanSendSaleEmailAsLink') || isValid('CanSendSaleEmailAsPdf')">{{
                                                            $t('Sale.Email') }}</a>
                                                </div>

                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>

                            <hr />
                            <div class="row">
                                <div class="col-lg-6">
                                    <span v-if="currentPage === 1 && rowCount === 0">
                                        {{
                                            $t('Pagination.ShowingEntries')
                                        }}
                                    </span>
                                    <span v-else-if="currentPage === 1 && rowCount < 10">
                                        {{ $t('Pagination.Showing') }}
                                        {{ currentPage }} {{ $t('Pagination.to') }} {{ rowCount }} {{ $t('Pagination.of') }}
                                        {{ rowCount }} {{ $t('Pagination.entries') }}
                                    </span>
                                    <span v-else-if="currentPage === 1 && rowCount >= 11">
                                        {{ $t('Pagination.Showing') }}
                                        {{ currentPage }} {{ $t('Pagination.to') }} {{ currentPage * 10 }} {{
                                            $t('Pagination.of')
                                        }} {{ rowCount }} {{ $t('Pagination.entries') }}
                                    </span>
                                    <span v-else-if="currentPage === 1">
                                        {{ $t('Pagination.Showing') }} {{ currentPage }} {{
                                            $t('Pagination.to')
                                        }} {{ currentPage * 10 }} of {{ rowCount }} {{
    $t('Pagination.entries')
}}
                                    </span>
                                    <span v-else-if="currentPage !== 1 && currentPage !== pageCount">
                                        {{
                                            $t('Pagination.Showing')
                                        }} {{ (currentPage * 10) - 9 }} {{ $t('Pagination.to') }}
                                        {{ currentPage * 10 }} {{ $t('Pagination.of') }} {{ rowCount }} {{
                                            $t('Pagination.entries')
                                        }}
                                    </span>
                                    <span v-else-if="currentPage === pageCount">
                                        {{ $t('Pagination.Showing') }}
                                        {{ (currentPage * 10) - 9 }} {{ $t('Pagination.to') }} {{ rowCount }} {{
                                            $t('Pagination.of')
                                        }} {{ rowCount }} {{ $t('Pagination.entries') }}
                                    </span>
                                </div>
                                <div class=" col-lg-6">
                                    <div class=" float-end" v-on:click="getPage()">
                                        <b-pagination pills size="sm" v-model="currentPage" :total-rows="rowCount"
                                            :per-page="10" :first-text="$t('Table.First')" :prev-text="$t('Table.Previous')"
                                            :next-text="$t('Table.Next')" :last-text="$t('Table.Last')">
                                        </b-pagination>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="tab-pane pt-3" id="Credit" role="tabpanel"
                            v-bind:class="{ active: active == 'Credit' }">
                            <div class="table-responsive">
                                <table class="table mb-0">
                                    <thead class="thead-light table-hover">
                                        <tr>
                                            <th>#</th>
                                            <th>
                                                {{ $t('Sale.InvoiceNo') }}
                                            </th>
                                            <th>
                                                {{ $t('Sale.Date') }}
                                            </th>
                                            <th>
                                                {{ $t('Sale.CustomerName') }}
                                            </th>
                                            <th>
                                                Due Amount
                                            </th>
                                            <th>
                                                Received Amount
                                            </th>
                                            <th v-if="allowBranches">
                                                {{ $t('DailyExpense.BranchCode') }}
                                            </th>
                                            <th>
                                                {{ $t('Sale.NetAmount') }}
                                            </th>
                                            <th class="text-end">

                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(sale, index) in saleList" v-bind:key="index">
                                            <td v-if="currentPage === 1">
                                                {{ index + 1 }}
                                            </td>
                                            <td v-else>
                                                {{ ((currentPage * 10) - 10) + (index + 1) }}
                                            </td>
                                            <td>
                                                <strong>
                                                    <a href="javascript:void(0)"
                                                        v-on:click="EditSale(sale.id, true)">{{ sale.registrationNumber }}</a>
                                                </strong> <br />

                                                <div v-if="sale.isSaleReturn">
                                                    <div class="badge bg-info">
                                                        {{ ($i18n.locale == 'en' || isLeftToRight()) ? ' Returned Invoice':'الفاتورة المرتجعة'}}
                                                    </div>
                                                </div>
                                                <div v-else>
                                                    <div class="badge bg-danger" v-if="sale.partiallyInvoices == 1">
                                                        {{ ($i18n.locale == 'en' || isLeftToRight()) ? 'Un-Paid' : 'غير مدفوعة' }}
                                                    </div>
                                                    <div class="badge bg-primary" v-if="sale.partiallyInvoices == 2">
                                                        {{ ($i18n.locale == 'en' || isLeftToRight()) ? ' Partially Paid':'المدفوعة جزئيا'}}

                                                    </div>
                                                    <div class="badge bg-success" v-if="sale.partiallyInvoices == 3">

                                                        {{ ($i18n.locale == 'en' || isLeftToRight()) ? ' Fully Paid' : 'مدفوعة بالكامل'}}
                                                    </div>
                                                </div>

                                            </td>
                                            <td>
                                                {{ getDate(sale.date) }}
                                            </td>
                                            <td>
                                                <a href="javascript:void(0)"
                                                    v-on:click="ViewCustomerInfo(sale.customerId, sale.cashCustomerId)"
                                                    data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight2"
                                                    aria-controls="offcanvasRight">{{ sale.customerName }}</a>
                                            </td>

                                            <td>
                                                {{ sale.dueAmount }}
                                            </td>
                                            <td>
                                                {{ sale.receivedAmount }}
                                            </td>
                                            <td v-if="allowBranches">
                                                {{ sale.branchCode }}
                                            </td>
                                            <td>
                                                {{ currency }}
                                                {{ parseFloat(sale.netAmount).toFixed(3).slice(0, -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                    "$1,") }}
                                            </td>
                                            <td class="text-end">
                                                <button type="button" class="btn btn-light dropdown-toggle"
                                                    data-bs-toggle="dropdown" aria-expanded="false">{{ $t('Sale.Action') }}
                                                    <i class="mdi mdi-chevron-down"></i></button>
                                                <div class="dropdown-menu">
                                                    <a class="dropdown-item" href="javascript:void(0)"
                                                        v-on:click="EditSale(sale.id, true)"
                                                        v-if="isValid('CanEditHoldInvoices') && (isValid('CreditInvoices') || isValid('CashInvoices'))">{{
                                                            $t('Sale.EditInvoice') }}</a>
                                                    <a class="dropdown-item" href="javascript:void(0)"
                                                        v-on:click="PayVoucherModel(sale)" v-if="sale.dueAmount > 0">Pay
                                                        Voucher</a>
                                                    <a class="dropdown-item" href="javascript:void(0)"
                                                        v-on:click="RemoveSale(sale.id)" >Delete</a>
                                                    <a class="dropdown-item" href="javascript:void(0)"
                                                        v-on:click="PrintRdlc(sale.id, 'print')"
                                                        v-if="isValid('CanA4Print')">{{ $t('Sale.A4Print') }} </a>
                                                    <a class="dropdown-item" href="javascript:void(0)"
                                                        v-on:click="PrintRdlc(sale.id, 'download')"
                                                        v-if="isValid('CanA4Print')">{{ $t('Sale.PdfDownload') }}</a>
                                                    <a class="dropdown-item" href="javascript:void(0)"
                                                        v-on:click="DuplicateInvoice(sale.id)"
                                                        v-if="isValid('CanDuplicateInvoices') && (isValid('CreditInvoices') || isValid('CashInvoices'))">{{
                                                            $t('Sale.DuplicateInvoice') }}</a>
                                                    <a class="dropdown-item" href="javascript:void(0)"
                                                        v-on:click="sendEmail(sale.id)"
                                                        v-if="isValid('CanSendSaleEmailAsLink') || isValid('CanSendSaleEmailAsPdf')">{{
                                                            $t('Sale.Email') }}</a>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                            <hr />
                            <div class="row">
                                <div class="col-lg-6">
                                    <span v-if="currentPage === 1 && rowCount === 0">
                                        {{
                                            $t('Pagination.ShowingEntries')
                                        }}
                                    </span>
                                    <span v-else-if="currentPage === 1 && rowCount < 10">
                                        {{ $t('Pagination.Showing') }}
                                        {{ currentPage }} {{ $t('Pagination.to') }} {{ rowCount }} {{ $t('Pagination.of') }}
                                        {{ rowCount }} {{ $t('Pagination.entries') }}
                                    </span>
                                    <span v-else-if="currentPage === 1 && rowCount >= 11">
                                        {{ $t('Pagination.Showing') }}
                                        {{ currentPage }} {{ $t('Pagination.to') }} {{ currentPage * 10 }} {{
                                            $t('Pagination.of')
                                        }} {{ rowCount }} {{ $t('Pagination.entries') }}
                                    </span>
                                    <span v-else-if="currentPage === 1">
                                        {{ $t('Pagination.Showing') }} {{ currentPage }} {{
                                            $t('Pagination.to')
                                        }} {{ currentPage * 10 }} of {{ rowCount }} {{
    $t('Pagination.entries')
}}
                                    </span>
                                    <span v-else-if="currentPage !== 1 && currentPage !== pageCount">
                                        {{
                                            $t('Pagination.Showing')
                                        }} {{ (currentPage * 10) - 9 }} {{ $t('Pagination.to') }}
                                        {{ currentPage * 10 }} {{ $t('Pagination.of') }} {{ rowCount }} {{
                                            $t('Pagination.entries')
                                        }}
                                    </span>
                                    <span v-else-if="currentPage === pageCount">
                                        {{ $t('Pagination.Showing') }}
                                        {{ (currentPage * 10) - 9 }} {{ $t('Pagination.to') }} {{ rowCount }} {{
                                            $t('Pagination.of')
                                        }} {{ rowCount }} {{ $t('Pagination.entries') }}
                                    </span>
                                </div>
                                <div class=" col-lg-6">
                                    <div class=" float-end" v-on:click="getPage()">
                                        <b-pagination pills size="sm" v-model="currentPage" :total-rows="rowCount"
                                            :per-page="10" :first-text="$t('Table.First')" :prev-text="$t('Table.Previous')"
                                            :next-text="$t('Table.Next')" :last-text="$t('Table.Last')">
                                        </b-pagination>
                                    </div>
                                </div>
                            </div>
                        </div>



                    </div>

                </div>
            </div>

        </div>


        <paymentVoucherModel :formName="'BankReceipt'" :show="showVoucher" v-if="showVoucher" @close="CloseVoucher"
            :accId="saleDetail.customerAccountId" :runningBalanceProp="saleDetail.runningBalance" :invoiceId="saleDetail.id"
            :totalAmount="saleDetail.netAmount" :remainingBalance="saleDetail.dueAmount"></paymentVoucherModel>
        <invoice-report-print :printDetails="printDetails" :isSaleOrder="false" :isTouchScreen="sale"
            @close="isPrint = false" :show="isPrint" v-if="printDetails.length != 0 && isPrint && !download"
            v-bind:key="printRender" />


        <email-compose :show="emailComposeShow" v-if="emailComposeShow" @close="emailComposeShow = false"
            :documentId="saleId" :formName="'Invoice'"></email-compose>


        <loading :active.sync="loading" :can-cancel="true" :is-full-page="false"></loading>

    </div>
</template>

<script>
import clickMixin from '@/Mixins/clickMixin'
import Loading from 'vue-loading-overlay';
import "vue-loading-overlay/dist/vue-loading.css";
import {
    MonthPickerInput
} from 'vue-month-picker'

import moment from "moment";
export default {
    props: ['formName'],
    components: {
        MonthPickerInput,
        Loading,
    },

    mixins: [clickMixin],
    data: function () {
        return {
            showVoucher: false,
            saleDetail: '',
            allowBranches: false,
            customerType: '',
            currentDocument: '',
            registrationNo: '',
            loading: false,
            holtSetupShow: false,
            expandHistory: false,
            randerExpand: 0,
            holdSetup: {
                Id: '',
                holdRecordType: '',
                isActive: true,
            },
            customerId: '',
            deleteMonths: '',

            request: 0,

            saleHoldType: '',

            historyList: [],

            monthObj: '',
            year: '',
            randerforempty: 0,
            month: 0,
            isDisable: false,
            isDisableMonth: false,
            changereport: 0,
            hold: 0,
            credit: 0,
            cash: 0,
            partially: 0,
            unPaid: 0,
            randerChart: 0,
            fullyPaid: 0,
            reportsrc: '',
            show: false,

            deliveryChallanRecord: [],
            ReservedDeliveryChallanbool: false,
            expandDeliveryChallan: false,
            expandDeliveryChallan1: false,
            expandDeliveryChallan2: false,
            SaleInvoiceId: '',
            isReservedId: '',
            qt: false,
            isService: false,
            code: '',
            canvasDate: '',
            canvasSaleOrderId: '',
            qutationId: '',
            deliveryChallanId: '',
            vAT: '',
            canvasTaxMethod: '',
            quotationNo: '',
            saleOrderNo: '',
            invoiceNote: '',
            deliveryNo: '',
            discountType: false,
            isAddChallan: false,
            isCloseChallan: false,
            isAdd: false,
            saleId: '',
            saleIdToCanvas: '',
            isDiscountBeforeVat: false,
            isPrint: false,
            download: false,
            emailComposeShow: false,
            bankDetail: false,
            printSize: '',
            printTemplate: '',
            filePath: null,
            fromDate: '',
            toDate: '',
            user: '',
            fromTime: '',
            toTime: '',
            userId: '',
            terminalId: '',
            active: 'Hold',
            isDisabled: false,
            colorVariants: false,
            advanceFilters: false,
            printInterval: '',
            search: '',
            english: '',
            arabic: '',
            searchQuery: '',
            saleList: [],
            saleItem: [],
            purchasePostList: [],
            deliveryChallanList: [],
            currentPage: 1,
            pageCount: '',
            rowCount: '',
            currency: '',

            selected: [],
            selectAll: false,
            updateApprovalStatus: {
                id: '',
                approvalStatus: ''
            },

            printDetails: [],
            printDetailsPos: [],
            printRender: 0,
            printRenderPos: 0,

            companyId: '00000000-0000-0000-0000-000000000000',
            CompanyID: '',
            UserID: '',
            employeeId: '',
            isDayAlreadyStart: false,
            IsProduction: false,
            AllowAll: false,
            sale: false,
            isDayStarts: '',
            rendr: 0,
            counter: 0,
            terminalType: 'CashCounter',
            allShow: true,
            isloginhistory: true,
            overWrite: false,
            businessLogo: '',
            businessNameArabic: '',
            businessNameEnglish: '',
            businessTypeArabic: '',
            businessTypeEnglish: '',
            companyNameArabic: '',
            companyNameEnglish: '',

            customerInformation: '',
            expandSale: false,
            isFifo: false,
            openBatch: 0,

            grossAmount: 0,
            discountAmount: 0,
            vatAmount: 0,
            afterDiscountAmount: 0,
            totalAmount: 0,
            proformaId: '',
            proformaNo: '',
        }
    },
    watch: {
        formName: function () {
            
            if(this.formName=='Service')
            {
                this.isService=true;
            }
            else if(this.formName=='SimpleInvoice')
            {
                this.isService=false;
            }

            this.makeActive('Hold');

        },



    },

    methods: {
        RemoveSale: function (id) {

            var root = this;
            // working with IE and Chrome both
            this.$swal({
                title: 'Are you sure?',
                text: 'You will not be able to recover this!',
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: 'Yes, delete it!',
                closeOnConfirm: false,
                closeOnCancel: false
            }).then(function (result) {
                if (result) {

                    var token = '';
                    if (root.$session.exists()) {
                        token = localStorage.getItem('token');
                    }
                    root.$https.get('/Sale/DeleteSale?Id=' + id, {
                        headers: {
                            "Authorization": `Bearer ${token}`
                        }
                    })
                        .then(function (response) {
                            if (response.data.message != '00000000-0000-0000-0000-000000000000') {

                                root.saleList.splice(root.saleList.findIndex(function (i) {
                                    return i.id === response.data.message;
                                }), 1);

                                root.$swal({
                                    title: 'Deleted!',
                                    text: 'Sale invoice deleted successfully',
                                    type: 'success',
                                    confirmButtonClass: "btn btn-success",
                                    buttonsStyling: false
                                });
                            } else {
                                root.$swal({
                                    title: 'Error!',
                                    text: 'Sale invoice not deleted successfully',
                                    type: 'error',
                                    confirmButtonClass: "btn btn-danger",
                                    buttonsStyling: false
                                });
                            }
                        },
                            function () {

                                root.$swal({
                                    title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Error!' : 'خطأ',
                                    text: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Delete UnSuccessfully' : 'حذف غير ناجح',
                                    type: 'error',
                                    confirmButtonClass: "btn btn-danger",
                                    buttonsStyling: false
                                });
                            });
                } else {
                    this.$swal((this.$i18n.locale == 'en' || this.isLeftToRight()) ? 'Cancelled!' : 'ألغيت!', (this.$i18n.locale == 'en' || this.isLeftToRight()) ? 'Your file is still intact!' : 'ملفك لا يزال سليما!', (this.$i18n.locale == 'en' || root.isLeftToRight()) ? 'info' : 'معلومات');
                }
            });
        },
        PayVoucherModel: function (sale) {
            this.saleDetail = sale;
            this.showVoucher = true;
        },
        CloseVoucher: function () {
            this.showVoucher = false;
            this.getData(this.search, 1, this.active, this.fromDate, this.toDate, this.fromTime, this.toTime, this.terminalId, this.userId, this.customerId, this.customerType);

        },
        searchData: function () {
            this.getData(this.search, 1, this.active, this.fromDate, this.toDate, this.fromTime, this.toTime, this.terminalId, this.userId, this.customerId, this.customerType);
        },



        GetDate1: function () {

            if (this.fromDate != '' || this.toDate != '') {
                this.isDisableMonth = true;
                this.year = '';
                this.month = '';
                this.monthObj = '';

            } else {
                this.isDisableMonth = false;
            }

        },
        GetMonth: function () {

            if (this.monthObj.month != undefined) {
                this.isDisable = true;
                this.fromDate = '';
                this.toDate = '';

                this.month = this.monthObj.monthIndex;
                this.year = this.monthObj.selectedYear;

            }

        },

        AdvanceFilterFor: function () {

            this.advanceFilters = !this.advanceFilters;
            if (this.advanceFilters == false) {
                this.FilterRecord(false);
            }

        },
        FilterRecord: function (type) {

            if (type) {
                if (this.fromDate != '') {
                    if (this.toDate == '') {
                        this.$swal({
                            title: 'Error',
                            text: "Please Select To Date ",
                            type: 'error',
                            icon: 'error',
                            showConfirmButton: false,
                            timer: 2000,
                            timerProgressBar: true,
                        });

                        return;

                    }
                }
                if (this.toDate != '') {
                    if (this.fromDate == '') {
                        this.$swal({
                            title: 'Error',
                            text: "Please Select From Date ",
                            type: 'error',
                            icon: 'error',
                            showConfirmButton: false,
                            timer: 2000,
                            timerProgressBar: true,
                        });

                        return;

                    }
                }

                if (this.monthObj.month != undefined) {
                    this.month = this.monthObj.monthIndex;
                    this.year = this.monthObj.selectedYear;

                }
                if (this.user.id != undefined) {
                    this.userId = this.user.id;

                }

            } else {
                this.isDisable = false;
                this.isDisableMonth = false;
                if (this.$refs.userDropdown != null) {
                    this.$refs.userDropdown.EmptyRecord();
                }
                this.user = '';
                this.userId = '';

                this.year = '';
                this.fromDate = '';
                this.toDate = '';
                this.month = '';
                this.monthObj = '';
                this.terminalId = '';
                this.customerId = '';
                this.customerType = '';
                this.search = "";
                this.randerforempty++;

                if (this.$refs.CustomerDropdown != undefined)
                    this.$refs.CustomerDropdown.Remove();

                if (this.$refs.TerminalDropDown != undefined)
                    this.$refs.TerminalDropDown.Remove();

            }

            this.getData(this.search, this.currentPage, this.active, this.fromDate, this.toDate, this.fromTime, this.toTime, this.terminalId, this.userId, this.customerId, this.customerType);

        },
        search22: function () {
            this.getData(this.search, this.currentPage, this.active, this.fromDate, this.toDate, this.fromTime, this.toTime, this.terminalId, this.userId, this.customerId, this.customerType);
        },

        clearData: function () {
            this.search = "";
            this.getData(this.search, this.currentPage, this.active, this.fromDate, this.toDate, this.fromTime, this.toTime, this.terminalId, this.userId, this.customerId, this.customerType);

        },




        ViewCustomerInfo: function (id, cashCustomerId) {
            var root = this;
            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }

            var cashCustomer = false;
            if (id == null) {
                cashCustomer = true;
                id = cashCustomerId;
            }
            root.$https.get('/Contact/ContactLedgerDetail?id=' + id + '&documentType=' + "SaleInvoice" + '&cashCustomer=' + cashCustomer + '&isService=' + this.isService + '&lastThreeMonth=' + true, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            })
                .then(function (response) {
                    if (response.data != null) {
                        root.customerInformation = response.data;
                    }
                },
                    function (error) {
                        console.log(error);
                    });
        },

        GetRecordOfDelivery: function () {

            this.ReservedDeliveryChallanbool = false;
            this.ViewDeliveryChallan(this.SaleInvoiceId);

        },


        GotoPage: function (link) {
            this.$router.push({
                path: link
            });
        },
        sendEmail: function (saleId) {
            this.saleId = saleId
            this.emailComposeShow = true;
        },
        GetUserId: function (x) {
            this.userId = x.id;
        },
        getDate: function (date) {
            return moment(date).format('DD-MM-YYYY');
        },
        PrinterInterval: function () {
            var root = this;

            this.printInterval = setInterval(() => {
                root.isDisabled = false;
            }, 3000);
        },
        ClearIntervalStatus: function () {
            clearInterval(this.printInterval)
        },

        EditSale: function (id, isEditPaidInvoice) {
            var root = this;
            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }

            root.$https.get('/Sale/SaleDetail?Id=' + id, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            })
                .then(function (response) {
                    if (response.data != null) {

                        root.$router.push({
                            path: '/addSaleService?formName=' + 'SaleInvoice',
                            query: {
                                data: response.data,
                                page: root.currentPage,
                                isEditPaidInvoice: isEditPaidInvoice,
                            }

                        })
                    } else {
                        root.$swal({
                            title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Error!' : 'خطأ',
                            text: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Something Went Wrong!' : 'هل هناك خطب ما!',
                            type: 'error',
                            confirmButtonClass: "btn btn-danger",
                            icon: 'error',
                            timer: 1500,
                            timerProgressBar: true,
                        });
                    }
                },
                    function (error) {
                        console.log(error);
                    });
        },


        IsSave: function () {
            this.show = false;
        },

        DuplicateInvoice: function (id) {
            var root = this;
            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }

            root.$https.get('/Sale/SaleDetail?Id=' + id, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            })
                .then(function (response) {
                    if (response.data != null) {
                        response.data.isDuplicate = true;
                        root.$router.push({
                            path: '/addSaleService?formName=' + 'SaleInvoice',
                            query: {
                                data: response.data,
                                isDuplicate: 'true'
                            }
                        })
                    }
                },
                    function (error) {
                        console.log(error);
                    });
        },



        makeActive: function (item) {

            this.active = item;
            this.selectAll = false;
            this.selected = [];
            this.getData(this.search, 1, item, this.fromDate, this.toDate, this.fromTime, this.toTime, this.terminalId, this.userId, this.customerId, this.customerType);
        },
        getPage: function () {

            this.getData(this.search, this.currentPage, this.active, this.fromDate, this.toDate, this.fromTime, this.toTime, this.terminalId, this.userId, this.customerId, this.customerType);
        },

        getData: function (search, currentPage, status, fromDate, toDate, fromTime, toTime, terminalId, userId, customerId, customerType) {
            var root = this;
            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }


            {

                this.$https.get('/Sale/SaleList?status=' + status + '&searchTerm=' + search + '&saleHoldType=' + this.saleHoldType + '&pageNumber=' + currentPage + '&fromDate=' + fromDate + '&toDate=' + toDate + '&fromTime=' + fromTime + '&toTime=' + toTime + '&terminalId=' + terminalId + '&userId=' + userId + '&isDiscountBeforeVat=' + this.isDiscountBeforeVat + '&isService=' + this.isService + '&month=' + this.month + '&year=' + this.year + '&CustomerId=' + customerId + '&customerType=' + customerType, {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                })
                    .then(function (response) {

                        if (response.data != null) {
                            root.currentPage = response.data.currentPage;
                            root.pageCount = response.data.pageCount;
                            root.rowCount = response.data.rowCount;
                            root.saleList = response.data.results.sales;
                            root.currentPage = currentPage;
                            root.rendr++;
                        }
                    }).catch(error => {
                        root.$swal.fire({
                            icon: 'error',
                            title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Error!' : 'خطأ',
                            text: error.response.data,
                            showConfirmButton: false,
                            timer: 5000,
                            timerProgressBar: true,
                        });

                    });
            }

        },

        AddSale: function () {
            this.$router.push('/addSaleService');
        },



        PrintRdlc: function (value, prop) {
            

            var root = this;
            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }
            root.$https.get("/Sale/SaleDetail?id=" + value + '&simpleQuery=true', {
                headers: {
                    Authorization: `Bearer ${token}`
                },
            })
                .then(function (response) {
                    if (response.data != null) {
                        

                        root.printDetails = response.data;

                        if (prop == 'download') {
                            root.download = true;
                            root.isPrint = false;
                        } else {
                            root.download = false;
                            root.isPrint = true;
                            root.printRender++;
                        }
                    }
                });
        },


    },
    created: function () {
        this.formName = this.$route.query.formName;


        if (this.$route.query.data == 'addSaleService') {
            this.$emit('input', 'addSaleService');

        } else {
            this.$emit('input', this.$route.name);

        }
        this.isService = this.formName == "Service" ? true : false;

       
    },
    mounted: function () {


        this.bankDetail = localStorage.getItem('BankDetail') == 'true' ? true : false;


        {
            this.english = 'true';
            this.search = '';
            this.active = 'Credit';
            this.getPage();

            this.currency = localStorage.getItem('currency');
            this.companyId = localStorage.getItem('CompanyID');
        }

    },
}
</script>

<style scoped>.vue__time-picker input.display-time {
    height: 40px !important;
    background-color: #f2f6f9;
    border: 1px solid #f2f6f9;
}


.timeline:nth-child(2n) .year {
    right: auto !important;
    left: 26% !important;
}

.year {
    right: 20% !important;
}</style>
