<template>
    <div class="row" v-if="isValid('CanViewSize')">

        <div class="col-lg-12">
            <div class="row">
                <div class="col-sm-12">
                    <div class="page-title-box">
                        <div class="row">
                            <div class="col">
                                <h4 class="page-title">{{ formName }}</h4>
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="javascript:void(0);">{{ $t('Size.Home') }}</a></li>
                                    <li class="breadcrumb-item active">{{ formName }}</li>
                                </ol>
                            </div>
                            <div class="col-auto align-self-center">
                                <a v-if="isValid('CanAddSize')" v-on:click="openmodel()" href="javascript:void(0);"
                                    class="btn btn-sm btn-outline-primary mx-1">
                                    <i class="align-self-center icon-xs ti-plus"></i>
                                    {{ $t('Size.AddNew') }}
                                </a>
                                <a v-on:click="GotoPage('/StartScreen')" href="javascript:void(0);"
                                    class="btn btn-sm btn-outline-danger">
                                    {{ $t('Size.Close') }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="col-lg-8" style="padding-top:20px">
                            <div class="input-group">
                                <button class="btn btn-secondary" type="button" id="button-addon1"><i
                                        class="fas fa-search"></i></button>
                                <input v-model="search"  @keyup.enter="search22()" type="text" class="form-control" :placeholder="$t('Size.Search')"
                                    aria-label="Example text with button addon" aria-describedby="button-addon1">
                            </div>
                        </div>
                        <div class=" col-lg-4 mt-1" v-if="!advanceFilters">

                            <button @keyup.enter="search22()" type="button" class="btn btn-outline-primary mt-3">
                                {{ $t('Sale.ApplyFilter') }}
                            </button>
                            <button v-on:click="clearData(false)" type="button" class="btn btn-outline-primary mx-2 mt-3">
                                {{ $t('Sale.ClearFilter') }}
                            </button>

                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="table-responsive">
                        <table class="table mb-0">
                            <thead class="thead-light table-hover">
                                <tr>
                                    <th width="10%">#</th>

                                    <th class="text-center">
                                        Name
                                    </th>
                                    <th class="text-center" v-if="formName == 'Variant'">
                                        Product Type
                                    </th>

                                    <th class="text-center" v-if="formName == 'Variant'">
                                        Value Type
                                    </th>
                                    <th class="text-center">
                                        Description
                                    </th>
                                    <th class="text-center">
                                        {{ $t('Size.Status') }}
                                    </th>
                                    <th class="text-center">
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(size, index) in sizelist" v-bind:key="size.id">
                                    <td v-if="currentPage === 1">
                                        {{ index + 1 }}
                                    </td>
                                    <td v-else>
                                        {{ ((currentPage * 10) - 10) + (index + 1) }}
                                    </td>
                                    <td class="text-center">
                                        <strong>
                                            <a href="javascript:void(0)" v-on:click="EditSize(size.id)">{{ size.name }}</a>
                                        </strong>
                                    </td>


                                    <td v-if="formName == 'Variant'" class="text-center">{{ size.productType }}</td>
                                    <td v-if="formName == 'Variant'" class="text-center">{{ size.valueType }}</td>
                                    <td class="text-center">{{ size.description }}</td>

                                    <td class="text-center" v-if="formName == 'Variant'">
                                        <toggle-button v-model="size.isActive" v-on:change="onStatusChange(size)"
                                            class="ml-2 mt-2" color="#3178F6" />

                                    </td>
                                    <td class="text-center" v-else>

                                        <span v-if="size.isActive" class="badge badge-boxed  badge-outline-success">{{
                                            $t('Size.Active') }}</span>
                                        <span v-else
                                            class="badge badge-boxed  badge-outline-danger">{{ $t('Size.De-Active') }}</span>
                                    </td>
                                    <td class="text-center">
                                        <button id="bElim" type="button" class="btn btn-sm btn-soft-danger btn-circle"
                                            v-on:click="RemoveProduct(size.id)"><i class="dripicons-trash"></i></button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <hr />

                    <div class="float-start">
                        <span v-if="currentPage === 1 && rowCount === 0"> {{ $t('Pagination.ShowingEntries') }}</span>
                        <span v-else-if="currentPage === 1 && rowCount < 10"> {{ $t('Pagination.Showing') }} {{ currentPage }}
                            {{ $t('Pagination.to') }} {{ rowCount }} {{ $t('Pagination.of') }} {{ rowCount }} {{
                                $t('Pagination.entries') }}</span>
                        <span v-else-if="currentPage === 1 && rowCount >= 11"> {{ $t('Pagination.Showing') }}
                            {{ currentPage }} {{ $t('Pagination.to') }} {{ currentPage * 10 }} {{ $t('Pagination.of') }}
                            {{ rowCount }} {{ $t('Pagination.entries') }}</span>
                        <span v-else-if="currentPage === 1"> {{ $t('Pagination.Showing') }} {{ currentPage }} {{
                            $t('Pagination.to') }} {{ currentPage * 10 }} of {{ rowCount }} {{ $t('Pagination.entries') }}</span>
                        <span v-else-if="currentPage !== 1 && currentPage !== pageCount"> {{ $t('Pagination.Showing') }}
                            {{ (currentPage * 10) - 9 }} {{ $t('Pagination.to') }} {{ currentPage * 10 }} {{ $t('Pagination.of') }}
                            {{ rowCount }} {{ $t('Pagination.entries') }}</span>
                        <span v-else-if="currentPage === pageCount"> {{ $t('Pagination.Showing') }} {{ (currentPage * 10) - 9 }}
                            {{ $t('Pagination.to') }} {{ rowCount }} {{ $t('Pagination.of') }} {{ rowCount }} {{
                                $t('Pagination.entries') }}</span>
                    </div>
                    <div class="float-end">
                        <div class="" v-on:click="GetSizeData()">
                            <b-pagination pills size="sm" v-model="currentPage" :total-rows="rowCount" :per-page="10"
                                :first-text="$t('Table.First')" :prev-text="$t('Table.Previous')"
                                :next-text="$t('Table.Next')" :last-text="$t('Table.Last')">
                            </b-pagination>
                        </div>
                    </div>
                </div>
            </div>

            <sizemodel :size="newSize" :show="show" v-if="show" @close="IsSave" :type="type" />
        </div>

    </div>
    <div v-else>
        <acessdenied></acessdenied>
    </div>
</template>


<script>
import clickMixin from '@/Mixins/clickMixin'
export default {
    props: ['formName', 'fromService'],
    mixins: [clickMixin],
    data: function () {
        return {
            arabic: '',
            english: '',
            searchQuery: '',
            show: false,
            sizelist: [],
            newSize: {
                id: '',
                name: '',
                nameArabic: '',
                valueType: '',
                productType: '',
                description: '',
                code: '',
                isActive: true,
                isVariant: this.formName == 'Variant' ? true : false,
                docType: this.formName,
            },
            type: '',
            search: '',
            currentPage: 1,
            pageCount: '',
            rowCount: '',
        }
    },
    watch: {
        formName: function () {
            this.GetSizeData(this.search, this.currentPage, this.formName);

        },
    },
    methods: {
        RemoveProduct: function (id) {
            var root = this;

            {

                this.$swal({
                    title: 'Are you sure?',
                    text: 'You will not be able to recover this!',
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: 'Yes, delete it!',
                    closeOnConfirm: false,
                    closeOnCancel: false
                }).then(function (result) {
                    
                    if (result.isConfirmed) {

                        var token = '';
                        if (root.$session.exists()) {
                            token = localStorage.getItem('token');
                        }
                        root.$https.get('/Product/SizeDelete?id=' + id, {
                            headers: {
                                "Authorization": `Bearer ${token}`
                            }
                        })
                            .then(function (response) {
                                

                                if (response.data != '00000000-0000-0000-0000-000000000000') {

                                    root.sizelist.splice(root.sizelist.findIndex(function (i) {
                                        return i.id === response.data;
                                    }), 1);
                                    if (root.formName == 'Variant') {
                                        root.$store.dispatch('GetSizeList', response.data.results.sizes);
                                    }
                                    root.$swal({
                                        title: 'Updated',
                                        text: 'Record Delete Successfully',
                                        type: 'success',
                                        confirmButtonClass: "btn btn-success",
                                        buttonsStyling: false
                                    });
                                } else {
                                    root.$swal({
                                        title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Error!' : 'خطأ',
                                        text: 'Product are in Used,So not Delete',
                                        type: 'error',
                                        confirmButtonClass: "btn btn-danger",
                                        buttonsStyling: false
                                    });
                                }
                            },
                                function () {

                                    root.$swal({
                                        title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Error!' : 'خطأ',
                                        text: 'Product are in Used,So not Delete',
                                        type: 'error',
                                        confirmButtonClass: "btn btn-danger",
                                        buttonsStyling: false
                                    });
                                });
                    } else {
                        this.$swal('Cancelled');
                    }
                });
            }
        },
        search22: function () {
            this.GetSizeData(this.search, this.currentPage, this.active, this.formName);
        },
        onStatusChange: function (size) {
            // alert(size.isActive);
            var root = this;
            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }
            this.$https.post('/Product/SaveSize', size, { headers: { "Authorization": `Bearer ${token}` } })
                .then(function (response) {

                    if (response.data.isSuccess == true) {
                        console.log("Status changed successfully");
                    }

                })
                .catch(error => {
                    console.log(error)
                    root.$swal.fire(
                        {
                            icon: 'error',
                            title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Something Went Wrong!' : 'هل هناك خطب ما!',
                            text: error.response.data,

                            showConfirmButton: false,
                            timer: 5000,
                            timerProgressBar: true,
                        });

                    root.loading = false
                })
                .finally(() => root.loading = false);
        },

        clearData: function () {
            this.search = "";
            this.GetSizeData(this.search, this.currentPage, this.active);

        },
        IsSave: function () {

            this.show = false;

            this.GetSizeData(this.search, this.currentPage);
        },
        GotoPage: function (link) {
            this.$router.push({ path: link });
        },
        getPage: function () {
            this.GetSizeData(this.search, this.currentPage);
        },
        openmodel: function () {
            this.newSize = {
                id: '00000000-0000-0000-0000-000000000000',
                name: '',
                nameArabic: '',
                description: '',
                valueType: this.formName == 'Variant' ? '' : this.fromService,
                productType: '',
                isActive: true,
                isVariant: this.formName == 'Variant' ? true : false,
                docType: this.formName,


            }
            this.show = !this.show;
            this.type = "Add";
        },
        GetSizeData: function () {

            var root = this;
            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }
            let isVariant = false;
            if (this.formName == 'Variant') {
                isVariant = true;
            }

            let documentType = this.formName;
            if (documentType == 'Variant') {
                documentType = '';
            }

            root.$https.get('Product/SizeList?isActive=false' + '&pageNumber=' + this.currentPage + '&searchTerm=' + this.search + '&docType=' + documentType + '&isVariant=' + isVariant, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                if (response.data != null) {
                    if (root.formName == 'Variant') {
                        root.$store.dispatch('GetSizeList', response.data.results.sizes);
                    }
                    root.sizelist = response.data.results.sizes;
                    root.pageCount = response.data.pageCount;
                    root.rowCount = response.data.rowCount;
                    root.loading = false;
                }
                root.loading = false;
            });
        },
        EditSize: function (Id) {


            var root = this;

            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }
            root.$https.get('/Product/SizeDetail?Id=' + Id, { headers: { "Authorization": `Bearer ${token}` } })
                .then(function (response) {
                    if (response.data) {

                        root.newSize.id = response.data.id;
                        root.newSize.name = response.data.name;
                        root.newSize.nameArabic = response.data.nameArabic;
                        root.newSize.description = response.data.description;
                        root.newSize.code = response.data.code;
                        root.newSize.valueType = response.data.valueType;
                        root.newSize.productType = response.data.productType;
                        root.newSize.isActive = response.data.isActive;
                        root.newSize.isVariant = response.data.isVariant;
                        root.newSize.docType = response.data.docType;
                        root.show = !root.show;
                        root.type = "Edit"
                    } else {
                        console.log("error: something wrong from db.");
                    }
                },
                    function (error) {
                        this.loading = false;
                        console.log(error);
                    });

        }
    },
    created: function () {
        this.$emit('input', this.$route.name);
    },
    mounted: function () {

        this.english = 'true';
        this.arabic = localStorage.getItem('Arabic');
        this.GetSizeData(this.search, 1);
    }
}
</script>