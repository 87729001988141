<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="row">
                <div class="col-sm-12">
                    <div class="page-title-box">
                        <div class="row">
                            <div class="col">
                                <h4 class="page-title" v-if="isCaseSystem">Profiles</h4>
                                <h4 class="page-title" v-else>{{ customerType }}</h4>
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="javascript:void(0);">{{ $t('Customer.Home') }}</a>
                                    </li>
                                    <li class="breadcrumb-item active" v-if="isCaseSystem">Profiles</li>
                                    <li class="breadcrumb-item active" v-else>{{ customerType }}</li>
                                </ol>
                            </div>
                            <div class="col-auto align-self-center">

                                <a v-on:click="AddCustomer" href="javascript:void(0);" v-if="!isCaseUser"
                                    class="btn btn-sm btn-outline-primary mx-1">
                                    <i class="align-self-center icon-xs ti-plus"></i>
                                    {{ $t('Customer.AddNew') }}
                                </a>
                                <a v-on:click="ImportDataFromXlsx" href="javascript:void(0);"
                                    v-if="isValid('CanImportCustomer') && ((rowCount < limitedCustomer) || (limitedCustomer == 0)) && customerType == 'Customer'"
                                    class="btn btn-sm btn-outline-primary mx-1">
                                    <i class="align-self-center icon-xs ti-plus"></i>
                                    {{ $t('Customer.ImportCustomer') }}
                                </a>
                                <a v-if="customerType == 'Customer'" class="btn btn-sm btn-outline-primary mx-1"
                                    v-on:click="DowmloadCSV()">
                                    Export Cash/Credit Customer Excel
                                    <i class="fas fa-file-pdf float-right"></i>
                                </a>
                                <!-- <a class="btn btn-sm btn-outline-primary mx-1" v-on:click="DowmloadCSV()">
                                    Export Walk_In Customer Excel 
                                    <i class="fas fa-file-pdf float-right"></i>
                                </a> -->

                                <a v-on:click="GotoPage('/StartScreen')" href="javascript:void(0);"
                                    class="btn btn-sm btn-outline-danger">
                                    {{ $t('Customer.Close') }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="col-lg-6" style="padding-top:20px">
                            <div class="input-group">
                                <button class="btn btn-secondary" type="button" id="button-addon1">
                                    <i class="fas fa-search"></i>
                                </button>
                                <input v-model="search"  @keyup.enter="search22()" type="text" class="form-control"
                                    placeholder="Search by Code or Name, Phone No, Email, VAT and Commercial Registration No"
                                    aria-label="Example text with button addon" aria-describedby="button-addon1">
                                <a class="btn btn-soft-primary" v-on:click="AdvanceFilterFor" id="button-addon2"
                                    value="Advance Filter">
                                    <i class="fa fa-filter"></i>
                                </a>
                            </div>

                        </div>
                        <div class=" col-lg-4 mt-1" v-if="!advanceFilters">

                            <button @keyup.enter="search22()" type="button" class="btn btn-outline-primary mt-3">
                                {{ $t('Sale.ApplyFilter') }}
                            </button>
                            <button v-on:click="clearData(false)" type="button" class="btn btn-outline-primary mx-2 mt-3">
                                {{ $t('Sale.ClearFilter') }}
                            </button>
                        </div>
                    </div>

                    <div class="row " v-if="advanceFilters">

                        <!-- <div class="col-lg-3">
                            <div class="form-group">
                                <label>{{ $t('Sale.FromDate') }}</label>
                                <datepicker v-model="fromDate" v-bind:isDisable="isDisable" v-on:input="GetDate1"
                                    v-bind:key="randerforempty" />
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="form-group">
                                <label>{{ $t('Sale.ToDate') }}</label>
                                <datepicker v-model="toDate" v-bind:isDisable="isDisable" v-on:input="GetDate1"
                                    v-bind:key="randerforempty" />
                            </div>
                        </div> -->
                        <div class="col-lg-4">
                            <label class="text  font-weight-bolder">{{ $t('Sale.Customer') }}</label>
                            <customerdropdown v-model="customerId" ref="CustomerDropdown" />
                        </div>
                        <div class="col-lg-4">
                            <label>{{ $t('AddCustomer.PaymentTerms') }}</label>
                            <div class="form-group">
                                <multiselect v-model="paymentTerms2" :preselect-first="true"
                                    v-if="($i18n.locale == 'en' || isLeftToRight())" :options="['Cash', 'Credit']"
                                    :show-labels="false" :placeholder="$t('AddCustomer.SelectType')">
                                </multiselect>
                                <multiselect v-else v-model="paymentTerms2" :preselect-first="true"
                                    :options="['نقد', 'آجل']" :show-labels="false"
                                    v-bind:placeholder="$t('AddCustomer.SelectOption')">
                                </multiselect>
                            </div>
                        </div>
                        <div class=" col-lg-4">
                            <label>{{ $t('AddCustomer.CustomerCategory') }}: </label>

                            <multiselect v-if="($i18n.locale == 'en' || isLeftToRight())" v-model="customerCategory"
                                :options="['Individual', 'Establishment', 'Company', 'Factory', 'Manufacturer', 'Government']"
                                :show-labels="false" :placeholder="$t('AddCustomer.SelectType')">
                            </multiselect>
                            <multiselect v-else v-model="customerCategory"
                                :options="['فرد', 'مؤسسة', 'شركة', 'مصنع', 'الصانع', 'حكومة']" :show-labels="false"
                                v-bind:placeholder="$t('AddCustomer.SelectOption')">
                            </multiselect>

                        </div>
                        <div class="col-lg-4">
                            <label>{{ $t('AddCustomer.CustomerGroup') }}</label>

                            <customergroupdropdown v-model="customerGroupId" :values="customerGroupId"
                                ref="CustomerGroupDropdown" />

                        </div>

                        <div class="col-lg-4 mt-4">
                            <span id="ember694" class="tooltip-container text-dashed-underline ">{{
                                $t('AddCustomer.CustomerType') }} :</span>

                            <div class="form-check form-check-inline">
                                <input v-model="category" name="contact-sub-type" id="a49946497" class=" form-check-input"
                                    type="radio" value="B2B – Business to Business">
                                <label class="form-check-label pl-0" for="a49946497">{{ $t('AddCustomer.Business') }}({{
                                    $t('AddCustomer.B2B') }})</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input v-model="category" name="contact-sub-type" id="a9ff8eb35" class=" form-check-input"
                                    type="radio" value="B2C – Business to Client">
                                <label class="form-check-label pl-0" for="a9ff8eb35">{{ $t('AddCustomer.Individual')
                                }}({{ $t('AddCustomer.B2C') }})</label>
                            </div>
                        </div>

                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3">

                            <button v-on:click="FilterRecord(true)" type="button" class="btn btn-outline-primary mt-3">
                                {{ $t('Sale.ApplyFilter') }}
                            </button>
                            <button v-on:click="FilterRecord(false)" type="button"
                                class="btn btn-outline-primary mx-2 mt-3">
                                {{ $t('Sale.ClearFilter') }}
                            </button>

                        </div>
                    </div>
                </div>
                
                <div class="card-body" v-if="isCaseUser || isCaseSystem">

                    <div class="table-responsive">
                        <table class="table mb-0">
                            <thead class="thead-light table-hover">
                                <tr>
                                    <th>#</th>
                                    <!-- <th>{{ $t('Customer.Code') }} </th> -->
                                    <th>Name
                                    </th>

                                    <th>Email</th>
                                    <th>Mobile No</th>
                                    <th>DOB</th>

                                    <th>Action</th>
                                    <!-- <th></th> -->

                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="(cust, index) in resultQuery">
                                    <tr v-if="cust.isCaseCustomer" v-bind:key="cust.id">
                                        <td>
                                            {{ index + 1 }}
                                        </td>
                                        <td v-if="isCaseSystem && !cust.isActive ">
                                            <strong >
                                                <a style="color:red !important" href="javascript:void(0)" v-on:click="EditCustomer(cust.id)">{{
                                                    cust.englishName
                                                }}</a>
                                            </strong>
                                        </td>
                                        <td v-else>
                                            <strong>
                                                <a href="javascript:void(0)" v-on:click="EditCustomer(cust.id)">{{
                                                    cust.englishName
                                                }}</a>
                                            </strong>
                                        </td>

                                        <td v-if="isCaseSystem && !cust.isActive " style="color:red !important">{{ cust.email }}</td>
                                        <td v-else>{{ cust.email }}</td>
                                        <td>{{ cust.telephone }}</td>
                                        <td>{{ cust.registrationDate }}</td>

                                        <!-- <td>
                                            <button type="button" class="btn btn-light"
                                                v-on:click="PrintAuthority(cust.email)">Print</button>
                                               
                                        </td> -->
                                        <td>

                                            <button type="button" v-on:click="AddUser(cust)" class="btn btn-primary "
                                                    data-bs-toggle="dropdown" aria-expanded="false">Login
                                                    </button>
                                        </td>
                                        
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                    <hr />
                    <div class="float-start" :key="randerPagination">
                        <span v-if="currentPage === 1 && rowCount === 0"> {{ $t('Pagination.ShowingEntries') }}</span>
                        <span v-else-if="currentPage === 1 && rowCount < 10"> {{ $t('Pagination.Showing') }} {{ currentPage
                        }}
                            {{ $t('Pagination.to') }} {{ rowCount }} {{ $t('Pagination.of') }} {{ rowCount }} {{
                                $t('Pagination.entries') }}</span>
                        <span v-else-if="currentPage === 1 && rowCount >= 11"> {{ $t('Pagination.Showing') }}
                            {{ currentPage }} {{ $t('Pagination.to') }} {{ currentPage * 10 }} {{ $t('Pagination.of') }}
                            {{ rowCount }} {{ $t('Pagination.entries') }}</span>
                        <span v-else-if="currentPage === 1"> {{ $t('Pagination.Showing') }} {{ currentPage }} {{
                            $t('Pagination.to') }} {{ currentPage * 10 }} of {{ rowCount }} {{ $t('Pagination.entries')
    }}</span>
                        <span v-else-if="currentPage !== 1 && currentPage !== pageCount"> {{ $t('Pagination.Showing') }}
                            {{ (currentPage * 10) - 9 }} {{ $t('Pagination.to') }} {{ currentPage * 10 }} {{
                                $t('Pagination.of') }}
                            {{ rowCount }} {{ $t('Pagination.entries') }}</span>
                        <span v-else-if="currentPage === pageCount"> {{ $t('Pagination.Showing') }} {{ (currentPage * 10) -
                            9 }}
                            {{ $t('Pagination.to') }} {{ rowCount }} {{ $t('Pagination.of') }} {{ rowCount }} {{
                                $t('Pagination.entries') }}</span>
                    </div>
                    <div class="float-end" :key="randerPagination">
                        <div class="" v-on:click="getPage()">
                            <b-pagination pills size="sm" v-model="currentPage" :total-rows="rowCount" :per-page="10"
                                :first-text="$t('Table.First')" :prev-text="$t('Table.Previous')"
                                :next-text="$t('Table.Next')" :last-text="$t('Table.Last')"></b-pagination>
                        </div>
                    </div>

                </div>
                <div class="card-body" v-else >
                    <div v-if="isCustomer" >
                        <ul class="nav nav-tabs" role="tablist">
                            <li class="nav-item"><a class="nav-link" v-bind:class="{ active: active == true }"
                                    v-on:click="makeActive(true,true)" data-bs-toggle="tab" href="#link5" role="tab"
                                    aria-selected="false">Regular</a></li>
                            <li class="nav-item"><a class="nav-link" v-bind:class="{ active: active == false }"
                                    v-on:click="makeActive(false,true)" data-bs-toggle="tab" href="#link6" role="tab"
                                    aria-selected="true">Retailer</a></li>

                        </ul>
                    </div>
                    <div class="table-responsive" v-if="active == false">
                        <table class="table mb-0">
                            <thead class="thead-light table-hover">
                                <tr>
                                    <th>#</th>
                                    <!-- <th>{{ $t('Customer.Code') }} </th> -->
                                    <th v-if="english == 'true'">Name
                                    </th>

                                    <th>Email</th>
                                    <th>Mobile No</th>
                                    <th>Total Invoice</th>
                                    <th class="text-center">Total Paid</th>
                                    <th>Total Due</th>

                                    <th>{{ $t('Customer.Status') }}</th>
                                    <th>Action</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(cust, index) in customerlist" v-bind:key="cust.id">
                                    <td>
                                        {{ index + 1 }}
                                    </td>
                                    <td v-if="isValid('CanEditCustomer')">
                                        <strong>
                                            <a href="javascript:void(0)" v-on:click="EditCustomer(cust.id)">{{
                                                cust.englishName
                                            }}</a>
                                        </strong>
                                    </td>
                                    <td v-else>
                                        {{ cust.englishName }}
                                    </td>
                                    <td>{{ cust.email }}</td>
                                    <td>{{ cust.contactNo1 }}</td>
                                    <td>{{ cust.totalInvoice }}</td>
                                    <!-- <td>{{ cust.category }}</td> -->
                                    <td class="text-center">{{ cust.totalPaid }}</td>
                                    <td>{{ cust.totalDue }}</td>
                                    <td>
                                        <span v-if="cust.isActive" class="badge badge-boxed  badge-outline-success">{{
                                            $t('Customer.Active') }}</span>
                                        <span v-else class="badge badge-boxed  badge-outline-danger">{{
                                            $t('Customer.De-Active') }}</span>
                                    </td>
                                    <td>
                                        <button type="button" class="btn btn-light"
                                            v-on:click="GetInvoiceRecord(cust.id)">Print</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="table-responsive" v-if="active == true">
                        <table class="table mb-0">
                            <thead class="thead-light table-hover">
                                <tr>
                                    <th>#</th>
                                    <!-- <th>{{ $t('Customer.Code') }} </th> -->
                                    <th v-if="english == 'true'">Name
                                    </th>

                                    <th>Email</th>
                                    <th>Mobile No</th>
                                    <th>Total Invoice</th>
                                    <th class="text-center">Total Paid</th>
                                    <th>Total Due</th>

                                    <th>{{ $t('Customer.Status') }}</th>
                                    <th>Action</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(cust, index) in customerlist" v-bind:key="cust.id">
                                    <td>
                                        {{ index + 1 }}
                                    </td>
                                    <td v-if="isValid('CanEditCustomer')">
                                        <strong>
                                            <a href="javascript:void(0)" v-on:click="EditCustomer(cust.id)">{{
                                                cust.englishName
                                            }}</a>
                                        </strong>
                                    </td>
                                    <td v-else>
                                        {{ cust.englishName }}
                                    </td>
                                    <td>{{ cust.email }}</td>
                                    <td>{{ cust.contactNo1 }}</td>
                                    <td>{{ cust.totalInvoice }}</td>
                                    <!-- <td>{{ cust.category }}</td> -->
                                    <td class="text-center">{{ cust.totalPaid }}</td>
                                    <td>{{ cust.totalDue }}</td>
                                    <td>
                                        <span v-if="cust.isActive" class="badge badge-boxed  badge-outline-success">{{
                                            $t('Customer.Active') }}</span>
                                        <span v-else class="badge badge-boxed  badge-outline-danger">{{
                                            $t('Customer.De-Active') }}</span>
                                    </td>
                                    <td>
                                        <button type="button" class="btn btn-light"
                                            v-on:click="GetInvoiceRecord(cust.id)">Print</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <hr />
                    <div class="float-start" :key="randerPagination">
                        <span v-if="currentPage === 1 && rowCount === 0"> {{ $t('Pagination.ShowingEntries') }}</span>
                        <span v-else-if="currentPage === 1 && rowCount < 10"> {{ $t('Pagination.Showing') }} {{ currentPage
                        }}
                            {{ $t('Pagination.to') }} {{ rowCount }} {{ $t('Pagination.of') }} {{ rowCount }} {{
                                $t('Pagination.entries') }}</span>
                        <span v-else-if="currentPage === 1 && rowCount >= 11"> {{ $t('Pagination.Showing') }}
                            {{ currentPage }} {{ $t('Pagination.to') }} {{ currentPage * 10 }} {{ $t('Pagination.of') }}
                            {{ rowCount }} {{ $t('Pagination.entries') }}</span>
                        <span v-else-if="currentPage === 1"> {{ $t('Pagination.Showing') }} {{ currentPage }} {{
                            $t('Pagination.to') }} {{ currentPage * 10 }} of {{ rowCount }} {{ $t('Pagination.entries')
    }}</span>
                        <span v-else-if="currentPage !== 1 && currentPage !== pageCount"> {{ $t('Pagination.Showing') }}
                            {{ (currentPage * 10) - 9 }} {{ $t('Pagination.to') }} {{ currentPage * 10 }} {{
                                $t('Pagination.of') }}
                            {{ rowCount }} {{ $t('Pagination.entries') }}</span>
                        <span v-else-if="currentPage === pageCount"> {{ $t('Pagination.Showing') }} {{ (currentPage * 10) -
                            9 }}
                            {{ $t('Pagination.to') }} {{ rowCount }} {{ $t('Pagination.of') }} {{ rowCount }} {{
                                $t('Pagination.entries') }}</span>
                    </div>
                    <div class="float-end" :key="randerPagination">
                        <div class="" v-on:click="getPage()">
                            <b-pagination pills size="sm" v-model="currentPage" :total-rows="rowCount" :per-page="10"
                                :first-text="$t('Table.First')" :prev-text="$t('Table.Previous')"
                                :next-text="$t('Table.Next')" :last-text="$t('Table.Last')"></b-pagination>
                        </div>
                    </div>

                </div>
            </div>
            <loading :active.sync="loading" :can-cancel="true" :is-full-page="true"></loading>
            <addsignupmodel :user="newEmployee"
                            :show="show"
                            :isEmployee="false"
                            v-if="show"
                            @close="show = false"
                            :type="type" />
        </div>
        <InvoiceReportPrint :headerFooter="headerFooter" :isPrint="isShown" :formName="formName" :printDetails="invoiceList" :fromDate="fromDate" :toDate="toDate"
            v-if="isShown" v-bind:key="printRender" />

            <AutorityPrint :customerEmail="customerEmail" @close="isShownAuthority=false" :show="isShownAuthority" v-if="isShownAuthority" v-bind:key="printRenderAuthority"></AutorityPrint>

    </div>
</template>
<script>
import clickMixin from '@/Mixins/clickMixin'
import Multiselect from 'vue-multiselect'
import Loading from 'vue-loading-overlay';
import "vue-loading-overlay/dist/vue-loading.css";

import moment from "moment";
export default {
    name: 'Customer',
    props: ['formName'],
    mixins: [clickMixin],
    components: {
        Multiselect,
        Loading
    },
    data: function () {
        return {
            randerPagination:0,
            printRenderAuthority:0,
            customerEmail:'',
            printRender: 0,
            show: false,
            type: false,
            isShown: false,
            isShownAuthority: false,
            isCaseSystem: false,
            invoiceList: '',
            isDisable: false,
            fromDate: '',
            toDate: '',
            randerforempty: 0,
            paymentTerms: '',
            paymentTerms2: '',
            b2b: false,
            b2c: false,
            advanceFilters: false,
            arabic: '',
            isCashCustomer: false,
            multipleAddress: false,
            active: true,
            headerFooter: {
                footerEn: '',
                footerAr: '',
                company: ''
            },
            english: '',
            customerlist: [],
            search: '',
            customer: [],
            loading: false,
            currentPage: 1,
            pageCount: '',
            rowCount: '',
            limitedCustomer: 0,
            customerId: '',
            customerGroupId: '',
            category: '',
            customerCategory: '',
            isVendor: false,
            isCaseUser: false,
            isCustomer:true,
            newEmployee: {
                   
                },

        }



    },
    watch: {
        formName: function () {
            this.customerType = this.formName;
            this.GetCustomerData(this.search, 1, this.active);
        }
    },
    computed: {
        resultQuery: function () {

            var root = this;
            var role = localStorage.getItem('RoleName');
            var email = localStorage.getItem('UserName');
            if (this.isCaseUser && role === 'User') {
                root.pageCount = 1;
                    root.rowCount = 1;
                return this.customerlist.filter((cur) => {
                    return cur.email && cur.email.toLowerCase().includes(email.toLowerCase());
                })
                
            } 
            else{
                return this.customerlist;
            }
        },
    },
    methods: {
        AddUser: function (employee) {
                this.newEmployee =employee;

                this.show = !this.show;
                this.type = "Add";
            },
        PrintAuthority:function(email){
            this.customerEmail = email;
            this.printRenderAuthority++;
            this.isShownAuthority = true;
        },
        searchData: function () {
            this.GetCustomerData(this.search, 1, this.fromDate, this.toDate, this.customerType);
        },
        GetInvoiceRecord: function (customerId) {

            
            var root = this;
            var token = '';
            if (token == '') {
                token = localStorage.getItem('token');
            }
            this.toDate = moment().format("DD MMM YYYY");
            this.fromDate = moment().startOf('year').format('DD MMM YYYY');

            var url;
            if(this.formName=='Supplier')
            {
                url = '/Report/PurchaseInvoice?fromDate=';

            }
            else
            {
                url = '/Report/SaleInvoiceReport?fromDate=';


            }







            this.$https.get(url + this.fromDate + '&toDate=' + this.toDate + '&customerId=' + customerId, { headers: { "Authorization": `Bearer ${token}` } })
                .then(function (response) {
                    if (response.data != null) {
                        root.invoiceList = response.data;
                        root.printRender++;
                        root.isShown = true;
                    }
                }).catch(error => {

                    console.log(error)
                    root.$swal.fire(
                        {
                            icon: 'error',
                            title: 'No Record Exist!',
                            text: error.response.data,
                            showConfirmButton: false,
                            timer: 5000,
                            timerProgressBar: true,
                        });

                    root.loading = false
                });
        },

        makeActive: function (item, fromTab) {
            this.currentPage = fromTab?1:this.currentPage
            this.active = item;
            this.GetCustomerData(this.search, this.currentPage, item);
        },
        // isCashCustomerFunc: function () {

        //     if (this.isCashCustomer) {

        //         this.customerType = 'Individual';
        //         this.paymentTerms = 'Cash';

        //     } else {
        //         this.customerType = '';
        //         this.paymentTerms = 'Credit';
        //     }
        // },
        AdvanceFilterFor: function () {

            this.advanceFilters = !this.advanceFilters;
            if (this.advanceFilters == false) {
                this.FilterRecord(false);
            }

        },
        FilterRecord: function (type) {

            if (type) {
                if (this.fromDate != '') {
                    if (this.toDate == '') {
                        this.$swal({
                            title: 'Error',
                            text: "Please Select To Date ",
                            type: 'error',
                            icon: 'error',
                            showConfirmButton: false,
                            timer: 2000,
                            timerProgressBar: true,
                        });

                        return;

                    }
                }
                if (this.toDate != '') {
                    if (this.fromDate == '') {
                        this.$swal({
                            title: 'Error',
                            text: "Please Select From Date ",
                            type: 'error',
                            icon: 'error',
                            showConfirmButton: false,
                            timer: 2000,
                            timerProgressBar: true,
                        });

                        return;

                    }
                }



            } else {
                this.isDisable = false;

                if (this.$refs.userDropdown != null) {
                    this.$refs.userDropdown.EmptyRecord();
                }


                this.fromDate = '';
                this.toDate = '';
                this.search = "";
                this.randerforempty++;
                this.customerId = '';
                this.paymentTerms2 = '';
                this.customerCategory = '';
                this.category = '';

                if (this.$refs.CustomerDropdown != undefined) {
                    this.$refs.CustomerDropdown.Remove();
                }


                if (this.$refs.CustomerGroupDropdown != undefined && this.$refs.CustomerGroupDropdown != null) {
                    this.$refs.CustomerGroupDropdown.EmptyRecord();
                }

            }

            this.GetCustomerData(this.search, this.currentPage, this.active, this.fromDate, this.toDate, this.fromTime, this.toTime, this.isCashCustomer, this.customerId, this.customerCategory, this.customerGroupId, this.category, this.paymentTerms, this.paymentTerms2);


        },
        search22: function () {
            this.GetCustomerData(this.search, this.currentPage, this.active, this.fromDate, this.toDate, this.fromTime, this.toTime, this.isCashCustomer, this.customerId, this.customerCategory, this.customerGroupId, this.category, this.paymentTerms2);
        },

        clearData: function () {
            this.search = "";
            this.GetCustomerData(this.search, this.currentPage, this.active, this.fromDate, this.toDate, this.fromTime, this.toTime, this.isCashCustomer, this.customerId, this.customerCategory, this.customerGroupId, this.category, this.paymentTerms2);

        },
        GetDate1: function () {

            if (this.fromDate != '' || this.toDate != '') {
                this.isDisableMonth = true;
                this.year = '';
                this.month = '';
                this.monthObj = '';

            } else {
                this.isDisableMonth = false;
            }

        },


        GotoPage: function (link) {
            this.$router.push({ path: link });
        },
        ImportDataFromXlsx: function () {
            var root = this;
            root.$router.push({
                path: '/ImportExportRecords',
                query: { data: 'Customer' }
            })
        },
        AddCustomer: function () {
            //this.$router.push('/addCustomer');
            this.$router.push({
                path: '/addCustomer',
                query: { formName: this.customerType }
            })
        },

        EditCustomer: function (Id) {
            var root = this;
            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }
            var multipleAddress = localStorage.getItem('MultipleAddress') == 'true' ? true : false;
            localStorage.setItem('ContactPage',this.currentPage);
            localStorage.setItem('CurrentActiveContact',this.active);
            this.$https.get('/Contact/ContactDetail?Id=' + Id + '&multipleAddress=' + multipleAddress, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                if (response.data != null) {
                    root.customer = response.data
                }
                root.$router.push({
                    path: '/addCustomer',
                    query: {
                        data: root.customer,
                        formName: root.customerType
                    }
                })
            });
        },
        getPage: function () {
            this.GetCustomerData(this.search, this.currentPage, this.active);
        },

        GetCustomerData: function (search, currentPage, isCashCustomer) {
            var root = this;

            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }
           
            this.$https.get('/Contact/ContactList?isCustomer=' + this.isCustomer + '&searchTerm=' + search + '&pageNumber=' + currentPage + '&isCashCustomer=' + isCashCustomer + '&CustomerId=' + this.customerId + '&customerGroupId=' + this.customerGroupId + '&customerCategory=' + this.customerCategory + '&category=' + this.category + '&paymentTerms2=' + this.paymentTerms2 + '&customerType=' + this.customerType, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                if (response.data != null) {


                    // root.$store.state.customerlist.push(response.data.contacts)
                    root.customerlist = response.data.results;
                    root.pageCount = response.data.pageCount;
                    root.rowCount = response.data.rowCount;
                    root.currentPage = response.data.currentPage;
                    root.randerPagination++;
                }
            });
        },
        DowmloadCSV: function () {
            var root = this;
            var token = '';
            root.loading = true;

            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }
            var multipleAddress = localStorage.getItem('MultipleAddress') == 'true' ? true : false;

            var paymentTerms = this.paymentTerm == 'Credit' || this.paymentTerm == 'آجـل' ? 'Credit' : '';

            root.$https.get('/Contact/GetContactListExcel?IsDropDown=' + true + '&isCustomer=' + true + '&isActive=' + true + '&paymentTerms=' + paymentTerms + '&isCashCustomer=' + this.isCashCustomer + '&multipleAddress=' + multipleAddress, { headers: { "Authorization": `Bearer ${token}` }, responseType: 'blob' })
                .then(function (response) {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'Customer Report.xlsx');
                    document.body.appendChild(link);
                    link.click();
                    root.loading = false;

                }).catch(error => {
                    console.log(error);
                    root.loading = false;
                    root.$swal.fire({
                        icon: 'error',
                        type: 'error',
                        title: root.$t('Error'),
                        text: root.$t('Something went Wrong'),
                        showConfirmButton: false,
                        timer: 5000,
                        timerProgressBar: true,
                    });
                });
        },
        RemoveCustomer: function (id) {
            var root = this;
            // working with IE and Chrome both
            this.$swal({
                icon: 'error',
                title: (this.$i18n.locale == 'en' || this.isLeftToRight()) ? 'Are you sure?' : 'هل أنت متأكد؟',
                text: (this.$i18n.locale == 'en' || this.isLeftToRight()) ? 'You will not be able to recover this!' : 'لن تتمكن من استرداد هذا!',
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: (this.$i18n.locale == 'en' || this.isLeftToRight()) ? 'Yes, delete it!' : 'نعم ، احذفها!',
                closeOnConfirm: false,
                closeOnCancel: false
            }).then(function (result) {
                if (result) {
                    var token = '';
                    if (root.$session.exists()) {
                        token = localStorage.getItem('token');
                    }
                    root.$https
                        .get('/Contact/ContactDelete?Id=' + id, { headers: { "Authorization": `Bearer ${token}` } })
                        .then(function (response) {
                            if (response.data.id != '00000000-0000-0000-0000-000000000000') {
                                root.$store.state.customerlist.splice(root.$store.state.customerlist.findIndex(function (i) {
                                    return i.id === response.data;
                                }), 1);
                                root.$swal({
                                    title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Deleted!' : 'تم الحذف!',
                                    text: response.data.isAddUpdate,
                                    type: 'success',
                                    confirmButtonClass: "btn btn-success",
                                    buttonsStyling: false
                                });
                            } else {
                                root.$swal({
                                    title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Error!' : 'خطأ',
                                    text: response.data.isAddUpdate,
                                    type: 'error',
                                    confirmButtonClass: "btn btn-danger",
                                    buttonsStyling: false
                                });
                            }
                        },
                            function () {
                                root.loading = false;
                                root.$swal({
                                    title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Error!' : 'خطأ',
                                    text: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Something Went Wrong!' : 'هل هناك خطب ما!',
                                    type: 'error',
                                    confirmButtonClass: "btn btn-danger",
                                    buttonsStyling: false
                                });
                            });
                }
                else {
                    this.$swal((this.$i18n.locale == 'en' || this.isLeftToRight()) ? 'Cancelled!' : 'ألغيت!', (this.$i18n.locale == 'en' || this.isLeftToRight()) ? 'Your file is still intact!' : 'ملفك لا يزال سليما!', (this.$i18n.locale == 'en' || root.isLeftToRight()) ? 'info' : 'معلومات');
                }
            });
        },
    },
    created: function () {
        //this.formName = this.$route.query.formName;
        console.log(this.formName)
        this.customerType = this.formName
        this.$emit('input', this.$route.name);

    },
    mounted: function () {
        this.isCustomer = this.formName =='Customer'?true:false;
            //isCashCustomer = this.isCustomer?isCashCustomer:false;
        
        this.english = 'true';
        this.arabic = localStorage.getItem('Arabic');
        this.limitedCustomer = parseInt(localStorage.getItem('LimitedCustomer'));
        if (isNaN(this.limitedCustomer)) {
            this.limitedCustomer = 0
        }
        this.isCaseSystem = localStorage.getItem('IsCaseSystem') == 'true' ? true : false;

        this.isCaseUser = localStorage.getItem('IsCaseUser') == 'true' ? true : false;

        this.isCashCustomer = localStorage.getItem('CashCustomer') == 'true' ? true : false;
        this.multipleAddress = localStorage.getItem('MultipleAddress') == 'true' ? true : false;


        if(this.$route.query.fromDashboard !== 'true'){
            
            this.currentPage = localStorage.getItem('ContactPage');
            this.active = localStorage.getItem('CurrentActiveContact') =='true'?true:false;
            this.getPage();
        }
        else if(!this.isCustomer){
            this.currentPage = 1;
            localStorage.setItem('ContactPage',this.currentPage);
            localStorage.setItem('CurrentActiveContact',false);
            
            this.makeActive(false);
        }
        else {
            this.currentPage = 1;
            localStorage.setItem('ContactPage',this.currentPage);
            localStorage.setItem('CurrentActiveContact',true);
            
            this.makeActive(this.active);
        }
        //this.GetCustomerData(this.search, 1, this.active);


    }
}
</script>