<template>
    <div class="row" v-if="isValid('CanAddItem') || isValid('CanEditItem')">
        <div class="col-lg-12 px-3" v-if="isService">
            <div class="row">
                <div class="col-sm-6">
                    <div class="col d-flex align-items-baseline">
                        <div class="media">
                            <span class="circle-singleline" style="background-color: #1761FD !important;">PR</span>
                            <div class="media-body align-self-center ms-3">
                                <h6 class="m-0 font-20" v-if="formName == 'Case'">Register Case</h6>
                                <h6 class="m-0 font-20" v-else>Register Service</h6>
                                <!-- <div class="col d-flex ">
                                    <p class="text-muted mb-0" style="font-size:13px !important;">{{ product.code }}</p>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="row">
                <div class="col-lg-7">
                    <div class="row">
                        <div class="col-lg-12">
                            <label v-if="formName == 'Case'"> Name:</label>
                            <label v-else>Service Name:</label>
                            <input v-model="$v.product.englishName.$model" placeholder="Item Name" class="form-control"
                                type="text">
                        </div>
                        <div class="col-lg-8">
                            <label>Basic Price: </label>
                            <my-currency-input v-model="product.salePrice"></my-currency-input>
                        </div>

                        <div class="col-lg-12">
                            <label>Description: </label>
                            <textarea class="form-control" rows="4" v-model="product.description"></textarea>
                        </div>
                    </div>
                </div>

            </div>


        </div>
        <div class="col-lg-12 px-3" v-else>
            <div class="row">
                <div class="col-sm-6">
                    <div class="col d-flex align-items-baseline">
                        <div class="media">
                            <span class="circle-singleline" style="background-color: #1761FD !important;">PR</span>
                            <div class="media-body align-self-center ms-3">
                                <h6 class="m-0 font-20">Register Product</h6>
                                <!-- <div class="col d-flex ">
                                    <p class="text-muted mb-0" style="font-size:13px !important;">{{ product.code }}</p>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>

            </div>


            <div class="row">
                <div class="col-lg-8">
                    <div class="row ">

                        <div class="col-lg-6">
                            <label>Product Name:<span class="text-danger"> *</span> </label>
                            <input v-model="$v.product.englishName.$model" placeholder="Item Name" class="form-control"
                                type="text">
                        </div>
                        <div class="col-lg-6">
                            <label> Category :<span class="text-danger"> *</span>   </label>
                            <categorydropdown  v-model="product.categoryId" v-bind:values="product.categoryId" />
                        </div>
                        <!-- <div class="col-lg-6">
                        <label> {{ $t('AddProduct.ProductSubcategory') }} </label>
                            <multiselect v-model="subCategoryId" @input="OnSelectedValue(subCategoryId.id)"
                                         :options="subCategoryOptions"
                                         :disabled="product.categoryId == '' || product.categoryId == null || product.serviceItem"
                                         :show-labels="false" track-by="name" label="name"
                                         v-bind:class="($i18n.locale == 'en' || isLeftToRight()) ? 'text-left ' : 'arabicLanguage '"
                                         v-bind:placeholder="$t('AddProduct.PleaseSelectSubCategory')">
                                <span slot="noResult" class="btn btn-primary "
                                      v-on:click="AddSubCategory('Add')">
                                    {{
                                                    $t('AddProduct.AddSubCategory')
                                    }}
                                </span><br />
                            </multiselect>
                    </div> -->
                        <div class="col-lg-6">
                            <label> {{ $t('AddProduct.PurchasePrice') }}</label>
                            <my-currency-input v-model="product.purchasePrice"></my-currency-input>
                        </div>
                        <div class="col-lg-6">
                            <label>Retail </label>
                            <my-currency-input v-model="product.salePrice"></my-currency-input>
                        </div>
                        <!-- <div class="col-lg-6">
                            <label> High Unit : </label>
                            <unitleveldropdown v-model="product.levelOneUnit" v-bind:values="product.levelOneUnit">
                            </unitleveldropdown>
                        </div>

                        <div class="col-lg-6">
                            <label>{{ $t('AddProduct.Unit') }} </label>
                            <unitdropdown v-model="product.unitId" :disabled="product.serviceItem"
                                v-bind:values="product.unitId">
                            </unitdropdown>
                        </div> -->
                        <!-- <div class="col-lg-6">
                            <label>{{ $t('AddProduct.PackSize') }} </label>
                            <div class="d-flex">
                                <input style="width:40%" class="form-control " type="number" v-model="product.length" />
                                <span style="padding-top:7px; width:20%;text-align: center;">x</span>
                                <input style="width:40%" class="form-control " type="number" v-model="product.width" />
                            </div>
                        </div> -->
                        <div class="col-lg-6">
                            <label> {{ $t('AddProduct.Description') }} </label>
                            <textarea class="form-control" v-model="product.description"></textarea>
                        </div>
                    </div>
                    <div class="row ">
                        <div class="col-lg-6">
                            <label> {{ $t('AddProduct.Barcode') }} </label>

                            <input class="form-control " v-on:input="BarCodeLength(product.barcode)" type="text"
                                v-model="product.barcode" />
                        </div>
                        <div class="col-lg-3 mt-3">
                            <button v-if="product.barcode == '' || product.barcode == null"
                                class="btn btn-outline-primary form-group" v-on:click="generateBarcode(false)">
                                {{
                                    $t('AddProduct.Generate')
                                }}
                            </button>
                            <button v-if="product.barcode != '' && product.barcode != null"
                                class="btn btn-outline-danger form-group" v-on:click="generateBarcode(true)">
                                {{
                                    $t('AddProduct.Delete')
                                }}
                            </button>
                        </div>
                        <div class="col-6">
                            <div v-if="product.barcode != '' && product.barcode != null" class="col-lg-3">
                                <barcode :height="30" v-bind:value="product.barcode"></barcode>
                            </div>
                        </div>
                        <div class="col-12">
                            <label>Warranty Date:</label><br>
                            <el-date-picker v-model="daterange" type="daterange" start-placeholder="Start Date"
                                end-placeholder="End Date"
                                :default-value="[new Date(2023, 19, 11), new Date(2023, 19, 12)]" />
                        </div>

                    </div>




                </div>
                <div class="col-lg-4">
                    <div class="card" style="height: 200px;padding:15px" :key="renderedImage">
                        <AddProductImage v-bind:disable="false" :imagePath="product.image" v-on:picPath="getImage"
                            v-bind:path="image" />
                    </div>

                    <div class="row">
                        <!-- <div class=" form-group col-lg-6">
                            <div class="checkbox form-check-inline mx-2" :key="render + 'add'">
                                <input type="checkbox" id="inlineCheckbox8" v-model="product.serviceItem">
                                <label for="inlineCheckbox8">{{ $t('AddProduct.ServiceItem') }}</label>
                            </div>
                        </div> -->
                        <div class="form-group col-md-6">
                            <div class="checkbox form-check-inline mx-2" :key="render + 'add'">
                                <input type="checkbox" id="inlineCheckbox2" v-model="product.isActive">
                                <label for="inlineCheckbox2">{{ $t('AddProduct.Active') }} </label>
                            </div>
                        </div>


                    </div>
                </div>
            </div>

            <div class="row mb-4 pb-4">
                <div class="col-4" v-for="item in sizeOption" v-bind:key="item.name">
                    <label>{{ item.name }} :</label>
                    <multiselect v-model="item.varianId" :options="item.variantList" :multiple="false"
                        :value="product.sizeIdList" placeholder="Select Value" track-by="name" :clear-on-select="false"
                        :show-labels="false" label="name" :preselect-first="false">
                        <a slot="noResult" class="btn btn-primary " v-on:click="openmodel(item.docType)">Add {{ item.docType
                        }}</a><br />

                    </multiselect>

                </div>



            </div>






        </div>

        <div v-if="!loading" class=" col-lg-12 invoice-btn-fixed-bottom">
            <div class="row">
                <div v-if="!loading" class="col-md-12">
                    <div class="button-items"
                        v-if="product.id == '00000000-0000-0000-0000-000000000000' && isValid('CanAddItem')">
                        <button class="btn btn-outline-primary" v-bind:disabled="$v.product.$invalid"
                            v-on:click="SaveProduct(false)"><i class="far fa-save "></i> {{ $t('AddCustomer.btnSave') }}</button>
                            <button class="btn btn-outline-primary" v-bind:disabled="$v.product.$invalid"
                            v-on:click="SaveProduct(true)"><i class="far fa-save "></i> Save Another </button>
                        <button class="btn btn-danger" v-on:click="GoToProduct">{{ $t('AddCustomer.Cancel') }}</button>
                    </div>
                    
                    <div class="button-items"
                        v-if="product.id != '00000000-0000-0000-0000-000000000000' && isValid('CanEditItem')">
                        <button class="btn btn-outline-primary" v-bind:disabled="$v.product.$invalid"
                            v-on:click="SaveProduct"><i class="far fa-save "></i> {{ $t('AddCustomer.btnUpdate') }}</button>
                        <button class="btn btn-danger" v-on:click="GoToProduct">{{ $t('AddCustomer.Cancel') }}</button>
                    </div>
                </div>
            </div>

        </div>
        <sizemodel :size="newSize" :show="show" v-if="show" @close="IsSave" :type="type" />

        <loading :active.sync="loading" :can-cancel="false" :is-full-page="true"></loading>
    </div>

    <div v-else>
        <acessdenied></acessdenied>
    </div>
</template>

<script>
import clickMixin from '@/Mixins/clickMixin'
import Multiselect from 'vue-multiselect'
import VueBarcode from 'vue-barcode';

import {
    maxLength,requiredIf
} from "vuelidate/lib/validators"
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';


export default {
    props:['formName'],
    mixins: [clickMixin],

    components: {
        'barcode': VueBarcode,

        Multiselect,
        Loading,

    },
    data: function () {

        return {
            color: "#79c120",
            salutatioRender: 0,
            reender: 0,
            isService: false,
            selectAllBrnaches: false,
            allowBranches: false,
            arabic: '',
            daterange: '',
            description: '',
            english: '',
            colorVariants: false,
            isSerial: false,
            isRaw: '',
            prop: '',
            isMultiUnit: '',
            subCategoryOptions: [],
            sizeOption: [],
            value: '',
            roles: [],
            options: ['Wholesaler', 'Retailer', 'Dealer', 'Distributer'],
            active: 'personal',
            rendered: 0,
            storedColors: [],
            renderedImage: 0,
            language: 'Nothing',
            isDelete: false,
            product: {
                id: '00000000-0000-0000-0000-000000000000',
                image: '',
                costSign: 'F',
                costValue: 0,
                costPrice: 0,
                productMasterId: '',
                priceRecords: [],
                englishName: '',
                arabicName: '',
                displayName: '',
                categoryId: '',
                barcode: '',
                length: 1,
                width: 1,
                subCategoryId: '',
                imagePath: '',
                isActive: true,
                isRaw: false,
                serial: false,
                guarantee: false,
                levelOneUnit: '',
                basicUnit: '',
                unitPerPack: null,
                salePriceUnit: '',
                salePrice: 0,
                purchasePrice: 0,
                unitId: '',
                sizeIdList: [],
                colorIdList: [],
                assortment: '',
                hsCode: '',
                styleNumber: '',
                taxMethod: '',
                serviceItem: false,
                wholesalePrice: 0,
                highUnitPrice: false,
                wholesaleQuantity: '',
                schemeQuantity: '',
                scheme: '',
                productGroupId: '',
                branchesIdList: [],
                toDate: '',
                fromDate: ''
            },
            sizeIds: '',
            colorIds: '',
            loading: false,
            catId: '',
            isCounter: 0,
            isEnabled: false,
            isDisable: false,
            isSaleReturn: false,
            isMasterProduct: false,
            randomNumber: '',
            render: 0,
            subCategoryId: [],
            decoded: [],
            permission: [],
            image: '',

            show: false,
            type: '',
            productId: {
                id: '',
                name: '',
                nameArabic: '',
            },
            newSubCategory: {
                id: '',
                code: '',
                name: '',
                nameArabic: '',
                description: '',
                categoryId: '',
                isActive: true
            },
            newSize: {
                id: '',
                name: '',
                nameArabic: '',
                valueType: '',
                productType: '',
                description: '',
                code: '',
                isActive: true,
                isVariant: '',
                docType: '',
            },
            wholesalePriceActivation: false,
        }
    },

    filters: {},
    validations: {
        product: {
            englishName: {
                maxLength: maxLength(250)
            },
            categoryId: {
                required: requiredIf((x) => {
                        if (x.serviceItem )
                            return false;
                        return true;
                    }),
            },

        }
    },
    watch: {
        formName: function () {
            //this.isService = localStorage.getItem('IsService') == 'true' ? true : false;
            alert(this.formName)
            if (this.formName == 'Case') {
                this.isRaw = true;
                this.product.isRaw = true;
                this.isService = true;
            }
            else if(this.formName == 'Service'){
                this.isService = true;
                this.product.serviceItem = true;
            }
            else {
                this.isRaw = false;
                this.product.isRaw = false;
                this.isService = false
            }


           // this.GetProductData(this.search, this.currentPage, this.warehouseId);

        }
    },

    methods: {

        openmodel: function (docType) {
            console.log(docType);
            this.newSize = {
                id: '00000000-0000-0000-0000-000000000000',
                name: '',
                nameArabic: '',
                description: '',
                valueType: '',
                productType: '',
                isActive: true,
                isVariant: false,
                docType: docType,


            }
            this.show = !this.show;
            this.type = "Add";
        },

        RemoveRow: function (index) {
            this.product.priceRecords.splice(index, 1);
        },

        AddRow: function () {

            // if(this.product.priceRecords.length>0)
            // {
            //     var isFind=this.product.priceRecords.some(x=>x.priceLabelingId==)
            // }


            this.product.priceRecords.push({
                id: '',
                newPrice: 0,
                price: 0,
                isActive: false,
                priceLabelingId: '',

            });


        },

        OnInputCost() {

            if (this.product.costSign === '%') {
                if (this.product.costValue > 0 && this.product.purchasePrice > 0) {
                    const val = ((this.product.purchasePrice) / 100) * this.product.costValue;
                    this.product.costPrice = parseFloat(this.product.purchasePrice + val).toFixed(3).slice(0, -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
                } else {
                    this.product.costPrice = 0;
                }
            } else {
                if (this.product.costValue > 0) {
                    const val = (this.product.costValue);
                    this.product.costPrice = parseFloat(val).toFixed(3).slice(0, -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");

                } else {
                    this.product.costPrice = 0;
                }

            }

        },
        OnChangType() {
            if (this.product.costSign === '%') {
                this.product.costSign = 'F';
                this.product.costValue = 0;
            } else {
                this.product.costSign = '%';
                this.product.costValue = 0;
            }
            this.OnInputCost();

        },
        onBarcodeScanned(barcode) {

            if (localStorage.getItem("BarcodeScan") != 'AddProduct')
                return
            this.product.barcode = barcode

        },
        BarCodeLength(barcode) {

            if (barcode.length > 20) {
                barcode = barcode.slice(0, -1);
                this.product.barcode = barcode
                this.$swal({
                    title: (this.$i18n.locale == 'en' || this.isLeftToRight()) ? 'Error!' : 'خطأ',
                    text: 'Bar Code Length not greater than 20',
                    type: 'error',
                    confirmButtonClass: "btn btn-danger",
                    icon: 'error',
                    timer: 4000,
                    timerProgressBar: true,
                });
            }

        },
        GetMasterProduct: function (x) {

            this.product.productMasterId = x.id;
            this.product.englishName = x.name;
            this.product.arabicName = x.nameArabic;
        },
        IsSave: function () {

            this.show = false;
            this.getSizeRecord();
            this.getSubcategory(this.product.categoryId);

        },
        AddSubCategory: function () {
            this.newSubCategory = {
                id: '00000000-0000-0000-0000-000000000000',
                code: '',
                name: '',
                nameArabic: '',
                description: '',
                categoryId: '',
                isActive: true
            }

            this.show = !this.show;
            this.type = "Add";
        },
        unitPackSizeChange: function (value) {
            this.product.width = value;
        },
        languageChange: function (lan) {
            if (this.language == lan) {
                if (this.product.id == '00000000-0000-0000-0000-000000000000') {

                    var getLocale = this.$i18n.locale;
                    this.language = getLocale;

                    this.$router.go('/addproduct');
                } else {
                    this.$swal({
                        title: (this.$i18n.locale == 'en' || this.isLeftToRight()) ? 'Error!' : 'خطأ',
                        text: (this.$i18n.locale == 'en' || this.isLeftToRight()) ? 'You cannot Change language during update, otherwise your current page data will be lose!' : 'لا يمكنك تغيير اللغة أثناء التحديث ، وإلا ستفقد بيانات صفحتك الحالية!',
                        type: 'error',
                        confirmButtonClass: "btn btn-danger",
                        icon: 'error',
                        timer: 4000,
                        timerProgressBar: true,
                    });
                }
            }

        },

        focusOut: function () {

            return this.product.salePrice = this.product.salePrice.toFixed(3).slice(0, -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
        },

        changeValue: function (value) {
            if (value == false) {
                this.product.saleReturnDays = '';
            }
        },
        RanderImagePath: function (value) {
            if (value == true) {
                this.renderedImage++;

            }
        },
        getSizeRecord: function () {
            var root = this;
            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }

            console.log(root.sizeIds);
            this.$https.get('/Product/VarrantyViseRecord?isActive=true', { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {

                if (response.data != null) {


                    root.sizeOption = response.data;

                    if (root.sizeIds.length > 0) {
                        for (let i = 0; i < root.sizeOption.length; i++) {
                            for (let j = 0; j < root.sizeIds.length; j++) {
                                {
                                    const updatedVariant = root.sizeOption[i].variantList.find(item => item.id === root.sizeIds[j]);



                                    if (updatedVariant != undefined && updatedVariant != null) {
                                        root.sizeOption[i].varianId = updatedVariant;
                                        break;
                                    }
                                }
                            }
                        }
                    }
                }
            });
        },

        getImage: function (value) {
            this.product.image = value;
            this.isDelete = true;
        },
        OnSelectedValue: function (id) {

            this.product.subCategoryId = id;
        },


        generateBarcode: function (x) {
            if (x) {
                this.randomNumber = 0; //multiply to generate random number between 0, 100
                this.product.barcode = '';
                this.isDisable = false
                this.isEnabled = false
            } else {

                this.randomNumber = Math.floor(Math.random() * 10000000000); //multiply to generate random number between 0, 100
                this.product.barcode = this.randomNumber
                this.isDisable = true
                this.isEnabled = true
            }

        },
        writeBarcode: function () {
            this.isDisable = true
            this.isEnabled = true

        },
        GoToProduct: function () {
            if (this.isValid('CanViewItem')) {


                this.$router.push({
                                        path: '/products',
                                        query: {
                                            formName: this.formName,
                                            prop: this.prop,
                                        }
                                    })

            } else {
                this.$router.go();
            }

        },
        makeActive: function (tab) {

            this.active = tab;
        },

        getSizeId: function (value) {
            var sizeId = [];
            for (var i = 0; i < value.length; i++) {
                sizeId[i] = value[i].id
            }
            return sizeId;
        },

        getColorId: function (value) {
            var sizeId = [];
            for (var i = 0; i < value.length; i++) {
                sizeId[i] = value[i].id
            }
            return sizeId;
        },

        SaveProduct: function (isAnother = false) {

            if (this.sizeOption.length > 0) {
                let ids = [];
                for (let i = 0; i < this.sizeOption.length; i++) {
                    if (this.sizeOption[i].varianId != undefined && this.sizeOption[i].varianId != null) {
                        if (this.sizeOption[i].varianId.id != undefined && this.sizeOption[i].varianId.id != null) {
                            ids[i] = this.sizeOption[i].varianId.id;


                        }

                    }
                }
                this.product.sizeIdList = ids;

            }

            // if (this.sizeIds != null && this.sizeIds != undefined && this.sizeIds != '') {
            //     this.product.sizeIdList = this.getSizeId(this.sizeIds);
            // }
            // if (this.colorVariants) {

            //     if (this.colorIds != null && this.colorIds != undefined && this.colorIds != '') {
            //         this.product.colorIdList = this.getColorId(this.colorIds);
            //     }
            // }

            if (this.product.barcode != '' && this.product.barcode != null && this.product.barcode.length > 20) {
                for (var i = 0; i < this.product.barcode.length; i++) {
                    if (this.product.barcode.length < 20) {
                        break;
                    } else {
                        this.product.barcode = this.product.barcode.slice(0, -1);

                    }

                }

            }


            this.loading = true;
            var root = this;
            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }

            if (!this.isSaleReturn) {
                this.product.saleReturnDays = 0;
            }

            if (this.product.salePrice == null || this.product.salePrice == '') {
                this.product.salePrice = 0;
            }

            if (this.product.serviceItem && this.product.categoryId == '') {
                this.product.categoryId = '00000000-0000-0000-0000-000000000000';
            }

            if (this.daterange != '' && this.daterange != undefined && this.daterange != null) {

                this.product.toDate = this.daterange[1]
                this.product.fromDate = this.daterange[0]
            }
            else {
                this.product.toDate = '';
                this.product.fromDate = '';
            }

            this.$https.post('/Product/SaveProductInformation', this.product, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            })
                .then(response => {

                    if (response.data.message.id != '00000000-0000-0000-0000-000000000000' && response.data.action == "Add") {
                        root.$swal({
                            title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Saved!' : '!تم الحفظ',
                            text: response.data.message.isAddUpdate,
                            type: 'success',
                            confirmButtonClass: "btn btn-success",
                            buttonStyling: false,
                            icon: 'success',
                            timer: 1500,
                            timerProgressBar: true,

                        }).then(function (ok) {
                            
                            if (ok != null) {
                                if(isAnother){
                                    root.AutoIncrementCode();
                                    root.product.englishName = '';
                                    root.product.description = '';
                                } else if (root.isValid('CanViewItem')) {
                                    root.$router.push({
                                        path: '/products',
                                        query: {
                                            formName: root.formName,
                                            prop: root.prop,
                                        }
                                    })



                                }
                                 else {
                                    root.$router.go();
                                }
                            }
                        });
                    } else if (response.data.message.id != '00000000-0000-0000-0000-000000000000' && response.data.action == "Update") {
                        root.info = response.data.bpi
                        root.$swal({
                            title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Saved!' : '!تم الحفظ',
                            text: response.data.message.isAddUpdate,
                            type: 'success',
                            confirmButtonClass: "btn btn-success",
                            buttonStyling: false,
                            icon: 'success',
                            timer: 1500,
                            timerProgressBar: true,

                        }).then(function (ok) {
                            if (ok != null) {
                                if (root.isValid('CanViewItem')) 
                                {

                                    root.$router.push({
                                        path: '/products',
                                        query: {
                                            formName: root.formName,
                                            prop: root.prop,
                                        }
                                    })



                                } else {
                                    root.$router.go();
                                }
                            }
                        });
                    } else {
                        root.$swal({
                            title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Error!' : 'خطأ',
                            text: response.data.message.isAddUpdate,
                            type: 'error',
                            confirmButtonClass: "btn btn-danger",
                            icon: 'error',
                            timer: 1500,
                            timerProgressBar: true,
                        });
                    }
                })
                .catch(error => {
                    console.log(error)
                    root.$swal.fire({
                        icon: 'error',
                        title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Something Went Wrong!' : 'هل هناك خطب ما!',
                        text: error.response.data,
                        showConfirmButton: false,
                        timer: 5000,
                        timerProgressBar: true,
                    });

                    root.loading = false
                })
                .finally(() => root.loading = false)
        },
        AutoIncrementCode: function () {
            var root = this;
            var token = '';
            if (root.$session.exists()) {
                token = localStorage.getItem('token');
            }
            root.$https
                .get('/Product/ProductAutoGenerateCode', {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                }).then(function (response) {
                    if (response.data != null) {
                        root.product.code = response.data;

                        root.rendered++
                    }
                });
        },

        getSubcategory: function (event) {
                this.catId = event;
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                this.subCategoryId = [];
                this.subCategoryOptions = [];
                this.$https.get('/Product/GetSubCategoryInformation?categoryId=' + event + '&isActive=' + true, {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                }).then(function (response) {
                    if (response.data != null) {
                        response.data.results.subCategories.forEach(function (rout) {
                            if (rout.id == root.product.subCategoryId) {
                                root.subCategoryId.push({
                                    id: rout.id,
                                    name: rout.name
                                })
                            }
                            root.subCategoryOptions.push({
                                id: rout.id,
                                name: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? (rout.name != "" ? rout.code + ' ' + rout.name : rout.code + ' ' + rout.nameArabic) : (rout.nameArabic != '' ? rout.code + ' ' + rout.nameArabic : rout.code + ' ' + rout.name)
                            })
                        })
                    }
                })
            },



        getBase64Image: function (path) {

            var root = this;
            var token = '';
            if (root.$session.exists()) {
                token = localStorage.getItem('token');
            }

            root.$https
                .get('/Contact/GetBaseImage?filePath=' + path, {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                }).then(function (response) {
                    if (response.data != null) {
                        root.image = response.data;
                        root.renderedImage++
                        root.isDelete = true;
                    }
                });
        }
    },

    created() {



        this.$emit('input', this.$route.name);
        //this.isService = localStorage.getItem('IsService') == 'true' ? true : false;


        this.formName = this.$route.query.formName;
        this.prop = this.$route.query.prop;
        if (this.formName == 'Case') {
                this.isRaw = true;
                this.product.isRaw = true;
                this.isService = true;
            }
            else if(this.formName == 'Service'){
                this.product.isRaw = false;
                this.isService = true;
                this.product.serviceItem = true;
            }
            else {
                this.isRaw = false;
                this.product.isRaw = false;
                this.isService = false
            }


        if (this.$route.query.data != undefined) {


            this.product = this.$route.query.data;
            this.sizeIds = this.product.sizeIdList;

            this.render++;
            this.isDisable = true;
            this.isEnabled = true;
            this.getSubcategory(this.product.categoryId);
            if (this.product.saleReturnDays != null && this.product.saleReturnDays != '' && this.product.saleReturnDays != 0) {
                this.isSaleReturn = true;
            }

            if (this.product.image != '' && this.product.image != null && this.product.image != undefined) {
                this.getBase64Image(this.product.image);
            }
            if (this.product.toDate != null && this.product.fromDate != null) {
                let date = [];
                date.push(this.product.fromDate);
                date.push(this.product.toDate);
                this.daterange = date;

            }

            this.getSizeRecord();
        } else {
            this.AutoIncrementCode();
            this.product.taxMethod = localStorage.getItem('taxMethod');
            this.product.taxRateId = localStorage.getItem('TaxRateId');
            this.getSizeRecord();
        }
        this.product.serviceItem = this.isService ? true : false;

    },

    mounted: function () {
        // if (this.formName == 'Case') {
        //     this.product.isRaw = true;
        // }
        // else {
        //     this.product.isRaw = false;
        // }



        this.english = 'true';
        var getLocale = this.$i18n.locale;
        this.language = getLocale;
        if (this.product.unitId == null || this.product.unitId == '' || this.product.unitId == undefined) {

            let unitId = localStorage.getItem('UnitId');
            if (unitId != null && unitId != undefined && unitId != 'null') {
                this.product.unitId = localStorage.getItem('UnitId');
            }
        }
    },
}
</script>

<style>
.circle-singleline {
    margin: 20px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    font-size: 30px;
    text-align: center;
    background: blue;
    color: #fff;
    vertical-align: middle;
    line-height: 60px;
}
</style>